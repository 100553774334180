<template>
  <el-dialog
    title="选择车型"
    :visible.sync="show"
    width="80%"
    top="5vh"
    :before-close="handleClose"
  >

    <carTypeList
      @change="handleChange"
      @newchange="handleNewChange"
      :defaultData="defaultData"
      :show="show"
    />

  </el-dialog>
</template>

<script>
import carTypeList from "./selectLink/car-type-list.vue";
export default {
  name: "index",
  components: {
    carTypeList,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    defaultData: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleChange(e) {
      this.$emit("change", e);
    },
    handleNewChange(e) {
      this.$emit("newchange", e);
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
</style>