const log = [
    {
        path: "/worker/manage",
        name: "workerManage",
        component: () => import("@/view/worker/manage"),
    },
    {
        path: "/worker/manage/create",
        name: "workerManageCreate",
        component: () => import("@/view/worker/create"),
    },
    {
        path: "/worker/manage/details",
        name: "workerManageDetails",
        component: () => import("@/view/worker/details"),
    },
];
export default log;
