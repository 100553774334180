import { allNum } from "@/api/message/index";
import { manageList } from "@/api/message/index";

const allNums = {
    state: {
        stateRed: 2,
        allMessageNum:0, //消息总数
        allColor:4, //消息等级
    },
    getters: {
        stateRed(state) {
            return state.stateRed
        },
        allMessageNum(state) {
            return state.allMessageNum
        },
        allColor(state) {
            return state.allColor
        }
    },
    mutations: {
        setStateRed(state,info) {
            state.stateRed = info.red;
            console.log(state.stateRed);
        },
        setAllColorNum(state,color) {
            state.allColor = color
        },
        setAllMessNum(state,newNum) {
            state.allMessageNum = newNum;
            console.log(newNum,'222');
        }
    },
    actions: {
        async getStateRed({commit}) {
            const result = await allNum().then(res => {
                return res.data;
            });
            commit('setStateRed', result);

            manageList({}).then((res) => {
                const newNum =  Number(res.data.oneNum) + Number(res.data.twoNum) + Number(res.data.threeNum) + Number(res.data.fourNum) + Number(res.data.fiveNum)
                let newColor
                if(res.data.oneLevel == 3 || res.data.twoLevel == 3 || res.data.threeLevel == 3 || res.data.fourLevel == 3 || res.data.fiveLevel == 3 ) {
                    newColor = 3
                } else if(res.data.oneLevel == 2 || res.data.twoLevel == 2 || res.data.threeLevel == 2 || res.data.fourLevel == 2 || res.data.fiveLevel == 2 ) {
                    newColor = 2
                } else if(res.data.oneLevel == 1 || res.data.twoLevel == 1 || res.data.threeLevel == 1 || res.data.fourLevel == 1 || res.data.fiveLevel == 1 ) {
                    newColor = 1
                } else if(res.data.oneLevel == 4 || res.data.twoLevel == 4 || res.data.threeLevel == 4 || res.data.fourLevel == 4 || res.data.fiveLevel == 4 ) {
                    newColor = 4
                }
              commit('setAllColorNum',newColor);
              commit('setAllMessNum',newNum);
              });

        }
    }

}
export default allNums
