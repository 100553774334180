<template>
  <dv-border-box-8 class="dv-border-box-8" backgroundColor="#0A1C3C">
    <dv-border-box-7 :color="['#203A64', '#70FFF9']">
      <div
        style="
          width: 300px;
          position: absolute;
          margin-left: 20px;
          margin-top: 20px;
        "
      >
        <dv-border-box-13 class="dv-border-box-13">
          <div style="width: 220px; margin: 8px auto">
            <el-select
              ref="optionRef"
              v-model="regionState"
              placeholder="请选择"
              :popper-append-to-body="false"
              clearable
              @change="getChange"
              @clear="getClear"
            >
              <el-option
                v-for="item in selectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                style="background-color: #0a1c3c"
              >
              </el-option>
            </el-select>
          </div>
        </dv-border-box-13>
      </div>

      <div class="padding20">
        <EchartsMap :mapLists="mapList" :mapListNums="mapListNum" />
      </div>
    </dv-border-box-7>
  </dv-border-box-8>
</template>

<script>
import EchartsMap from "./echartsMap.vue";
export default {
  components: {
    EchartsMap,
  },
  props: {
    mapList: Array,
    mapListNum: Array,
    subassembly: Function,
    selectList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      regionState: "",
      options: [],
    };
  },
  methods: {
    getChange(e) {
      this.regionState = e;
      this.$nextTick(() => {
        this.subassembly(e, this.$refs.optionRef.selected.label);
      });
    },
    getClear() {
      this.$nextTick(() => {
        this.subassembly("", "全区域");
      });
    },
  },
};
</script>

<style  scoped>
.dv-border-box-8 {
  height: 866px;
  position: relative;
}
.dv-border-box-13 {
  width: 220px;
  height: 50px;
  padding: 10px 20px;
  z-index: 99999999999;
}
/deep/.el-input--suffix .el-input__inner {
  padding-right: 30px;
  background-color: #0a1c3c;
}
/deep/ .el-select-dropdown {
  /* border: none !important; */
  background: #0a1c3c !important;
  z-index: 9999;
}

/deep/ .el-select-dropdown__item {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: #0a1c3c;
  z-index: 9999;
}
/deep/ .el-select-dropdown__item.hover {
  background-color: rgba(20, 40, 80, 0.8) !important;
  color: #fff !important;
  z-index: 9999;
}
/deep/ .el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #0a1c3c;
  z-index: 9999;
}

/* // input框 */
/deep/ .el-select,
/deep/ .el-input,
/deep/ .el-input__inner {
  background-image: linear-gradient(1#0f2244, #0a1c3d) !important ;
  color: rgba(255, 255, 255, 0.5);
  border: none;
  text-align: left;
  border-radius: 4px;
}
/* // 鼠标悬浮时 input框颜色 */
/* /deep/ .el-input__inner:hover{
        background-color: rgba(255, 255, 255, 0.12);
    } */

/* // input框 右侧的箭头 */
/* /deep/ .el-select .el-input .el-select__caret {
        color: rgba(255, 255, 255, 0.50);
    } */

/* // option选项 上面的箭头 */
/* /deep/ .el-popper[x-placement^="bottom"] .popper__arrow::after {
        border-bottom-color: #0a1c3c;
        z-index: 9999;
    } */
/* /deep/ .popper__arrow {
        border: none;
    } */

/* /deep/ .el-select-dropdown__item.selected span{
        color: rgba(255, 255, 255, 0.80) !important;
        z-index: 9999;
    } */

/* /deep/ .el-select-dropdown__item.hover{
        background-color: rgba(20, 40, 80, 0.8)!important;
        color: #fff !important;
        z-index: 9999;
    } */
</style>