import {get,post,put,Delete} from "@/api/axios";

// /web/house/label/list get
export const list = params => get('/web/house/label/list', params);

// /web/house/label/add post
export const add = params => post('/web/house/label/add', params);

// /web/house/label/update put
export const update = params => put('/web/house/label/update', params);

// /web/house/label/delete/{id} delete
export const deleteNew = params => Delete('/web/house/label/delete/' + params.id, '');

// /web/house/label/view/{id} get
export const view = params => get('/web/house/label/view/' + params.id, '');

// /web/house/label/state/update put
export const updateNew = params => put('/web/house/label/state/update', params);