import { get, post, put, Delete } from "@/api/axios";

// 列表
export const list = params => get('/web/sale/manage/list', params);

// 详情
export const view = params => get('/web/sale/manage/view/' + params.id, '');

// 车辆行驶轨迹
export const carTravel = params => get('/web/sale/manage/travel', params);

// 车辆行驶轨迹-位置信息
export const carTravelAddress = params => get('/web/sale/manage/travel/address', params);

// 车辆列表-车辆详情-时长统计-车辆行驶轨迹
export const carDurationTravel = params => get('/web/car/manage/detail/duration/travel', params);

// 审核
export const examine = params => put('/web/sale/manage/examine', params);
