<template>
  <div>

    <div>
      <div class="flex align-items marginBottom10 flex-wrap">
        <div class="flex align-items marginRight10 marginBottom10">
          <span class="marginRight10">车型编号:</span>
          <div class="width200">
            <el-input
              v-model="searchData.code"
              placeholder="请输入"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="20"
            ></el-input>
          </div>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <span class="marginRight10">车型名称:</span>
          <div class="width200">
            <el-input
              v-model="searchData.name"
              placeholder="请输入"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="20"
            ></el-input>
          </div>
        </div>

        <div class="marginRight10 marginBottom10">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            class="marginLeft10"
          >查询</el-button>
        </div>

        <div class="marginRight10 marginBottom10">
          <el-button
            type="info"
            icon="el-icon-refresh-right"
            @click="handleReset"
            class="marginLeft10"
          >重置</el-button>
        </div>

      </div>
    </div>

    <avue-crud
      ref="ongoingTask"
      class="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
      @selection-change="selectionChange"
    >

      <template
        slot="lowGearSpeed"
        slot-scope="{ row }"
      >
        <el-radio
          v-model="row.gearSpeed"
          label="1"
          @change="gearSpeedHandle(row,'1')"
        >{{ '' }}</el-radio>{{ row.lowGearSpeed }}
      </template>
      <template
        slot="highGearSpeed"
        slot-scope="{ row }"
      >
        <el-radio
          v-model="row.gearSpeed"
          label="2"
          @change="gearSpeedHandle(row,'2')"
        >{{ '' }}</el-radio>{{ row.highGearSpeed }}
      </template>

    </avue-crud>

    <div style="text-align:right;margin-top:20px">
      <el-button
        type="primary"
        @click="selectClassify"
      >确定</el-button>
    </div>

  </div>
</template>

<script>
import { getCarTypeList } from "@/api/region/manage";
export default {
  name: "goods",
  props: {
    defaultData: {
      type: Array,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectList: [],
      searchData: {
        name: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: "mini",
        selection: true, // 显示多选框
        reserveSelection: true,
        rowKey: "id",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "车型编号",
            prop: "code",
          },
          {
            label: "车型名称",
            prop: "name",
          },
          {
            label: "低档速度（km/h）",
            prop: "lowGearSpeed",
          },
          {
            label: "高档速度（km/h）",
            prop: "highGearSpeed",
          },
          {
            label: "默认缓冲区距离（米）",
            prop: "bufferDistance",
          },
          {
            label: "每1%电量预计行驶时间（分钟）",
            prop: "travelTime",
          },
        ],
      },
    };
  },
  watch: {
    show(value) {
      this.onLoad();
    },
  },
  methods: {
    gearSpeedHandle(row, gearSpeed) {
      this.selectList = this.selectList.map((item) => {
        if (row.id == item.id) {
          return {
            ...item,
            gearSpeed,
          };
        }
        return item;
      });
    },
    toggleSelection(val) {
      this.$refs.ongoingTask.toggleSelection(val);
    },
    onLoad() {
      this.showLoading = true;
      getCarTypeList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records.map((item) => {
            return {
              ...item,
              gearSpeed:
                this.defaultData.find((items) => items.id === item.id)
                  ?.gearSpeed || "0",
            };
          });

          this.page.total = res.data.total;

          if (this.show) {
            console.log(this.tableData);
            console.log(this.defaultData);

            this.toggleSelection(this.defaultData);
          } else {
            this.toggleSelection();
          }
        }
      });
    },
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    handleReset() {
      this.searchData = {};
      this.page.currentPage = 1;
      this.onLoad();
    },
    selectClassify() {
      let selectData = false;
      if (this?.selectList?.length > 0) {
        this.selectList.find((item) => {
          if (!item.gearSpeed || item.gearSpeed == 0) {
            selectData = true;
            return;
          }
        });
      }
      if (selectData) {
        this.$message.warning("请选择对应的档速");
        return;
      }
      const selectList = this.selectList.map((item) => {
        return {
          ...item,
          state: item?.state || "1",
          carId: !item?.carId ? [] : item.carId,
        };
      });
      this.$emit("change", selectList);
    },
    selectionChange(list) {
      this.selectList = list;
    },
  },
};
</script>

<style scoped>
/* .crud /deep/.el-card__body .el-form .el-table .el-table__fixed-right {
  height: 100% !important;
} */
</style>