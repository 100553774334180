const house = [
    {
        path: '/fault/handle',
        name: 'faultHandle',
        component: () => import('@/view/fault/handle'),
    },
    {
        path: '/fault/manage',
        name: 'faultManage',
        component: () => import('@/view/fault/manage'),
    },
    {
        path: '/fault/manage/details',
        name: 'faultManageDetails',
        component: () => import('@/view/fault/manage/details'),
    },
    {
        path: '/fault/manage/ideaHandle',
        name: 'faultManageIdeaHandle',
        component: () => import('@/view/fault/manage/ideaHandle'),
    }
]
export default house