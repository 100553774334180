<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >

      <template #header>
        <div class="flex justify-content-space-between align-items marginBottom20">
          <div class="flex align-items">
            <div class="flex align-items marginRight10">
              <span class="span marginRight10">标题 : </span>
              <div class="width200">
                <el-input
                  v-model="searchData.name"
                  placeholder="输入车型名称"
                  maxlength="10"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </div>
            </div>
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>
          </div>

        </div>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
        >编辑</el-button>
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? '编辑' : '新增'}`"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >

        <template slot="name">
          {{ form.name }}
        </template>

        <template slot="depositPrice">
          <el-input
            v-model="form.depositPrice"
            placeholder="请输入"
            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            @blur="salaryDianChange($event,'depositPrice',999999.99)"
            type="text"
            clearable
            :disabled="disabled"
          />
        </template>

        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleClose"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { list, update } from "@/api/deposit/setting";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        name: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "押金设置",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "车型名称",
            prop: "name",
          },
          {
            label: "押金金额(元)",
            prop: "depositPrice",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        type: "",
        name: "",
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "140",
        column: [
          {
            label: "车型名称",
            prop: "name",
            span: 24,
          },
          {
            label: "押金金额(元)",
            prop: "depositPrice",
            type: "number",
            maxRows: 999999.99,
            minRows: 0,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入押金金额",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        name: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 编辑
    async handleEdit(row) {
      // console.log('编辑',row)
      //   const { data } = await view({
      //     id: row.id,
      //   });
      // console.log(data)
      this.form = row;
      this.dialogVisible = true;
    },
    // 提交
    submit(form, done) {
      console.log("form", form);
      if (form.id) {
        update(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      }
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
        name: "",
      };
    },
  },
};
</script>

<style scoped>
</style>