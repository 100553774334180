const yuanbing = {
  title: {
    text: '区域车型',
    left: 'center',
    top: '45%',
    textStyle: {
      color: '#fff',
      fontSize: 12,
      align: 'center',
    },
  },
  tooltip: {
    trigger: 'item',
  },
  legend: {
    type: 'scroll',
    right: 10,
    top: 4,
    bottom: 0,
    itemWidth: 10,
    itemHeight: 10,
    textStyle: {
      fontSize: 12,
      color: '#fff'
    },
    pageTextStyle:{
      color: '#fff'
    },
  },
  grid: {
    // top: '0',
    left: '0%',
    right: '0',
    containLabel: true
  },
  label: {
    alignTo: 'edge',
    formatter: '{name|{b}}\n{time|{c} %}',
    minMargin: 0,
    edgeDistance: 10,
    lineHeight: 11,
    rich: {
      time: {
        fontSize: 10,
        color: '#999'
      }
    }
  },
  series: {
    name: '区域车型',
    type: 'pie',
    radius: ['24%', '40%'],
    data: [ ],
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
  },
};



export {
  yuanbing,
};
