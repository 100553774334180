import { get, post, put, Delete } from "@/api/axios";

// 列表
export const list = params => get('/web/partner/manage/list', params);

// 会员列表
export const userList = params => get('/web/partner/manage/user/list', params);

// 销售合伙人列表
export const getTypeList = params => get('/web/partner/manage/type/list', params);

// 优惠劵列表
export const getCouponList = params => get('/web/partner/manage/coupon/list', params);

// 获取手机/联系电话验证码
export const getPhoneCode = params => get('/web/partner/manage/message/get', params);

// 获取手机/联系电话验证码
export const getEmailCode = params => get('/web/partner/manage/email/get', params);

// 添加
export const add = params => post('/web/partner/manage/add', params);

// 修改
export const update = params => put('/web/partner/manage/update', params);

// 删除
export const deleteNew = params => Delete('/web/partner/manage/delete/' + params.id, '');

// 查看
export const view = params => get('/web/partner/manage/view/' + params.id, '');

// 修改
export const updateNew = params => put('/web/partner/manage/state/update', params);

// 收益明细
export const revenueList = params => get('/web/partner/detail/list', params);

// 绑定关系
export const bindingRelationshipList = params => get('/web/partner/bind/list', params);

// 好友裂变设置-修改
export const atomicFissionUpdate = params => put('/web/setting/give/update', params);

// // 好友裂变设置-查看
// export const atomicFissionView = params => get('/web/level/discount/view/' + params.id, '');

// 好友裂变设置-列表
export const atomicFissionList = params => get('/web/setting/give/list', params)
