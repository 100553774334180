import { get, post, put, Delete } from "@/api/axios";

// 获取优惠劵列表
export const list = params => get('/web/coupon/manage/list', params);

// 添加优惠劵
export const add = params => post('/web/coupon/manage/add', params);

// 修改优惠劵
export const update = params => put('/web/coupon/manage/update', params);

// 作废优惠劵
export const deleteNew = params => get('/web/coupon/manage/cancel/' + params.id, '');

// 查询banner详情
export const view = params => get('/web/coupon/manage/view/' + params.id, '');

//选择区域列表
export const regionList = params => get('/web/coupon/manage/region/list', params)

