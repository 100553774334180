<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">

            <div class="marginRight10 marginBottom10">
              <div class="width300">
                <el-input
                  v-model="searchData.name"
                  placeholder="输入会员编号/会员昵称/会员手机号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="marginRight10 marginBottom10">
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>

            </div>

            <div class="marginRight10 marginBottom10">
              <div class="width200">
                <el-select
                  class="width200"
                  v-model="searchData.payType"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="支付渠道"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="微信"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="余额"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="marginRight10 marginBottom10">
              <div class="width200">
                <el-select
                  class="width200"
                  v-model="searchData.depositState"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="状态"
                  ></el-option>
                  <el-option
                    :value="3"
                    label="申请中"
                  ></el-option>
                  <el-option
                    :value="4"
                    label="已退款"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10"
              >查询</el-button>
            </div>

            <div class="marginRight10 marginBottom10">

              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10"
              >重置</el-button>
            </div>

            <div class="marginRight10 marginBottom10">
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-download"
                @click="handleExport"
              >导出</el-button>
            </div>
          </div>

        </div>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          v-if="row.depositState	 === 3"
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="deductionMoneyHandle(row)"
        >扣除押金</el-button>
        <el-button
          v-if="row.depositState	 === 3"
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="isAgreestHandle(row)"
        >同意</el-button>
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      title="扣除押金"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >
        <template slot="parkFeeDeduct">
          <el-input
            v-model="form.parkFeeDeduct"
            placeholder="请输入停车费"
            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            @blur="salaryDianChange($event,'parkFeeDeduct',999999.99)"
            type="text"
            clearable
          />
        </template>

        <template slot="otherDeduct">
          <el-input
            v-model="form.otherDeduct"
            placeholder="请输入其他扣除"
            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            @blur="salaryDianChange($event,'otherDeduct',999999.99)"
            type="text"
            clearable
          />
        </template>
        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleClose"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  list,
  deductionMoneyHandle,
  isAgreestHandle,
} from "@/api/deposit/apply";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        name: "",
        depositState: "",
        createTime: "",
        payType: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "退还押金申请",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "订单编号",
            prop: "orderCode",
          },
          {
            label: "会员编号",
            prop: "code",
          },
          {
            label: "会员昵称",
            prop: "nickname",
          },
          {
            label: "会员手机号",
            prop: "phone",
          },
          {
            label: "支付渠道",
            prop: "payType",
            formatter: function (row, column, cellValue) {
              return row.payType === 1
                ? "微信"
                :  row.payType === 2
                ? "余额"
                :  row.payType;
            },
          },
          {
            label: "申请时间",
            prop: "depositTime",
          },
          {
            label: "押金金额(元)",
            prop: "depositPrice",
          },
          {
            label: "停车费扣除(元)",
            prop: "parkFeeDeduct",
          },
          {
            label: "其它扣除(元)",
            prop: "otherDeduct",
          },
          {
            label: "退还金额(元)",
            prop: "returnPrice",
          },
          {
            label: "状态",
            prop: "depositState",
            formatter: function (row, column, cellValue) {
              return  row.depositState === 3
                ? "申请中"
                :  row.depositState === 4
                ? "已退款"
                :  row.depositState;
            },
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "140",
        column: [
          {
            label: "停车费扣除（元）",
            prop: "parkFeeDeduct",
            type: "number",
            maxRows: 999999.99,
            // minRows: 0,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入停车费",
                trigger: "blur",
              },
            ],
          },
          {
            label: "其他扣除（元）",
            prop: "otherDeduct",
            type: "number",
            // value: 1,
            maxRows: 999999.99,
            minRows: 0,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入其他扣除",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
      if (
        searchForm.searchData.createTimeStart &&
        searchForm.searchData.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData.createTimeStart,
          searchForm.searchData.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    // 扣除押金
    async deductionMoneyHandle(row) {
      // console.log(data)
      this.form = row;
      this.dialogVisible = true;
    },
    //是否同意退还押金申请
    isAgreestHandle(row) {
      this.$confirm("是否同意退还押金申请?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        isAgreestHandle({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("提交成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    onLoad() {
      this.showLoading = true;
      const searchData = {
        depositState: this.searchData.depositState,
        name: this.searchData.name,
        payType: this.searchData.payType,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));

      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        depositState: "",
        name: "",
        createTime: "",
        payType: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 提交
    submit(form, done) {
      if ( Number(form.depositPrice) < (Number(form.parkFeeDeduct) + Number(form.otherDeduct)) ) {
        this.$message.warning("扣除的押金总和不能大于押金金额");
        done();
        return;
      }

      if (form.id) {
        deductionMoneyHandle(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      }
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      };
    },

    // 导出
    handleExport() {
      const searchData = {
        depositState: this.searchData.depositState,
        name: this.searchData.name,
        payType: this.searchData.payType,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/deposit/apply/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },

    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
  },
};
</script>

<style scoped>
/deep/.el-table .cell {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 22px;
    padding-right: 10px;
}
</style>