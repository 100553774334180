import { get, post, put, Delete } from "@/api/axios";

// 消息接收人设置-列表
export const receiveList = (params) => get("/web/message/receive/list", params);

// 消息接收人设置-角色-列表
export const roleList = (params) => get("/web/message/receive/role/list", params);

// 消息接收人设置-接收模块修改
export const typeUpdate= (params) => post("/web/message/receive/type/update", params);

// 消息接收人设置-区域修改
export const regionUpdate= (params) => post("/web/message/receive/region/update", params);
