<template>
  <div class="main-content">
    <h3 class="marginBottom20">
      {{ type === "create" ? "新增财务报表白名单" : "编辑财务报表白名单" }}
    </h3>
    <avue-form
      ref="form"
      v-model="form"
      v-loading="loading"
      :option="formOption"
      @submit="submit"
    >
      <template slot="id">
        <el-button
          type="text"
          v-if="JSON.stringify(form.regionId) == '{}'"
          @click="handleSelect1"
        >请选择会员</el-button>
        <el-button
          type="text"
          v-else
          @click="handleSelect1"
        >{{
          form.regionId.code
        }}</el-button>
      </template>

      <template slot="regionIds">
        <div style="text-align:right">
          <el-button
            type="primary"
            @click="handleSelect"
          >选择区域</el-button>
        </div>

        <el-table
          :data="form.regionIds"
          style="width: 100%"
          height="250"
        >
          <el-table-column
            fixed
            prop="code"
            label="区域编号"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="区域名称"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="state"
            label="区域状态"
            width="120"
          >
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{
                scope.row.state == 1
                  ? "正常"
                  : scope.row.state == 2
                  ? "冻结"
                  : ""
              }}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <template slot="menuForm">
        <el-button
          icon="el-icon-close"
          @click="handleClose"
        >取消</el-button>
      </template>
    </avue-form>

    <SelectLink
      :show="show"
      :defaultData="regionIds"
      @change="handleSelectData"
      @newchange="handleSelectNewData"
      @close="handleSelectClose"
    />
    <UserLink
      :show="show1"
      :defaultData="form.regionId"
      @change="handleSelectData1"
      @close="handleSelectClose1"
    />
  </div>
</template>

<script>
import { whiteAdd, whiteUpdate, whiteView } from "@/api/finance/index";
import SelectLink from "./components/selectLink";
import UserLink from "./components/userLink";
export default {
  name: "create",
  components: {
    SelectLink,
    UserLink,
  },
  data() {
    return {
      type: this.$route.query.type,
      id: this.$route.query.id,
      loading: false,
      form: {
        title: "基本信息",
        regionIds: [],
        regionId: {},
        title1: "可用区域",
      },
      regionIds: [],
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "",
            labelWidth: 20,
            type: "title",
            prop: "title",
            span: 24,
            styles: {
              fontSize: "16px",
              borderBottom: "1px solid #eee",
              paddingBottom: "10px",
            },
          },
          {
            label: "选择会员",
            prop: "id",
            span: 12,
            row: true,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请选择会员",
              },
            ],
          },
          {
            label: "姓名",
            prop: "financeUsername",
            maxlength: 20,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入姓名",
            rules: [
              {
                required: true,
                message: "请输入姓名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "职位",
            prop: "financePosition",
            maxlength: 20,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入职位",
            rules: [
              {
                required: true,
                message: "请输入职位",
                trigger: "blur",
              },
            ],
          },
          {
            label: "手机号",
            prop: "financePhone",
            maxlength: 11,
            showWordLimit: true,
            span: 12,
            row: true,
            disabled:true,
            placeholder: "请输入手机号",
            rules: [
              {
                required: true,
                message: "请输入手机号",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (!/^1[3456789]\d{9}$/.test(value)) {
                    callback(new Error("请输入正确的手机号"));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          },
          {
            label: "备用手机号",
            prop: "financeSparePhone",
            maxlength: 11,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入备用手机号",
            rules: [
              // {
              //   required: true,
              //   message: "请输入备用手机号",
              //   trigger: "blur",
              // },
              {
                validator: (rule, value, callback) => {
                  if (value && !/^1[3456789]\d{9}$/.test(value)) {
                    callback(new Error("请输入正确的手机号"));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          },
          {
            label: "",
            labelWidth: 20,
            type: "title",
            prop: "title1",
            span: 24,
            styles: {
              fontSize: "16px",
              borderBottom: "1px solid #eee",
              paddingBottom: "10px",
            },
          },
          {
            label: "使用范围",
            prop: "financeUseScope",
            type: "radio",
            value: 1,
            dicData: [
              { label: "全区域", value: 1 },
              { label: "指定区域", value: 2 },
            ],
            span: 16,
            row: true,
            rules: [
              {
                required: true,
                message: "请选择使用范围",
                trigger: "blur",
              },
            ],
            change: (e) => {
              const regionIds = this.findObject(
                this.formOption.column,
                "regionIds"
              );
              if (e.value === 1) {
                regionIds.display = false;
              } else {
                regionIds.display = true;
              }
            },
          },
          {
            label: "选择指定区域",
            prop: "regionIds",
            span: 16,
            row: true,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请选择指定区域",
                trigger: "blur",
              },
            ],
            display: true,
          },
        ],
      },
      show: false,
      selectData: {
        id: "",
      },
      show1: false,
    };
  },
  mounted() {
    this.$route.query.id && this.getView();
  },
  methods: {
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    handleSelect() {
      this.show = true;
      this.regionIds = this.form.regionIds;
    },
    handleSelectData(e) {
      this.form.regionIds = e;
      this.regionIds = e;
      this.handleSelectClose();
    },
    handleSelectNewData(e) {
      this.regionIds = e;
    },
    handleSelectClose() {
      this.show = false;
    },
    handleDelete(row) {
      this.form.regionIds = this.form.regionIds.filter(
        (item) => item.id !== row.id
      );
      this.regionIds = this.regionIds.filter((item) => item.id !== row.id);
    },

    getView() {
      this.loading = true;
      whiteView({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.form = {
            ...res.data,
            regionIds: res.data.regions,
          };
          this.regionIds = res.data.regions;

          this.form.regionId = {
            id: res.data.id,
            code: res.data.code,
          };
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    submit(form, done) {
      let data = {
        id: this.form.regionId.id,
        financeUsername: this.form.financeUsername,
        financePosition: this.form.financePosition,
        financePhone: this.form.financePhone,
        financeUseScope: this.form.financeUseScope,
        financeSparePhone:this.form.financeSparePhone
      };
      if (this.form.financeUseScope === 2) {
        data.regionIds = [];
        this.form.regionIds.map((item) => {
          data.regionIds.push(item.id);
        });
      }
      if (this.id) {
        whiteUpdate(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      } else {
        whiteAdd(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      }
    },
    handleClose() {
      this.$router.go(-1);
    },

    // 会员选择
    handleSelect1() {
      if (this.type === "create") {
        this.show1 = true;
      }
    },
    handleSelectData1(e) {
      this.form.regionId = e;
      this.form.id = e.id;
      this.form.financePhone = e.phone
      this.handleSelectClose1();
    },

    handleSelectClose1() {
      this.show1 = false;
    },
  },
};
</script>

<style scoped></style>
