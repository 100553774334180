<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex flex-wrap align-items marginBottom20">

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">优惠券名称: </span> -->
            <div class="width250">
              <el-input
                v-model="searchData.name"
                placeholder="输入车型名称"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="10"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">

            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>

          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-plus"
              @click="handleCreate"
            >新建</el-button>
          </div>

        </div>
      </template>

      <template
        slot="carTypeDesc"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          @click="onCarTypeDescHandle(row)"
        >查看</el-button>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
        >编辑</el-button>
        <el-button
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
        >删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      :title="form.id?'编辑车型':'新建车型'"
      :visible.sync="dialogVisible"
      width="54%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-after="uploadAfter"
        :upload-before="uploadBefore"
      >
        <template slot="code">
          {{ form.id?form.code:'系统自动生成' }}
        </template>

        <template slot="couponName">
          车型编号
        </template>

        <template slot="bufferDistance">
          <el-input
            v-model="form.bufferDistance"
            placeholder="请输入 缓冲区距离"
            type="text"
            onkeyup="this.value=this.value.match(/\d+\.?\d{0,1}/)"
            @blur="salaryDianChange($event,'bufferDistance',999999.9)"
          >
            <template slot="append">米</template>
          </el-input>
        </template>

        <template slot="travelTime">
          <div class="flex travel">
            <div class="travel-list">
              <div class="travel-list-title">低档速度（km/h）</div>
              <div>
                <el-input
                  v-model="form.lowGearSpeed"
                  placeholder="请输入 低档速度"
                  type="text"
                  onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                  @blur="salaryChange($event,'lowGearSpeed',999999)"
                  @change="onTravelTime"
                />
              </div>
            </div>
            <div class="travel-list">
              <div class="travel-list-title">高档速度（km/h）</div>
              <div>
                <el-input
                  v-model="form.highGearSpeed"
                  placeholder="请输入 高档速度"
                  type="text"
                  onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                  @blur="salaryChange($event,'highGearSpeed',999999)"
                  @change="onTravelTime"
                />
              </div>
            </div>
            <div class="travel-list">
              <div class="travel-list-title">每1%电量预计行驶时间（分钟）</div>
              <div>
                <el-input
                  v-model="form.travel"
                  placeholder="请输入 每1%电量预计行驶时间"
                  type="text"
                  onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                  @blur="salaryChange($event,'travel',999999)"
                  @change="onTravelTime"
                />
              </div>
            </div>
          </div>
        </template>

        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleClose"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog
      title="车型描述"
      :visible.sync="dialogTableVisibleUse"
    >
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="dialogTableVisibleUse = false"
        >确 定</el-button>
      </div>
      <div>
        {{ CarTypeDescData }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, deleteNew, list, update, view } from "@/api/car/type";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        type: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      CarTypeDescData: {},
      tableData: [],
      showLoading: false,
      option: {
        title: "车型库设置",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "车型编号",
            prop: "code",
          },
          {
            label: "车型名称",
            prop: "name",
          },
          {
            label: "车型图片",
            prop: "image",
            type: "img",
          },
          {
            label: "车型描述",
            prop: "carTypeDesc",
          },
          {
            label: "低档速度（km/h）",
            prop: "lowGearSpeed",
          },
          {
            label: "高档速度（km/h）",
            prop: "highGearSpeed",
          },
          {
            label: "每1%电量预计行驶时间（分钟）",
            prop: "travelTime",
          },
          {
            label: "缓冲区距离",
            prop: "bufferDistance",
          },
        ],
      },
      dialogTableVisibleUse: false,
      dialogVisible: false,
      form: {
        id: "",
        type: "",
        name: "",
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "100",
        column: [
          {
            label: "车型编号",
            prop: "code",
            span: 24,
          },
          {
            label: "车型名称",
            prop: "name",
            span: 24,
            maxlength: 10,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入车型名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "车型图片",
            prop: "image",
            type: "upload",
            listType: "picture-img",
            accept: "image/jpeg,image/png",
            propsHttp: {
              res: "data",
              url: "path",
              name: "",
            },
            headers: {
              Authorization: this.$store.state.user.Token,
            },
            action: this.actionUrl,
            tip: "建议尺寸：750*360,最大20M",
            fileSize: 20 * 1024,
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传车型图片",
                trigger: "blur",
              },
            ],
          },
          {
            label: "车型描述",
            prop: "carTypeDesc",
            type: "textarea",
            span: 24,
            maxlength: 500,
            showWordLimit: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入车型描述",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "缓冲区距离",
            prop: "bufferDistance",
            span: 24,
            // maxlength: 4,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入缓冲区距离",
                trigger: "blur",
              },
            ],
          },
          {
            label: "速度档位",
            prop: "travelTime",
            span: 24,
            maxlength: 4,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请完善信息",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onTravelTime() {
      if (
        this.form.lowGearSpeed &&
        this.form.highGearSpeed &&
        this.form.travel
      ) {
        if (this.form.travel > 999999) {
          this.form.travelTime = 999999;
        } else {
          this.form.travelTime = this.form.travel;
        }
      } else {
        this.form.travelTime = "";
      }
    },
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            if (t === "travel" && value < 1) {
              this.form[t] = 1;
              e.target.value = 1;
              return;
            }
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },

    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(1);
            e.target.value = max.toFixed(1);
          } else {
            this.form[t] = parseFloat(value).toFixed(1);
            e.target.value = parseFloat(value).toFixed(1);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 添加
    handleCreate() {
      // console.log('添加')
      this.dialogVisible = true;
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      localStorage.removeItem("searchForm");
      this.searchData = {
        name: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 编辑
    async handleEdit(row) {
      // console.log('编辑',row)
      const { data } = await view({
        id: row.id,
      });
      // console.log(data)
      this.form = data;
      this.form.travel = this.form.travelTime;
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    //查看区域
    onCarTypeDescHandle(row) {
      this.dialogTableVisibleUse = true;
      this.CarTypeDescData = row.carTypeDesc;
    },

    // 提交
    submit(form, done) {
      // console.log(form)
      if (form.id) {
        update(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      } else {
        add(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      }
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
        name: "",
      };
    },
    uploadDelete(file, column) {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      error && this.$message.error(error);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadAfter(res, done, loading, column) {
      done();
      if (!res.path) {
        let msg = JSON.parse(JSON.stringify(res)).message;
        if (msg.indexOf("401") > -1) {
          this.$message.error("登录失效，请重新登录");
          this.$router.push("/login");
        } else {
          this.$message.error("上传失败");
        }
        loading();
      } else {
        column.propsHttp.name = res.path;
      }
    },
  },
};
</script>

<style scoped>
.travel .travel-list {
  width: 33%;
  text-align: center;
  border-left: 1px solid #999;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}

.travel .travel-list:last-child {
  border-right: 1px solid #999;
}

.travel .travel-list .travel-list-title {
  border-bottom: 1px solid #999;
}

.avue-crud .el-input--small input,
::v-deep .avue-form .travel .travel-list .el-input--small input {
  border: none;
}
</style>