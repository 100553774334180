<template>
  <div class="main-content">
    <h3 class="marginBottom20">车辆详情</h3>
    <div class="flex justify-content-space-between">
      <!-- <el-button @click="goBack()">返回</el-button> -->
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane
        label="基础信息"
        name="createOne"
      >
        <createOne
          :id="id"
          v-if="activeName==='createOne'"
          @onCreateOneHandle="onCreateOneHandle"
        />
      </el-tab-pane>
      <el-tab-pane
        label="运营情况"
        name="createTwo"
      >
        <createTwo
          :id="id"
          v-if="activeName==='createTwo'"
        />

      </el-tab-pane>
      <el-tab-pane
        label="时长统计"
        name="createThree"
      >
        <createThree
          :id="id"
          v-if="activeName==='createThree'"
        />
      </el-tab-pane>
      <el-tab-pane
        label="行驶轨迹"
        name="createFour"
      >
        <createFour
          :id="id"
          v-if="activeName==='createFour'"
          :form="createOneData"
        />

      </el-tab-pane>
      <el-tab-pane
        label="车辆信息"
        name="createFive"
      >
        <createFive
          :id="id"
          v-if="activeName==='createFive'"
        />

      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import createOne from "./components/createOne/index.vue";
import createTwo from "./components/createTwo/index.vue";
import createThree from "./components/createThree/index.vue";
import createFour from "./components/createFour/look.vue";
import createFive from "./components/createFive/index.vue";

export default {
  name: "info",
  components: {
    createOne,
    createTwo,
    createThree,
    createFour,
    createFive,
  },
  props:{
    ids:{
      type:String,
    }
  },
  data() {
    return {
      activeName: "createOne",
      id: "",
      createOneData: {},
    };
  },
  created() {
    this.id = this.ids;
  },
  methods: {
    // goBack() {
    //   this.$router.go(-1);
    // },
    onCreateOneHandle(e) {
      this.createOneData = e;
    },
  },
};
</script>

<style scoped>
</style>