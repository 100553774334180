<template>
  <div>
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex align-items marginBottom20">
            <div class="flex align-items marginRight10 ">
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>
            </div>

            <div class="flex align-items marginRight10 ">
              <!-- <span class="span marginRight10">订单状态 : </span> -->
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.type"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="请选择"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="扫码挪车"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="扫码开车"
                  ></el-option>
                  <el-option
                    :value="3"
                    label="故障换车"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>

            <div class="marginLeft10 flex align-items marginRight10">
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-download"
                @click="handleExport"
              >导出明细</el-button>
            </div>

          </div>

        </div>

        <div class="order-box">
          <div class="order-box-list">
            <h3 class="order-box-title">{{ orderData.timeNum }}</h3>
            <div class="order-box-info">使用时长(分钟) </div>
          </div>
        </div>

      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          @click="handleDetails(row)"
        >详情</el-button>
      </template>

    </avue-crud>

  </div>
</template>

<script>
import { durationList, durationCount } from "@/api/car/manage";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  props: {
    id: {
      typeof: String,
    },
  },
  data() {
    return {
      searchData: {
        createTime: "",
        type: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "使用明细",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "开锁用户",
            prop: "username",
          },
          {
            label: "开锁时间",
            prop: "startTime",
          },
          {
            label: "使用时长(分钟)",
            prop: "timeNum",
          },
          {
            label: "使用方式",
            prop: "type",
            formatter: function (row, column, cellValue) {
              return row.type === 1
                ? "扫码挪车"
                : row.type === 2
                ? "扫码开车"
                : row.type === 3
                ? "故障换车"
                : row.type;
            },
          },
        ],
      },
      form: {
        id: "",
        createTime: "",
        type: "",
      },
      orderData: {
        timeNum: "",
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (
      searchForm?.path === this.$route.path &&
      searchForm?.name === "createTwo"
    ) {
      this.searchData = {
        type: this.searchData.type,
      };
      if (
        searchForm.searchData?.createTimeStart &&
        searchForm.searchData?.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData?.createTimeStart,
          searchForm.searchData?.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
    this.orderCount();
  },
  methods: {
    orderCount() {
      const searchData = {
        type: this.searchData.type,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      durationCount({
        id: this.id,
        ...searchData,
      }).then((res) => {
        this.orderData = res.data;
      });
    },
    onLoad() {
      this.showLoading = true;
      const searchData = {
        type: this.searchData.type,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem(
        "searchForm",
        JSON.stringify({ ...searchForm, name: "createTwo" })
      );

      durationList({
        id: this.id,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
      this.orderCount();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        createTime: "",
        type: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
      this.orderCount();
    },

    // 区域详情
    handleDetails(row) {
      this.$router.push({
        path: "/order/manage/detail",
        query: {
          id: row.id,
        },
      });
    },

    // 导出
    handleExport() {
      const searchData = {
        id: this.id,
        type: this.searchData.type,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/car/manage/detail/duration/export?${qs.stringify(
          searchData
        )}`,
        "_self"
      );
    },
  },
};
</script>

<style scoped>
.order-box {
  display: flex;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  background: #f6f6f6;
  padding: 20px;
  margin-bottom: 20px;
}
.order-box-list {
  width: 33%;
}
.order-box-title {
  font-size: 45px;
  font-weight: 400;
  height: 70px;
}
.order-box-info {
  color: #999;
}
</style>