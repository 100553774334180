import { get, post, put, Delete } from "@/api/axios";

// 获取弹窗列表
export const list = params => get('/web/home/frame/list', params);

// 添加弹窗列表
export const add = params => post('/web/home/frame/add', params);

// 修改弹窗列表
export const update = params => put('/web/home/frame/update', params);

// 删除弹窗列表
export const deleteNew = params => Delete('/web/home/frame/delete/' + params.id, '');

// 查看弹窗列表
export const view = params => get('/web/home/frame/view/' + params.id, '');

// 修改显示状态
export const updateNew = params => put('/web/home/frame/state/update', params);

// 景区列表
export const regionList = () => get('/web/home/frame/region/list')

