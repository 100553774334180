<template>
  <div class="main-content">
    <h3 class="marginBottom20">{{ type === 'create' ? '新增' : '编辑' }}</h3>
    <avue-form
      ref="form"
      v-model="form"
      v-loading="loading"
      :option="formOption"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
      :upload-before="uploadBefore"
      @submit="submit"
    >

      <template slot="linkType">
        <el-button
          v-if="!form.linkType"
          type="primary"
          @click="handleSelect"
        >选择链接</el-button>
        <div v-else>
          <el-button type="text">{{ form.linkType === 1 ? '小程序页面' : '景区详情' }}: {{ selectData.name }}</el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            circle
            @click="handleSelectEmpty"
          ></el-button>
        </div>
      </template>

      <template slot="linkUrl">
        <div class="flex align-items justify-content-space-between ">
          <el-input
            class="marginRight20"
            style="width:40%"
            placeholder="请输入链接地址"
            v-model="form.linkUrl"
            maxlength="100"
            clearable
          />

          <div class="marginRight10">
            APPID:
          </div>

          <el-input
            style="width:40%"
            placeholder="请输入APPID"
            v-model="form.appid"
            maxlength="50"
            clearable
          />
        </div>

      </template>

      <template slot="sort">
        <el-input
          v-model="form.sort"
          placeholder="请输入优先级"
          onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
          @blur="salaryChange($event,'sort',999999)"
          type="text"
          clearable
        />
      </template>

      <!-- <template slot="dayShowNum">
        <el-input
          v-model="form.dayShowNum"
          placeholder="请输入每日展示次数"
          onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
          @blur="salaryChange($event,'dayShowNum',999999)"
          type="text"
          clearable
        />
      </template> -->

      <template slot="menuForm">
        <el-button
          icon="el-icon-close"
          @click="handleClose"
        >取消</el-button>
      </template>
    </avue-form>

    <SelectLink
      :show="show"
      :defaultData="selectData"
      @change="handleSelectData"
      @close="handleSelectClose"
    />
  </div>
</template>

<script>
import { add, update, view } from "@/api/home/frame";
import SelectLink from "./components/selectLink";
import dayjs from "dayjs";
export default {
  name: "create",
  components: {
    SelectLink,
  },
  data() {
    return {
      type: this.$route.query.type,
      loading: false,
      form: {
        state: 1,
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "弹窗标题",
            prop: "title",
            maxlength: 35,
            showWordLimit: true,
            span: 12,
            row: true,
            placeholder: "请输入弹窗标题",
            rules: [
              {
                required: true,
                message: "请输入弹窗标题",
                trigger: "blur",
              },
            ],
          },
          {
            label: "图片链接",
            prop: "imageLink",
            type: "radio",
            value: 1,
            dicData: [
              { label: "系统链接", value: 1 },
              { label: "自定义链接", value: 2 },
            ],
            span: 12,
            row: true,
            rules: [
              {
                required: true,
                // message: "请选择跳转类型",
                // trigger: "blur",
              },
            ],
            change: (e) => {
              const linkType = this.findObject(
                this.formOption.column,
                "linkType"
              );
              // const appId = this.findObject(this.formOption.column, "appId");
              const linkUrl = this.findObject(
                this.formOption.column,
                "linkUrl"
              );
              if (e.value === 1) {
                linkType.display = true;
                // appId.display = false;
                linkUrl.display = false;
              } else {
                linkType.display = false;
                // appId.display = true;
                linkUrl.display = true;
              }
            },
          },
          {
            label: "选择链接",
            prop: "linkType",
            span: 12,
            row: true,
            showWordLimit: true,
           rules: [
              {
                required: true,
                message: "请选择链接",
                trigger: "blur",
              },
            ],
            display: true,
          },
          {
            label: "链接地址",
            prop: "linkUrl",
            maxlength: 100,
            span: 12,
            row: true,
            showWordLimit: true,
            placeholder: "请输入链接地址",
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入链接地址",
            //     trigger: "blur",
            //   },
            // ],
            display: false,
          },
          // {
          //   label: "APPID",
          //   prop: "appId",
          //   maxlength: 50,
          //   span: 6,
          //   row: true,
          //   placeholder: "请输入",
          //   display: false,
          // },
          {
            label: "优先级",
            prop: "sort",
            type: "number",
            // value: 1,
            maxRows: 999999,
            minRows: 1,
            controls: false,
            span: 12,
            row: true,
            placeholder: "请输入优先级",
            rules: [
              {
                required: true,
                message: "请输入优先级",
                trigger: "blur",
              },
            ],
          },

          {
            label: "到期时间",
            prop: "endTime",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            controls: false,
            span: 12,
            row: true,
            placeholder: "请选择到期时间",
            pickerOptions: {
              disabledDate: (time) => {
                return time.getTime() < Date.now() - 8.64e7;
              },
            },
            rules: [
              {
                required: true,
                message: "请选择到期时间",
                trigger: "blur",
              },
            ],
          },

          // {
          //   label: "每日展示次数",
          //   prop: "dayShowNum",
          //   type: "number",
          //   // value: 1,
          //   maxRows: 999999,
          //   minRows: 1,
          //   controls: false,
          //   span: 12,
          //   row: true,
          //   placeholder: "请输入每日展示次数",
          //   rules: [
          //     {
          //       required: true,
          //       message: "请输入每日展示次数",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          {
            label: "弹窗图片",
            prop: "image",
            type: "upload",
            listType: "picture-img",
            accept: "image/jpeg,image/png",
            propsHttp: {
              res: "data",
              url: "path",
              name: "",
            },
            headers: {
              Authorization: this.$store.state.user.Token,
            },
            action: this.actionUrl,
            tip: "建议尺寸：650*420,最大20M",
            fileSize: 20 * 1024,
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传图片",
                trigger: "blur",
              },
            ],
            change: (e) => {
              if (e.value !== "") {
                this.$refs.form.clearValidate(`image`);
              }
            },
          },
        ],
      },

      show: false,
      selectData: {
        id: "",
      },
    };
  },
  mounted() {
    this.$route.query.id && this.getView();
  },
  methods: {

    validate(){
        //如果存在验证不通过，msg为错误信息
        this.$refs.form.validate((valid, done,msg) => {
          if (valid) {
            done()
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      },

    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    handleSelect() {
      this.show = true;
    },
    handleSelectData(e) {
      // // console.log(e)
      this.selectData = e;
      this.form.typeId = e.id;
      this.form.linkType = e.linkType;
      this.validate()
      this.show = false
    },
    handleSelectClose() {
      this.show = false;
    },
    handleSelectEmpty() {
      this.selectData = {};
      this.form.linkType = "";
      this.form.typeId = 1;
      this.validate()
    },
    getView() {
      this.loading = true;
      view({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.form = res.data;
          this.selectData = {
            id: res.data.linkType,
            name: res.data.typeName,
          };
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    submit(form, done) {
      // // console.log(form)
      const data = {
        ...form,
        endTime: dayjs(form.endTime).format("YYYY-MM-DD 23:59:59"),
      };
      if (form.id) {
        update(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      } else {
        add(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      }
    },
    handleClose() {
      this.$router.go(-1);
    },
    uploadDelete(file, column) {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadAfter(res, done, loading, column) {
      done();
      // // console.log(res)
      // // console.log(column)
      if (!res.path) {
        this.$message.error("上传失败");
        loading();
      } else {
        column.propsHttp.name = res.path;
      }
    },
  },
};
</script>

<style scoped>
</style>