const system = [
    {
        path: '/system/role',
        name: 'role',
        component: () => import('@/view/system/role/index')
    },
    {
        path: '/system/user',
        name: 'user',
        component: () => import('@/view/system/user/index')
    }
]
export default system