<template>
  <div class="tmap">
    <h3 class="marginBottom10">区域电子围栏</h3>
    <div>
      <!-- <el-button
        :disabled="loading"
        :loading="loading"
        type="primary"
        @click="add('highlight')"
      >添加区域</el-button> -->
      <!-- <el-button
        :disabled="loading"
        :loading="loading"
        type="primary"
        @click="add('highlightRed')"
      >添加停车区</el-button> -->
      <!-- <el-button
        :disabled="loading"
        :loading="loading"
        type="warning"
        @click="edit"
      >编辑</el-button> -->
      <!-- <el-button
        :disabled="loading"
        :loading="loading"
        type="danger"
        @click="del"
      >删除</el-button> -->
    </div>
    <div
      class="map marginTop20"
      id="darkMap"
      ref="tmap"
      style="width:100%;height:600px"
    ></div>
  </div>
</template>

<script>
import internal from "stream";

let editor,
  TMap,
  map,
  MultiPolygon,
  tMapDom,
  id = 0;
export default {
  name: "TMap",
  props: {
    geometries: {
      type: Array,
    },
    situation:{
      type:Array,
      default:()=> []
    }
  },
  data() {
    return {
      map: {},
      center: {},
      TMap: {},
      marker: {},
      //初始化化数据
      // paths: [
      //   [
      //     { lat: 28.159730931106623, lng: 112.99104925198526 },
      //     { lat: 28.155023400813775, lng: 112.99139234751101 },
      //     { lat: 28.155817456440566, lng: 112.999133443544 },
      //     { lat: 28.160222468268145, lng: 112.99836147811993 },
      //   ],
      // ],
      paths: [],
      isRegion: "highlight",
      loading: false,
      styleIdIndex: "",
    };
  },
  mounted() {
    console.log("geometriesfffwww", this.geometries);
    this.geometries?.forEach((item, index) => {
      if (item.styleId === "highlight") {
        this.styleIdIndex = index;
      }
    });
    const paths = this.geometries?.map((item) => item.paths);
    this.paths = paths || [];
    this.loading = true;
    this.show();
  },
  methods: {
    onGeometriesHandle(geometries) {
      this.$emit("onGeometriesHandle", geometries);
    },
    // 显示地图
    show() {
      tMapDom = document.getElementById("darkMap");
      this.$nextTick(() => {
        this.loadScript(() => {
          this.initTMap();
        });
      }, 1000);
    },

    initTMap() {
      // this.$refs.tmap
      //   获取地图ContainerDom
      //   把挂载在window上面的Tmap对象放到组件内部
      this.TMap = window.TMap;

      TMap = this.TMap;

      //   初始化地图中心
      let defaultcenter = new this.TMap.LatLng(
        this.situation[0],
        this.situation[1]
      );
      //   创建地图对象
      this.map = new this.TMap.Map(tMapDom, {
        center: defaultcenter, //设置地图中心点坐标
        zoom: 16, //设置地图缩放级别
        pitch: 43.5, //设置俯仰角
        rotation: 45, //设置地图旋转角度
      });

      map = this.map;

      //初始化几何图形及编辑器
      this.initGeometry();
      // this.createMarker(defaultcenter);
      //   创建信息窗口
      this.info = new this.TMap.InfoWindow({
        map: this.map,
        position: this.map.getCenter(),
        offset: { x: -3, y: -35 }, //向上偏移35像素坐标，向左偏移3像素坐标
      }).close();

      //   监听地图点击事件
      // this.mapClick();
    },

    mapClick() {
      this.map.on("click", (event) => {
        // var lat = evt.latLng.getLat();
        // var lng = evt.latLng.getLng();
        window.console.log(event);
        // 设置当前地图中心点
        this.setMapCenter(event.latLng);
        // 如果存在marker伤处marker
        if (this.marker) {
          this.marker.setMap(null);
          this.marker = null;
        }
        // 重新创建marker
        // this.createMarker(event.latLng);
        let poi = event.poi;
        if (poi) {
          // 拾取到POI
          //   设置信息窗口
          this.info.setContent(poi.name).setPosition(poi.latLng).open();
        } else {
          // 没有拾取到POI
          this.info.close();
        }
      });
    },

    //删除
    del() {
      const isHighlight = MultiPolygon?.geometries?.find(
        (item) => item.styleId === "highlight"
      );

      const geometriesOld = this.geometries?.filter(
        (item) => item.styleId === "highlightRed"
      );
      const geometriesNew = MultiPolygon?.geometries.filter(
        (item) => item.styleId === "highlightRed"
      );

      if (geometriesNew.length > 0) {
        this.$message.warning("当前区域包含停车区围栏，请先去删除停车区围栏");
        return;
      }
      if (isHighlight || geometriesOld.length > geometriesNew.length) {
        this.$message.warning("当前页面不能删除停车区围栏");
        return;
      }
      editor.delete();
    },

    //添加
    add(isRegion) {
      this.isRegion = isRegion;
      const isHighlight = MultiPolygon?.geometries?.filter(
        (item) => item.styleId === "highlight"
      );
      if (isRegion === "highlight" && isHighlight.length > 0) {
        this.$message.warning("区域电子围栏最多只能画一个");
        return;
      }

      editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW);
    },
    //编辑
    edit() {
      //设置为编辑模式
      editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT);
      console.log(this.paths, MultiPolygon.geometries);
    },

    //格式化返回的经纬度
    pathElems(geometry) {
      var lngLat = [];
      for (const item of geometry.paths) {
        const lng = item.getLng();
        const lat = item.getLat();

        lngLat.push({
          lat: lat,
          lng: lng,
        });
      }
      return lngLat;
    },

    dataList(pathArr) {
      console.log("pathArrpathArrpathArr", pathArr);
      // var list = editor.getOverlayList();
      // console.log("list", list);

      var pathList = [];

      if (pathArr) {
        pathArr.forEach((item, index) => {
          var simplePath = [];
          item?.forEach((items) => {
            simplePath.push(new TMap.LatLng(items.lat, items.lng));
          });
          if (id > index) {
            id = id - 1;
          } else {
            id = id + 1;
          }
          var path = {
            id: id,
            paths: simplePath,
            styleId: this.isRegion,
          };
          if (this.styleIdIndex !== "" && index !== this.styleIdIndex) {
            path.styleId = "highlightRed";
          }
          pathList.push(path);
        });
      }
      return pathList;
    },

    //几和图层
    MultiPolygon(simplePath) {
      MultiPolygon = new TMap.MultiPolygon({
        map,
        styles: {
          //默认样式
          highlight: new TMap.PolygonStyle({
            color: "rgba(3,170,252, 0.3)",
            showBorder: true, //Boolean 是否显示边线，默认为false
            borderColor: "rgba(3,170,252, 0.8)", //string  边线颜色，支持rgb()，rgba()，#RRGGBB等形式，默认为#3777FF，showBorder为true时有效
            //borderWidth: //Number 边线宽度，正整数，单位为像素，指的是地图pitch为0时的屏幕像素大小，如果pitch不为0，实际绘制出来的线宽度与屏幕像素会存在一定误差，默认为2，showBorder为true时有效
            //borderDashArray: //Number[] 边线虚线虚线展示方式，[0, 0]为实线，[10, 10]表示十个像素的实线和十个像素的空白（如此反复）组成的虚线，默认为[0, 0];这里的像素指的是地图pitch为0时的屏幕像素大小，如果pitch不为0，实际绘制出来的线宽度与屏幕像素会存在一定误差。
          }),

          //选中样式
          highlights: new TMap.PolygonStyle({
            color: "rgba(247,229,5, 0.5)",
            showBorder: true, //Boolean 是否显示边线，默认为false
            borderColor: "rgba(247,229,5, 1)", //string  边线颜色，支持rgb()，rgba()，#RRGGBB等形式，默认为#3777FF，showBorder为true时有效
            //borderWidth: //Number 边线宽度，正整数，单位为像素，指的是地图pitch为0时的屏幕像素大小，如果pitch不为0，实际绘制出来的线宽度与屏幕像素会存在一定误差，默认为2，showBorder为true时有效
            //borderDashArray: //Number[] 边线虚线虚线展示方式，[0, 0]为实线，[10, 10]表示十个像素的实线和十个像素的空白（如此反复）组成的虚线，默认为[0, 0];这里的像素指的是地图pitch为0时的屏幕像素大小，如果pitch不为0，实际绘制出来的线宽度与屏幕像素会存在一定误差。
          }),

          //默认样式
          highlightRed: new TMap.PolygonStyle({
            color: "rgba(202, 67, 58, 0.5)",
            showBorder: true, //Boolean 是否显示边线，默认为false
            borderColor: "rgba(202, 67, 58, 1)", //string  边线颜色，支持rgb()，rgba()，#RRGGBB等形式，默认为#3777FF，showBorder为true时有效
            //borderWidth: //Number 边线宽度，正整数，单位为像素，指的是地图pitch为0时的屏幕像素大小，如果pitch不为0，实际绘制出来的线宽度与屏幕像素会存在一定误差，默认为2，showBorder为true时有效
            //borderDashArray: //Number[] 边线虚线虚线展示方式，[0, 0]为实线，[10, 10]表示十个像素的实线和十个像素的空白（如此反复）组成的虚线，默认为[0, 0];这里的像素指的是地图pitch为0时的屏幕像素大小，如果pitch不为0，实际绘制出来的线宽度与屏幕像素会存在一定误差。
          }),
        },
        geometries: simplePath,
      });

      return MultiPolygon;
    },

    //初始化几何图形及编辑器
    initGeometry() {
      //初始化数据
      var pathArr = this.paths;

      //多边形范围
      var simplePath = this.dataList(pathArr);

      //几和图层
      var overlay = this.MultiPolygon(simplePath);

      //初始化几何图形及编辑器
      editor = new TMap.tools.GeometryEditor({
        map, // 编辑器绑定的地图对象
        overlayList: [
          // 可编辑图层
          {
            overlay: overlay,
            id: "polygon",
            drawingStyleId: "highlight", //默认样式key
            selectedStyleId: "highlights", //选中样式key
          },
        ],
        actionMode: TMap.tools.constants.EDITOR_ACTION.INTERACT, // 编辑器的工作模式
        activeOverlayId: "polygon", // 激活图层
        selectable: true, // 开启点选功能
        snappable: true, // 开启吸附
      });

      this.evtResult();
      this.loading = false;
    },

    //监听事件
    evtResult() {
      // 监听绘制结束事件，获取绘制几何图形
      editor.on("draw_complete", (geometry) => {
        var lngLat = [this.pathElems(geometry)];
        if (this.paths?.length > 0) {
          this.paths = this.paths.concat(lngLat);
        } else {
          this.paths = lngLat;
        }

        // this.dataList(this.paths);
        //删除新加的多边形
        MultiPolygon.remove(geometry.id);

        //修改id后重新渲染
        MultiPolygon.add(this.dataList(lngLat));

        //设置为编辑模式
        editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT);
        this.onGeometriesHandle(MultiPolygon.geometries);
      });

      // 监听删除、修改、拆分、合并完成事件
      let evtList = ["delete", "adjust", "split", "union"];
      evtList.forEach((evtName) => {
        editor.on(evtName + "_complete", (evtResult) => {
          if (evtName === "delete") {
            if (evtResult[0].styleId === "highlightRed") {
              return;
            }
            this.paths = MultiPolygon.geometries.map((item) => item.paths);
            this.onGeometriesHandle(MultiPolygon.geometries);
          }
        });
      });

      // 监听拆分失败事件，获取拆分失败原因
      editor.on("split_fail", (res) => {
        alert(res.message);
      });
      // 监听合并失败事件，获取合并失败原因
      editor.on("union_fail", (res) => {
        alert(res.message);
      });
    },

    // 创建信息窗口

    // 创建marker
    createMarker(defaultcenter) {
      this.marker = new this.TMap.MultiMarker({
        id: "marker-layer", //图层id
        map: this.map,
        styles: {
          //点标注的相关样式
          marker: new this.TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png",
          }),
        },
        geometries: [
          {
            //点标注数据数组
            id: "demo",
            styleId: "marker",
            position: defaultcenter,
            properties: {
              title: "marker",
            },
          },
        ],
      });
    },
    // 2d视角
    change2D() {
      this.map.setViewMode("2D");
    },
    // 3D视角
    change3D() {
      this.map.setViewMode("3D");
      //设置斜角
      this.map.setPitch(70);
    },
    // 获取中心
    // 也就是初始化的位置
    getMapCenter() {
      window.console.log(this.map.getCenter());
      this.setMapCenter(this.map.getCenter());
    },
    // 设置中心位置
    setMapCenter(center) {
      window.console.log(center);

      // 平滑移动到某个位置
      this.map.easeTo(
        {
          center: new this.TMap.LatLng(center.getLat(), center.getLng()),
          zoom: 17,
          rotation: 90,
        },
        { duration: 2000 }
      );
    },
    // 加载javascript GL SDK
    loadScript(callback) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://map.qq.com/api/gljs?v=1.exp&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77&callback=init&libraries=tools`;

      document.body.appendChild(script);
      if (script.readyState) {
        //IE
        //这里只有反人类设计的IE才有
        script.onreadystatechange = function () {
          if (
            script.readyState == "complete" ||
            script.readyState == "loaded"
          ) {
            script.onreadystatechange = null;
            //callback&&callback()是判断传入的回调函数是不是空的如果是空的就不执行，如果不是空的就执行
            callback && callback();
          }
        };
      } else {
        //非IE
        script.onload = function () {
          callback && callback();
        };
      }
    },
  },
  destroyed() {
    this.map.destroy();
  },
};
</script>

<style>
</style>