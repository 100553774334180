import { get, post, put, Delete } from "@/api/axios";

// 列表
export const list = params => get('/web/user/manage/list', params);

//选择会员等级列表
export const getLevelList = params => get('/web/user/manage/level', params)

// 冻结区域列表 
export const stateUpdateRegion = params => put('/web/user/manage/state/update', params);

// 查看详情
export const view = params => get('/web/user/manage/view/info/' + params.id, '');

// 亲昵度调整
export const updateIntimacy = params => put('/web/user/manage/view/intimacy/update', params);

// 积分调整
export const updateAvailableIntegral = params => put('/web/user/manage/view/integral/update', params);

//选择会员等级列表
export const getOrderList = params => get('/web/user/manage/view/order/list', params)

//选择会员等级列表
export const getCuponList = params => get('/web/user/manage/view/coupon/list', params)

//余额充值记录
export const getBalanceList = params => get('/web/user/manage/view/balance/list', params)

// 积分明细
export const getIntegralList = params => get('/web/user/manage/view/integral/list', params)
