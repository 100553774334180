<template>
  <div>
    <div>
      <div class="flex align-items marginBottom10 flex-wrap">
        <div class="flex align-items marginRight10 marginBottom10">
          <span class="marginRight10">区域编号:</span>
          <div class="width200">
            <el-input
              v-model="searchData.code"
              placeholder="请输入"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="20"
            ></el-input>
          </div>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <span class="marginRight10">区域名称:</span>
          <div class="width200">
            <el-input
              v-model="searchData.name"
              placeholder="请输入"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="20"
            ></el-input>
          </div>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <span class="marginRight10">区域状态:</span>
          <div class="width200">
            <el-select
              class="width200"
              v-model="searchData.state"
              clearable
              @change="handleSearch"
            >
              <el-option
                value=""
                label="请选择"
              ></el-option>
              <el-option
                :value="1"
                label="正常"
              ></el-option>
              <el-option
                :value="2"
                label="冻结"
              ></el-option>
            </el-select>
          </div>
        </div>

        <div class="marginRight10 marginBottom10">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            class="marginLeft10"
          >查询</el-button>
        </div>

        <div class="marginRight10 marginBottom10">
          <el-button
            type="info"
            icon="el-icon-refresh-right"
            @click="handleReset"
            class="marginLeft10"
          >重置</el-button>
        </div>
      </div>
    </div>

    <avue-crud
      ref="ongoingTask"
      class="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
      @selection-change="selectionChange"
    >
    </avue-crud>

    <div style="text-align:right;margin-top:20px">
      <el-button
        type="primary"
        @click="selectClassify"
      >确定</el-button>
    </div>
  </div>
</template>

<script>
import { addRegionList } from "@/api/finance/index";
export default {
  name: "goods",
  props: {
    defaultData: {
      type: Array,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectList: [],
      searchData: {
        name: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: "mini",
        selection: true, // 显示多选框
        reserveSelection: true,
        rowKey: "id",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "区域编号",
            prop: "code",
          },
          {
            label: "区域名称",
            prop: "name",
          },
          {
            label: "区域状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state == 1 ? "正常" : row.state == 2 ? "冻结" : "";
            },
          },
        ],
      },
    };
  },
  watch: {
    show(value) {
      this.onLoad();
    },
  },
  methods: {
    toggleSelection(val) {
      this.$refs.ongoingTask.toggleSelection(val);
    },
    onLoad() {
      this.showLoading = true;
      addRegionList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
          this.toggleSelection();
          if (this.show) {
            this.selectList = this.defaultData;
            // this.$nextTick(() => {
            this.toggleSelection(this.selectList);
            // });
          }
        }
      });
    },
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    handleReset() {
      this.searchData = {};
      this.page.currentPage = 1;
      this.onLoad();
    },
    selectClassify() {
      this.$emit("change", this.selectList);
    },
    selectionChange(list) {
      this.selectList = list;
      this.$emit("newchange", this.selectList);
    },
  },
};
</script>

<style scoped>
/* .crud /deep/.el-card__body .el-form .el-table .el-table__fixed-right {
  height: 100% !important;
} */
</style>
