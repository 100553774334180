<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex flex-wrap align-items marginBottom20">
          <div class="flex align-items marginRight10 marginBottom10">
            <div class="width150">
              <el-input
                v-model="searchData.name"
                placeholder="输入姓名/手机号"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <div class="width150">
              <el-input
                v-model="searchData.regionName"
                placeholder="输入区域名称"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <div class="width200">
              <el-input
                v-model="searchData.carName"
                placeholder="输入车型名称/车辆编号"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <div class="width300">
              <el-date-picker
                v-model="searchData.createTime"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleSearch"
              >
              </el-date-picker>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              type="primary"
              size="medium"
              icon="el-icon-download"
              class="marginLeft10"
              @click="handleExport"
            >导出</el-button>
          </div>
        </div>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { openList, openExport } from "@/api/scan/index";
import qs from "qs";
import dayjs from "dayjs";
export default {
  name: "index",
  data() {
    return {
      disabled: false,
      searchData: {
        carName: "",
        name: "",
        regionName: "",
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "开锁记录",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        menu: false,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "姓名",
            prop: "whiteUsername",
          },
          {
            label: "手机号",
            prop: "whitePhone",
          },
          {
            label: "职位",
            prop: "position",
          },
          {
            label: "区域名称",
            prop: "regionName",
          },
          {
            label: "车型名称",
            prop: "carName",
          },
          {
            label: "车辆编号",
            prop: "carCode",
          },
          {
            label: "开锁时间",
            prop: "openTime",
          },
          {
            label: "使用时长（分钟）",
            prop: "timeNum",
          },
          {
            label: "使用说明",
            prop: "useDesc",
          },
          {
            label: "用车类型",
            prop: "openType",
            formatter: function (row, column, cellValue) {
              //1引流/2接待/3换车/4售票/5调度/0其它
              return row.openType === 1
                ? "引流"
                : row.openType === 2
                ? "接待"
                : row.openType === 3
                ? "换车"
                : row.openType === 4
                ? "售票"
                : row.openType === 5
                ? "调度"
                : "其它";
            },
          }
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
      if (
        searchForm.searchData?.createTimeStart &&
        searchForm.searchData?.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData?.createTimeStart,
          searchForm.searchData?.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    // 导出
    handleExport() {
      const searchData = {
        carName: this.searchData.carName,
        regionName: this.searchData.regionName,
        name: this.searchData.name,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/scan/open/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        carName: "",
        name: "",
        regionName: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    onLoad() {
      this.showLoading = true;
      const searchData = {
        carName: this.searchData.carName,
        regionName: this.searchData.regionName,
        name: this.searchData.name,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      openList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
  },
};
</script>
