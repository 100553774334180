<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">优惠券编号: </span>
              <div class="width250">
                <el-input
                  v-model="searchData.code"
                  placeholder="请输入"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">优惠券名称: </span>
              <div class="width250">
                <el-input
                  v-model="searchData.name"
                  placeholder="请输入"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">优惠券类型: </span>
              <div class="width200">
                <el-select
                  class="width200"
                  v-model="searchData.type"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="请选择"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="代金"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="满减"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">状态: </span>
              <div class="width200">
                <el-select
                  class="width200"
                  v-model="searchData.state"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="请选择"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="未生效"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="生效中"
                  ></el-option>
                  <el-option
                    :value="3"
                    label="已过期"
                  ></el-option>
                  <el-option
                    :value="4"
                    label="已作废"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
              >查询</el-button>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
              >重置</el-button>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-plus"
                @click="handleCreate"
              >添加优惠券</el-button>
            </div>
          </div>

        </div>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-view"
          @click="handleDetails(row)"
        >详情</el-button>
        <el-button
          v-if="row.state !== 4"
          style="color: #D9001B"
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
        >作废</el-button>
        <el-button
          v-if="row.state !== 4"
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
        >编辑</el-button>
      </template>

    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      title="扣除押金"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >
        <template slot="parkFeeDeduct">
          <el-input
            v-model="form.parkFeeDeduct"
            placeholder="请输入停车费"
            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            @blur="salaryDianChange($event,'parkFeeDeduct',999999.99)"
            type="text"
            clearable
          />
        </template>

        <template slot="otherDeduct">
          <el-input
            v-model="form.otherDeduct"
            placeholder="请输入其他扣除"
            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            @blur="salaryDianChange($event,'otherDeduct',999999.99)"
            type="text"
            clearable
          />
        </template>
        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleClose"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { list, deleteNew } from "@/api/coupon/manage";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        name: "",
        state: "",
        type: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "优惠劵列表",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "优惠劵编号",
            prop: "code",
          },
          {
            label: "优惠劵名称",
            prop: "name",
          },
          {
            label: "优惠劵类型",
            prop: "type",
            formatter: function (row, column, cellValue) {
              return row.type  === 1
                ? "满减"
                : row.type  === 2
                ? "代金"
                : row.type;
            },
          },
          {
            label: "使用条件",
            prop: "useConditionFullPrice",
            formatter: function (row, column, cellValue) {
              return row.type == 1
                ? row.useConditionFullPrice == 0
                  ? "无条件"
                  : `满￥ ${cellValue}可用`
                : '无门槛';
                // row.takePrice
            },
          },
          {
            label: "使用有效期",
            prop: "validityStartTime",
            formatter: function (row, column, cellValue) {
              console.log("rowffff", row);
              return row.overdueType == 1
                ? `${row.validityStartTime}~${row.validityEndTime}`
                : `领取后${row.comeNum}天生效，生效后${row.validityNum}天失效`;
            },
          },
          {
            label: "已领取（张）",
            prop: "drawNum",
          },
          {
            label: "库存剩余(张)",
            prop: "stockNum",
          },
          {
            label: "状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "未生效"
                : row.state === 2
                ? "生效中"
                : row.state === 3
                ? "已过期"
                : row.state === 4
                ? "已作废"
                : row.state;
            },
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "140",
        column: [
          {
            label: "停车费扣除（元）",
            prop: "parkFeeDeduct",
            type: "number",
            maxRows: 999999.99,
            // minRows: 0,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入停车费",
                trigger: "blur",
              },
            ],
          },
          {
            label: "其他扣除（元）",
            prop: "otherDeduct",
            type: "number",
            // value: 1,
            maxRows: 999999.99,
            minRows: 0,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入其他扣除",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));
      this.showLoading = true;

      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        type: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 添加优惠劵
    handleCreate() {
      this.$router.push({
        path: "/coupon/manage/create",
        query: {
          type: "create",
        },
      });
    },
    // 优惠劵详情
    handleDetails(row) {
      // // console.log(row)
      this.$router.push({
        path: "/coupon/manage/details",
        query: {
          id: row.id,
        },
      });
    },
    // 编辑优惠劵
    handleEdit(row) {
      // // console.log(row)
      this.$router.push({
        path: "/coupon/manage/create",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定作废吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },

    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      };
    },

    // 导出
    handleExport() {
      window.open(
        `/api/web/deposit/apply/export?${qs.stringify(this.searchData)}`,
        "_self"
      );
    },

    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
  },
};
</script>

<style scoped>
</style>