import { get, post, put, Delete } from "@/api/axios";


// 全局消息弹框
export const allAlert = (params) => get("/web/message/all/alert", params);

// 全局消息红点
export const allNum = (params) => get("/web/message/all/num", params);

// 消息中心-显示
export const manageList = (params) => get("/web/message/manage/list", params);

// 消息中心-显示-分页详情
export const detailList = (params) => get("/web/message/manage/detail/list", params);

// 消息中心-显示-已读
export const manageRead = (params) => get("/web/message/manage/read/"+ params.id, '');

// 消息中心-显示-一键已读
export const readAll = (params) => get("/web/message/manage/read/all", params);

// 消息中心-售后状态判断
export const saleState = (params) => get("/web/message/manage/sale/state/"+ params.id, '');

