<template>
  <div>
    <h3>基础信息</h3>
    <avue-form
      ref="form"
      v-model="form"
      :option="formOption"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
      :upload-before="uploadBefore"
    >
      <template slot="name">
        {{ form.name }}
      </template>

      <template slot="sort">
        {{ form.sort }}
      </template>

      <template slot="regionIntro">
        {{ form.regionIntro }}
      </template>

      <template slot="state">
        {{ form.state === 1 ? '正常' : form.state === 2 ? '冻结' : '' }}
      </template>

      <template slot="cityPartner">
        {{ form.cityPartner }}
      </template>

      <template slot="partnerPhone">
        {{ form.partnerPhone }}
      </template>

      <template slot="headImage">
        <div class="flex">
          <div class="ElImage">
            <el-image class="ElImageItem" :src="form.headImage" fit="contain">
            </el-image>
          </div>
        </div>
        <div class="span">建议尺寸：800*800,最大20M</div>
      </template>

      <template slot="banner">
        <div class="flex">
          <div
            class="ElImage"
            v-for="(url, index) in form.banner"
            :key="`ElImage${index}`"
          >
            <el-image
              class="ElImageItem"
              :src="url"
              :key="`ElImage${index}`"
              fit="contain"
            >
            </el-image>
          </div>
        </div>
        <div class="span">建议尺寸：800*800,最大20M，最多上传9张</div>
      </template>

      <template slot="code">
        {{ form.id ? form.code : '自动生成' }}
      </template>

      <template slot="scenicScale">
        <el-table :data="form.userTableData" style="width: 100%">
          <el-table-column label="分账对象">
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
              {{ scope.row.userType === 0 ? '员工' : '公司' }}
            </template>
          </el-table-column>
          <el-table-column label="公司/个人">
            <template slot-scope="scope">
              {{ scope.row.financeRegionUserName }}
            </template>
          </el-table-column>
          <el-table-column label="商户号/手机号">
            <template slot-scope="scope">
              {{ scope.row.accountOrPhone }}
            </template>
          </el-table-column>
          <el-table-column label="分账比例">
            <template slot-scope="scope">
              {{ scope.row.proportion }} %
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="flex travel">
          <div class="travel-list">
            <div class="travel-list-title">平台比例</div>
            <div>
              {{ form.platformScale }}
            </div>
          </div>
          <div class="travel-list">
            <div class="travel-list-title">居间费比例</div>
            <div>
              {{ form.betweenScale }}
            </div>
          </div>
          <div class="travel-list">
            <div class="travel-list-title">运营比例</div>
            <div>
              {{ form.runScale }}
            </div>
          </div>
          <div class="travel-list">
            <div class="travel-list-title">景区比例</div>
            <div>
              {{ form.scenicScale }}
            </div>
          </div>
          <div class="travel-list">
            <div class="travel-list-title">合计</div>
            <div>
              {{ form.totalNum }}
            </div>
          </div>
        </div> -->
      </template>

      <template slot="servicePhones">
        <div>
          <el-table :data="form.servicePhones" style="width: 100%">
            <el-table-column label="客服电话">
              <template slot-scope="{ row }">
                <!-- slot-scope="{ row }" -->
                <!-- {{row  }} -->
                <div v-for="(item, index) in row.split(',')" :key="index">
                  {{ item }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>

      <template slot="cooperWay">
        {{ form.cooperWay }}
      </template>

      <template slot="regionDesc">
        <div v-html="form.regionDesc"></div>
      </template>
    </avue-form>

    <el-image-viewer
      v-if="imgViewerVisible"
      :on-close="closeImgViewer"
      :initialIndex="imgViewerIndex"
      :url-list="form.banner"
    />

    <el-dialog
      :title="serviceTelState ? '编辑' : '新增'"
      :visible.sync="dialogTableVisibleServiceTel"
    >
      <avue-form
        @submit="serviceTelsubmit"
        v-model="serviceTelForm"
        :option="serviceTelFormOption"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-after="uploadAfter"
        :upload-before="uploadBefore"
      >
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import UploadImage from './UploadImage'
import draggable from 'vuedraggable'

export default {
  name: 'createOne',
  components: {
    draggable,
    UploadImage,
    'el-image-viewer': () =>
      import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {
      drag: false, // 拖拽
      imgViewerVisible: false, // 图片预览
      imgViewerIndex: 0, // 图片预览索引
      form: {
        sort: 1,
        banner: [],
        servicePhones: [],
        totalNum: '',
        platformScale: '',
        betweenScale: '',
        runScale: '',
        scenicScale: '',
        userTableData: []
      },
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 220,
        column: [
          {
            label: '区域编码',
            prop: 'code',
            maxlength: 60,
            showWordLimit: true,
            span: 20,
            row: true
          },
          {
            label: '区域名称',
            prop: 'name',
            maxlength: 20,
            showWordLimit: true,
            span: 20,
            row: true
          },
          {
            label: '排序值',
            prop: 'sort',
            maxlength: 60,
            showWordLimit: true,
            span: 20,
            row: true
          },
          {
            label: '区域简介',
            prop: 'regionIntro',
            type: 'textarea',
            maxlength: 50,
            showWordLimit: true,
            span: 20,
            row: true
          },
          {
            label: '区域状态',
            prop: 'state',
            type: 'radio',
            span: 20,
            row: true,
            dicData: [
              {
                value: 1,
                label: '正常'
              },
              {
                value: 2,
                label: '冻结'
              }
            ]
          },
          {
            label: '城市合伙人',
            prop: 'cityPartner',
            maxlength: 20,
            showWordLimit: true,
            span: 20,
            row: true
          },
          {
            label: '合伙人联系方式',
            prop: 'partnerPhone',
            maxlength: 20,
            showWordLimit: true,
            span: 20,
            row: true
          },
          {
            label: '合作方式',
            prop: 'cooperWay',
            maxlength: 20,
            showWordLimit: true,
            span: 20,
            row: true
          },
          {
            label: '分账比例',
            prop: 'scenicScale',
            maxlength: 60,
            showWordLimit: true,
            span: 20,
            row: true
          },
          {
            label: '介绍信息',
            prop: 'servicePhones',
            maxlength: 60,
            showWordLimit: true,
            span: 20,
            row: true
          },
          {
            label: '区域头图',
            prop: 'headImage',
            type: 'upload',
            listType: 'picture-img',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '建议尺寸：宽 338、高 自适应，最大20M',
            fileSize: 20 * 1024,
            span: 24,

            change: (e) => {
              if (e.value !== '') {
                this.$refs.form.clearValidate(`headImage`)
              }
            }
          },
          {
            label: '区域banner',
            prop: 'banner',
            span: 24,

            change: (e) => {
              if (e.value.length !== 0) {
                this.$refs.form.clearValidate(`banner`)
              }
            }
          },
          {
            label: '区域介绍',
            prop: 'regionDesc',
            maxlength: 50,
            showWordLimit: true,
            span: 20,
            row: true
          }
        ]
      },
      serviceTelFormOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 140,
        column: [
          {
            label: '客服电话',
            prop: 'serviceTelData',
            maxlength: 20,
            showWordLimit: true,
            span: 20,
            row: true
          }
        ]
      },
      dialogTableVisibleServiceTel: false,
      serviceTelForm: {
        serviceTelData: ''
      },
      serviceTelState: 0,
      options: {
        action: this.actionUrl,
        customConfig: {}, //wangEditor编辑的配置
        headers: {
          Authorization: this.$store.state.user.Token
        },
        props: {
          url: 'path',
          res: 'data',
          name: 'path'
        }
      }
    }
  },
  async mounted() {
    this.$emit('onload')
  },
  methods: {
    onScaleHandle(e) {
      //计算分成比例
      const platformScale = Number(this.form?.platformScale || 0)
      const betweenScale = Number(this.form?.betweenScale || 0)
      const runScale = Number(this.form?.runScale || 0)
      const scenicScale = Number(this.form?.scenicScale || 0)
      this.form.totalNum = (
        Number((platformScale > 100 ? 100 : platformScale).toFixed(1)) +
        Number((betweenScale > 100 ? 100 : betweenScale).toFixed(1)) +
        Number((runScale > 100 ? 100 : runScale).toFixed(1)) +
        Number((scenicScale > 100 ? 100 : scenicScale).toFixed(1))
      ).toFixed(1)
    },
    //关闭客服电话弹窗
    handleClose() {
      this.dialogTableVisibleServiceTel = false
      this.serviceTelState = 0
      this.serviceTelForm = {
        serviceTelData: '',
        index: ''
      }
    },
    //添加/编辑客服电话
    serviceTelsubmit(form, done) {
      if (this.serviceTelState) {
        this.form.servicePhones = this.form.servicePhones.map((item, index) => {
          if (index === this.serviceTelForm.index) {
            return this.serviceTelForm.serviceTelData
          }
          return item
        })
      } else {
        this.form.servicePhones.push(form.serviceTelData)
      }
      done()
      this.handleClose()
    },
    //查看/删除客服电话列表
    handleServiceTelCreate(index, serviceTelData, state) {
      this.serviceTelState = state
      if (state === 0) {
        this.serviceTelForm = {
          serviceTelData: '',
          index: ''
        }
        this.dialogTableVisibleServiceTel = true
      } else if (state === 1) {
        this.serviceTelForm = {
          serviceTelData,
          index
        }
        this.dialogTableVisibleServiceTel = true
      } else if (state === 2) {
        this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.form.servicePhones.splice(index, 1)
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
          .catch(() => {})
      }
    },
    //开始拖拽事件
    onStart() {
      this.drag = true
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false
    },
    submitForm() {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid, done) => {
          if (valid) {
            done()
            resolve(this.form)
          } else {
            done()
            resolve(false)
          }
        })
      })
    },
    uploadDelete(file, column) {
      return this.$confirm(`是否确定移除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          // // console.log(action)
          if (action === 'confirm') {
            let array = JSON.parse(
              JSON.stringify(this.form[column.prop].split(','))
            )
            let index = array?.findIndex((item) => item === file.url)
            array?.splice(index, 1)
            column.propsHttp.name = array?.toString()
            if (array.length < column.limit) {
              this.upload('inline-block', column.prop)
            }
          }
          done()
        }
      })
    },
    uploadError(error, column) {
      error && this.$message.error(error)
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === '' || column.accept.indexOf(file.type) < 0) {
        this.$message.error('文件格式有误')
        loading()
      } else {
        done()
      }
    },
    uploadAfter(res, done, loading, column) {
      done()
      // // console.log(res)
      // // console.log(column)
      if (!res.path) {
        this.$message.error('上传失败')
        loading()
      } else {
        if (column.prop === 'banner') {
          if (column.propsHttp.name) {
            column.propsHttp.name += ',' + res.path
          } else {
            column.propsHttp.name = res.path
          }
        } else {
          column.propsHttp.name = res.path
        }
        // console.log(column.propsHttp.name.split(',').length);
        if (column.propsHttp.name.split(',').length >= column.limit) {
          // console.log(column.propsHttp.name.split(',').length);
          // console.log(column.limit);
          this.upload('none')
        }
      }
    },
    upload(type) {
      const content = document.getElementsByClassName(
        'el-form-item__content'
      )[3]
      const upload = content.getElementsByClassName('el-upload')[0]
      upload.style.display = type === 'none' ? 'none' : 'inline-block'
    },
    handleUpload(e) {
      this.form.banner.push(e)
    },
    handleDelete(index) {
      this.$confirm('是否确定移除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.form.banner.splice(index, 1)
          }
          done()
        }
      })
    },

    handleView(index) {
      this.imgViewerIndex = index
      this.imgViewerVisible = true
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, false) // 禁止页面滑动
    },
    closeImgViewer() {
      this.imgViewerVisible = false
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
    },
    salaryChange(e, t, max) {
      const value = e.target.value
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = ''
          e.target.value = ''
        } else {
          if (value > max) {
            this.form[t] = max
            e.target.value = max
          } else {
            if (t === 'sort' && value < 1) {
              this.form[t] = 1
              e.target.value = 1
              return
            }
            this.form[t] = value * 1
            e.target.value = value * 1
          }
        }
      } else {
        this.form[t] = ''
        delete this.form[t]
      }
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = ''
          e.target.value = ''
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(1)
            e.target.value = max.toFixed(1)
          } else {
            this.form[t] = parseFloat(value).toFixed(1)
            e.target.value = parseFloat(value).toFixed(1)
          }
        }
      } else {
        this.form[t] = ''
        delete this.form[t]
      }
    }
  }
}
</script>

<style scoped>
/deep/ .avue-form__group .avue-upload__icon {
  width: 148px !important;
  height: 148px !important;
  line-height: 148px !important;
}
/deep/ .avue-form__group .avue-upload__avatar {
  width: 148px !important;
  height: 148px !important;
  object-fit: contain;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: contain;
}
/deep/ .ElImage {
  width: 148px !important;
  height: 148px !important;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
/deep/ .ElImage .ElImageItem {
  width: 148px !important;
  height: 148px !important;
}
.ElImageHover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
}
/deep/.ElImageHover:hover {
  opacity: 1;
}
/deep/ .ElIcon {
  color: #fff;
  font-size: 20px;
}

.travel .travel-list {
  width: 33%;
  text-align: center;
  border-left: 1px solid #999;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}

.travel .travel-list:last-child {
  border-right: 1px solid #999;
}

.travel .travel-list .travel-list-title {
  border-bottom: 1px solid #999;
}

.avue-crud .el-input--small input,
::v-deep .avue-form .travel .travel-list .el-input--small input {
  border: none;
}
</style>
