import { get } from "@/api/axios";

// 区域列表
export const list = params => get('/web/map/region/list', params);

// 地图区域位置
export const addressList = params => get('/web/map/region/address/list', params);
// 
// 地图区域数量
export const regionNum = params => get('/web/map/region/num', params);

// 地图上部-统计
export const upCount = params => get('/web/map/up/count', params);

// 车辆状态
export const carState = params => get('/web/map/car/state', params);

// 订单金额
export const orderPrice = params => get('/web/map/order/price', params);

// 公告
export const mapNotice = params => get('/web/map/notice', params);

// 车辆信息
export const carList = params => get('/web/map/car/list', params);

// 车型占比
export const typeProportion = params => get('/web/map/car/type/proportion', params);

// 订单占比
export const orderProportion = params => get('/web/map/order/proportion', params);
