const log = [
  {
    path: "/setting/interrupt",
    name: "settingInterrupt",
    component: () => import("@/view/setting/interrupt"),
  },
  {
    path: "/setting/business",
    name: "settingBusiness",
    component: () => import("@/view/setting/business"),
  },
  {
    path: "/setting/agreement",
    name: "settingAgreement",
    component: () => import("@/view/setting/agreement"),
  },
  {
    path: "/setting/subscribe",
    name: "settingSubscribe",
    component: () => import("@/view/setting/subscribe"),
  },
  {
    path: "/setting/phone",
    name: "settingPhone",
    component: () => import("@/view/setting/phone"),
  },
  {
    path: "/setting/give",
    name: "settingGive",
    component: () => import("@/view/setting/give"),
  },
];
export default log;
