<template>
  <div class="main-content">
    <h3 class="marginBottom20">{{ type === 'create' ? '新增' : '编辑' }}</h3>

    <avue-form
      ref="form"
      v-model="form"
      v-loading="loading"
      :option="formOption"
      @submit="submit"
    >
      <template slot="h3">
        <h3>基本信息</h3>
      </template>

      <template slot="partnerPhoneCode">
        <el-input
          v-model="form.partnerPhoneCode"
          placeholder="请输入手机验证码"
          type="text"
          maxlength="4"
        >
          <template slot="append">
            <div style="padding:0 20px">
              <el-button
                type="text"
                @click="getPhoneCodeHandle(form)"
                v-if="showPhoneCode"
                :loading="loadingPhoneCode"
              >获取验证码</el-button>
              <el-button
                type="text"
                disabled
                v-else
              >{{countPhoneCode}}s</el-button>
            </div>
          </template>

        </el-input>
      </template>

      <template slot="partnerPhoneCodes">
        <el-input
          v-model="form.partnerPhoneCodes"
          placeholder="请输入电话验证码"
          type="text"
          maxlength="4"
        >
          <template slot="append">
            <div style="padding:0 20px">
              <el-button
                type="text"
                @click="getPhoneCodeHandle(form)"
                v-if="showPhoneCodes"
                :loading="loadingPhoneCodes"
              >获取验证码</el-button>
              <el-button
                type="text"
                disabled
                v-else
              >{{countPhoneCodes}}s</el-button>
            </div>
          </template>
        </el-input>
      </template>

      <template slot="emailCode">
        <el-input
          v-model="form.emailCode"
          placeholder="请输入邮箱验证码"
          type="text"
          maxlength="4"
        >

          <template slot="append">
            <div style="padding:0 20px">
              <el-button
                type="text"
                @click="getEmailCodeHandle(form)"
                v-if="showEmailCode"
                :loading="loadingEmailCode"
              >获取验证码</el-button>
              <el-button
                type="text"
                disabled
                v-else
              >{{countEmailCode}}s</el-button>
            </div>
          </template>
        </el-input>
      </template>

      <template slot="id">
        <el-button
          type="text"
          v-if="JSON.stringify(form.regionId) == '{}'"
          @click="handleSelectIdShow"
        >请选择会员</el-button>
        <el-button
          type="text"
          v-else
          @click="handleSelectIdShow"
        >{{
          form.regionId.code
        }}</el-button>
      </template>

      <template slot="workCertImages">
        <div class="flex">
          <draggable
            v-model="form.workCertImages"
            filter=".forbid"
            chosenClass="chosen"
            forceFallback="true"
            group="key"
            animation="1000"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group class="flex flex-wrap">
              <div
                class="ElImage"
                v-for="(url, index) in form.workCertImages"
                :key="`ElImage${index}`"
              >
                <el-image
                  class="ElImageItem"
                  :src="url"
                  :key="`ElImage${index}`"
                  fit="contain"
                >
                </el-image>
                <div class="ElImageHover">
                  <div class="flex">
                    <i
                      class="el-icon-zoom-in ElIcon"
                      @click.stop="handleView(index)"
                    ></i>
                    <i
                      class="el-icon-delete ElIcon"
                      style="margin-left: 15px;"
                      @click.stop="handleDelete(index)"
                    ></i>
                  </div>
                </div>
              </div>
              <div
                class="forbid"
                :key="`ElImage${10}`"
                v-if="form.workCertImages.length < 9"
              >
                <UploadImage @upload="handleUpload" />
              </div>
            </transition-group>
          </draggable>
        </div>
        <div class="span">建议尺寸：800*800,最大20M，最多上传9张</div>

      </template>

      <template slot="distributionTitle">
        <h3>使用规则说明</h3>
      </template>

      <template slot="partnerTypeId">
        <el-select
          v-model="form.partnerTypeId"
          placeholder="请选择销售合伙人"
          @change="getTypeListHandle"
        >
          <el-option
            v-for="item in partnerTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>

      <template slot="retailScale">
        <el-input
          v-model="form.retailScale"
          placeholder="请输入 平台比例"
          type="text"
          onkeyup="this.value=this.value.match(/\d+\.?\d{0,1}/)"
          @blur="salaryDianChange($event,'retailScale',100)"
          :disabled=true
        />
        <template slot="append">%</template>
      </template>

      <template slot="couponId">
        <el-select
          v-model="form.couponId"
          placeholder="请选择关联的优惠券"
          @change="getCouponListHandle"
          :disabled=true
        >
          <el-option
            v-for="item in couponList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>

      <template slot="menuForm">
        <el-button
          icon="el-icon-close"
          @click="goBack"
        >取消</el-button>
      </template>
    </avue-form>

    <el-image-viewer
      v-if="imgViewerVisible"
      :on-close="closeImgViewer"
      :initialIndex="imgViewerIndex"
      :url-list="form.workCertImages"
    />

    <UserLink
      :show="showSelectId"
      :defaultData="form.regionId"
      @change="handleSelectId"
      @close="handleSelectCloseId"
    />

  </div>
</template>

<script>
import UploadImage from "./UploadImage";
import draggable from "vuedraggable";
import UserLink from "./components/userLink";
import {
  add,
  update,
  view,
  getTypeList,
  getCouponList,
  getPhoneCode,
  getEmailCode,
} from "@/api/region/commission";
import log from '../../../router/view/log';

export default {
  name: "create",
  components: {
    UserLink,
    draggable,
    UploadImage,
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      loadingPhoneCode: false,
      loadingPhoneCodes: false,
      loadingEmailCode: false,
      showPhoneCode: true,
      timerPhoneCode: null, //设置计时器,
      countPhoneCode: "",
      showPhoneCodes: true,
      timerPhoneCodes: null, //设置计时器,
      countPhoneCodes: "",
      showEmailCode: true,
      timerEmailCode: null, //设置计时器,
      countEmailCode: "",
      partnerTypeList: [],
      couponList: [],
      drag: false, // 拖拽
      imgViewerVisible: false, // 图片预览
      imgViewerIndex: 0, // 图片预览索引
      type: this.$route.query.type,
      loading: false,
      form: {
        regionId: {},
        workCertImages: [],
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "",
            prop: "h3",
            span: 24,
            labelWidth: 0,
          },
          {
            label: "选择会员",
            prop: "id",
            type: "select",
            dicData: [
              { label: "满减", value: 1 },
              { label: "代金", value: 2 },
            ],
            span: 24,
            row: true,
            rules: [
              {
                required: true,
                message: "请选择会员",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  console.log("value", value);
                  if (value === "") {
                    callback(new Error("请选择会员"));
                  } else {
                    callback();
                  }
                },
              },
            ],
            change: (e) => {
              const useConditionSubtractPrice = this.findObject(
                this.formOption.column,
                "useConditionSubtractPrice"
              );
              // const appId = this.findObject(this.formOption.column, "appId");
              const takePrice = this.findObject(
                this.formOption.column,
                "takePrice"
              );
              if (e.value === 1) {
                useConditionSubtractPrice.display = true;
                // appId.display = false;
                takePrice.display = false;
              } else {
                useConditionSubtractPrice.display = false;
                // appId.display = true;
                takePrice.display = true;
              }
            },
          },
          {
            label: "选择类型",
            prop: "overdueType",
            type: "radio",
            value: 2,
            dicData: [
              { label: "个人", value: 2 },
              { label: "公司", value: 1 },
            ],
            span: 24,
            row: true,
            rules: [
              {
                required: true,
                message: "请选择类型",
                trigger: "blur",
              },
            ],
            change: (e) => {
              const partnerUsername = this.findObject(
                this.formOption.column,
                "partnerUsername"
              );
              const companyTitle = this.findObject(
                this.formOption.column,
                "companyTitle"
              );
              const address = this.findObject(
                this.formOption.column,
                "address"
              );
              const dutyParagraph = this.findObject(
                this.formOption.column,
                "dutyParagraph"
              );
              const gatherPriceAccount = this.findObject(
                this.formOption.column,
                "gatherPriceAccount"
              );

              const gatherPriceAccounts = this.findObject(
                this.formOption.column,
                "gatherPriceAccounts"
              );

              const partnerPhone = this.findObject(
                this.formOption.column,
                "partnerPhone"
              );
              const partnerPhoneCode = this.findObject(
                this.formOption.column,
                "partnerPhoneCode"
              );
              const partnerPhones = this.findObject(
                this.formOption.column,
                "partnerPhones"
              );
              const partnerPhoneCodes = this.findObject(
                this.formOption.column,
                "partnerPhoneCodes"
              );
              const idCard = this.findObject(this.formOption.column, "idCard");
              const workCertImages = this.findObject(
                this.formOption.column,
                "workCertImages"
              );

              if (e.value === 1) {
                this.form.partnerPhones = this.form.partnerPhone;
                this.form.gatherPriceAccounts = this.form.gatherPriceAccount;
                partnerUsername.display = false;
                companyTitle.display = true;
                address.display = true;
                dutyParagraph.display = true;
                gatherPriceAccounts.display = true;
                gatherPriceAccount.display = false;
                partnerPhoneCode.display = false;
                partnerPhone.display = false;
                partnerPhoneCodes.display = true;
                partnerPhones.display = true;
                idCard.display = false;
                workCertImages.display = false;
              } else {
                this.form.partnerPhone = this.form.partnerPhones;
                this.form.gatherPriceAccount = this.form.gatherPriceAccounts;
                partnerUsername.display = true;
                companyTitle.display = false;
                address.display = false;
                dutyParagraph.display = false;
                gatherPriceAccounts.display = false;
                gatherPriceAccount.display = true;
                partnerPhoneCode.display = true;
                partnerPhoneCode.display = true;
                partnerPhone.display = true;
                partnerPhoneCodes.display = false;
                partnerPhones.display = false;
                idCard.display = true;
                workCertImages.display = true;
              }
            },
          },
          {
            display: true,
            label: "姓名",
            prop: "partnerUsername",
            maxlength: 20,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入姓名",
            rules: [
              {
                required: true,
                message: "请输入姓名",
                trigger: "blur",
              },
            ],
          },
          {
            display: true,
            label: "抬头",
            prop: "companyTitle",
            maxlength: 50,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入抬头",
            rules: [
              {
                required: true,
                message: "请输入抬头",
                trigger: "blur",
              },
            ],
          },
          {
            label: "公司地址",
            prop: "address",
            maxlength: 200,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入公司地址",
            rules: [
              {
                required: true,
                message: "请输入公司地址",
                trigger: "blur",
              },
            ],
          },
          {
            label: "税号",
            prop: "dutyParagraph",
            maxlength: 50,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入税号",
            rules: [
              {
                required: true,
                message: "请输入税号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "收款账号",
            prop: "gatherPriceAccounts",
            span: 12,
            maxlength: 100,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入收款账号",
                trigger: "blur",
              },
            ],
            display: true,
          },
          {
            label: "联系电话",
            prop: "partnerPhones",
            maxlength: 11,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入联系电话",
            rules: [
              {
                required: true,
                message: "请输入联系电话",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  const isPhone = /^1[3456789]\d{9}$/;
                  if (!isPhone.test(value)) {
                    callback(new Error("请输入正确格式的联系电话"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "邮箱",
            prop: "email",
            maxlength: 30,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入邮箱",
            rules: [
              {
                required: true,
                message: "请输入邮箱",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  const isEmail =
                    /^([\.a-zA-Z0-9_-])+@[\.a-zA-Z0-9_-]+(\.[\.a-zA-Z0-9_-]+)+$/;
                  if (!isEmail.test(value)) {
                    callback(new Error("请输入正确格式的邮箱"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "电话验证码",
            prop: "partnerPhoneCodes",
            maxlength: 4,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入电话验证码",
            rules: [
              {
                required: true,
                message: "请输入电话验证码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "身份证号",
            prop: "idCard",
            maxlength: 18,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入身份证号",
            rules: [
              {
                required: true,
                message: "请输入身份证号",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  var regIdNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                  if (!regIdNo.test(value)) {
                    callback(new Error("请输入正确格式的身份证号"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "邮箱验证码",
            prop: "emailCode",
            maxlength: 4,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入邮箱验证码",
            rules: [
              {
                required: true,
                message: "请输入邮箱验证码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "手机号",
            prop: "partnerPhone",
            maxlength: 11,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入手机号",
            rules: [
              {
                required: true,
                message: "请输入手机号",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  const isPhone = /^1[3456789]\d{9}$/;
                  if (!isPhone.test(value)) {
                    callback(new Error("请输入正确格式的手机号"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "收款账号",
            prop: "gatherPriceAccount",
            span: 12,
            maxlength: 100,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入收款账号",
                trigger: "blur",
              },
            ],
            display: true,
          },
          {
            label: "手机验证码",
            prop: "partnerPhoneCode",
            maxlength: 4,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入手机验证码",
            rules: [
              {
                required: true,
                message: "请输入手机验证码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "介绍人姓名",
            prop: "introducer",
            maxlength: 10,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入介绍人姓名",
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入介绍人姓名",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "介绍人手机号",
            prop: "introducerPhone",
            maxlength: 11,
            showWordLimit: true,
            span: 12,
            placeholder: "请输入介绍人手机号",
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入介绍人手机号",
            //     trigger: "blur",
            //   },
            //   {
            //     validator: (rule, value, callback) => {
            //       const isPhone = /^1[3456789]\d{9}$/;
            //       if (!isPhone.test(value)) {
            //         callback(new Error("请输入正确格式的手机号"));
            //       } else {
            //         callback();
            //       }
            //     },
            //   },
            // ],
          },
          {
            label: "职业证明",
            prop: "workCertImages",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传职业证明",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传职业证明"));
                  } else {
                    callback();
                  }
                },
              },
            ],
            change: (e) => {
              if (e.value.length !== 0) {
                this.$refs.form.clearValidate(`workCertImages`);
              }
            },
          },
          {
            label: "",
            prop: "distributionTitle",
            span: 24,
            labelWidth: 0,
          },
          {
            label: "类型",
            prop: "partnerTypeId",
            span: 12,
            rules: [
              {
                required: true,
                message: "请选择销售合伙人",
                trigger: "blur",
              },
            ],
          },
          {
            label: "分销比例",
            prop: "retailScale",
            showWordLimit: true,
            span: 12,
            placeholder: "请输入分销比例",
            rules: [
              {
                required: true,
                message: "请输入分销比例",
                trigger: "blur",
              },
            ],
          },
          {
            label: "关联的优惠券",
            prop: "couponId",
            span: 12,
            rules: [
              {
                required: true,
                message: "请选择关联的优惠券",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      showSelectId: false,
      disabled: false,
    };
  },
  mounted() {
    this.getTypeListHandle('');
    this.getCouponListHandle();
    if (this.$route.query.id) {
      this.getView();
      this.disabled = true;
    }
  },
  methods: {
    getPhoneCodeHandle(form) {
      if (form.overdueType === 2) {
        this.loadingPhoneCode = true;
        if (!form.partnerPhone) {
          this.$message.warning("请先输入手机号码");
          return;
        }
      }
      if (form.overdueType === 1) {
        this.loadingPhoneCodes = true;
        if (!form.partnerPhones) {
          this.$message.warning("请先输入电话验号码");
          return;
        }
      }

      let TIME_COUNT = 60;
      getPhoneCode({
        phone: form.overdueType == 1 ? form.partnerPhones : form.partnerPhone,
        saleType: form.overdueType,
      })
        .then((res) => {
          this.loadingPhoneCode = false;
          this.loadingPhoneCodes = false;
          if (res.code === 200) {
            this.$message.success("验证码发送成功,请留意短信查收!");

            if (!this.timerPhoneCode && form.overdueType === 2) {
              this.countPhoneCode = TIME_COUNT;
              this.showPhoneCode = false;
              this.timerPhoneCode = setInterval(() => {
                if (
                  this.countPhoneCode > 0 &&
                  this.countPhoneCode <= TIME_COUNT
                ) {
                  this.countPhoneCode--;
                } else {
                  this.showPhoneCode = true;
                  clearInterval(this.timerPhoneCode);
                  this.timerPhoneCode = null;
                }
              }, 1000);
            }
            if (!this.timerPhoneCodes && form.overdueType === 1) {
              this.countPhoneCodes = TIME_COUNT;
              this.showPhoneCodes = false;
              this.timerPhoneCodes = setInterval(() => {
                if (
                  this.countPhoneCodes > 0 &&
                  this.countPhoneCodes <= TIME_COUNT
                ) {
                  this.countPhoneCodes--;
                } else {
                  this.showPhoneCodes = true;
                  clearInterval(this.timerPhoneCodes);
                  this.timerPhoneCodes = null;
                }
              }, 1000);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingPhoneCode = false;
          this.loadingPhoneCodes = false;
        });
    },
    getEmailCodeHandle(form) {
      this.loadingEmailCode = true;
      if (!form.email) {
        this.$message.warning("请先输入邮箱");
        return;
      }
      let TIME_COUNT = 60;
      getEmailCode({
        email: form.email,
        saleType: form.overdueType,
      })
        .then((res) => {
          this.loadingEmailCode = false;
          if (res.code === 200) {
            this.$message.success("验证码发送成功,请留意邮箱信息!");
            if (!this.timerEmailCode) {
              this.countEmailCode = TIME_COUNT;
              this.showEmailCode = false;
              this.timerEmailCode = setInterval(() => {
                if (
                  this.countEmailCode > 0 &&
                  this.countEmailCode <= TIME_COUNT
                ) {
                  this.countEmailCode--;
                } else {
                  this.showEmailCode = true;
                  clearInterval(this.timerEmailCode);
                  this.timerEmailCode = null;
                }
              }, 1000);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingEmailCode = false;
        });
    },
   async getTypeListHandle(e) {
    await  getTypeList().then((res) => {
        if (res.code === 200) {
          this.partnerTypeList = res.data;
        }
      });
      if(e) {
        const newData = this.partnerTypeList.filter((item,index) => item.id == e)
        console.log(newData,'newDatanewData');
        this.form.retailScale =  newData[0].retailScale || ''
        this.form.couponId =  newData[0].couponId || ''
      }
    },
    getCouponListHandle() {
      getCouponList().then((res) => {
        if (res.code === 200) {
          this.couponList = res.data;
        }
      });
    },
    handleDelete(index) {
      this.$confirm("是否确定移除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.form.workCertImages.splice(index, 1);
          }
          done();
        },
      });
    },
    //开始拖拽事件
    onStart() {
      this.drag = true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false;
    },
    handleUpload(e) {
      this.form.workCertImages.push(e);
    },
    closeImgViewer() {
      this.imgViewerVisible = false;
      const m = (e) => {
        e.preventDefault();
      };
      document.body.style.overflow = "auto";
      document.removeEventListener("touchmove", m, true);
    },
    handleSelectIdShow() {
      if (this.type === "create") {
        this.showSelectId = true;
      }
    },
    // 会员选择
    handleSelectId(e) {
      this.form.regionId = e;
      this.form.id = e.id;
      this.handleSelectCloseId();
    },
    handleSelectCloseId() {
      this.showSelectId = false;
    },
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    getView() {
      this.loading = true;
      view({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.form = {
            address: res.data.address,
            companyTitle: res.data.companyTitle,
            couponId: res.data.couponId,
            dutyParagraph: res.data.dutyParagraph,
            email: res.data.email,
            id: res.data.id,
            regionId: {
              id: res.data.id,
              code: res.data.code,
            },
            idCard: res.data.idCard,
            introducer: res.data.introducer,
            introducerPhone: res.data.introducerPhone,
            partnerPhone: res.data.partnerPhone,
            gatherPriceAccount: res.data.gatherPriceAccount,
            partnerTypeId: res.data.partnerTypeId,
            partnerUsername: res.data.partnerUsername,
            retailScale: res.data.retailScale,
            overdueType: res.data.saleType,
            workCertImages: [],
          };

          if (res.data.saleType === 1) {
            this.form.gatherPriceAccounts = res.data.gatherPriceAccount;
            this.form.partnerPhoneCode = res.data.partnerPhoneCodes;
          }

          if (res.data.saleType === 2) {
            this.form.gatherPriceAccount = res.data.gatherPriceAccount;
            this.form.partnerPhoneCode = res.data.partnerPhoneCode;
            this.form.workCertImages = res.data.workCertImages.split(",");
          }
          console.log(this.form.gatherPriceAccounts);
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    submit(form, done) {
      const data = {
        ...form,
        saleType: form.overdueType,
      };

      if (data.saleType === 1) {
        data.gatherPriceAccount = form.gatherPriceAccounts;
        data.partnerPhoneCode = form.partnerPhoneCodes;
        data.partnerPhone = form.partnerPhones;
      }

      if (form.overdueType === 2) {
        data.workCertImages = form.workCertImages.join(",");
      }
      if (this.type === "edit") {
        update(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      } else {
        add(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      }
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(1);
            e.target.value = max.toFixed(1);
          } else {
            this.form[t] = parseFloat(value).toFixed(1);
            e.target.value = parseFloat(value).toFixed(1);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
/deep/ .avue-form__group .avue-upload__icon {
  width: 148px !important;
  height: 148px !important;
  line-height: 148px !important;
}
/deep/ .avue-form__group .avue-upload__avatar {
  width: 148px !important;
  height: 148px !important;
  object-fit: contain;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: contain;
}
/deep/ .ElImage {
  width: 148px !important;
  height: 148px !important;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
/deep/ .ElImage .ElImageItem {
  width: 148px !important;
  height: 148px !important;
}
.ElImageHover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
}
/deep/.ElImageHover:hover {
  opacity: 1;
}
/deep/ .ElIcon {
  color: #fff;
  font-size: 20px;
}

.travel .travel-list {
  width: 33%;
  text-align: center;
  border-left: 1px solid #999;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}

.travel .travel-list:last-child {
  border-right: 1px solid #999;
}

.travel .travel-list .travel-list-title {
  border-bottom: 1px solid #999;
}

.avue-crud .el-input--small input,
::v-deep .avue-form .travel .travel-list .el-input--small input {
  border: none;
}
</style>