<template>
  <div>
    <h4 class="marginBottom20 title-18">基础信息</h4>
    <el-descriptions>
      <el-descriptions-item label="会员编号">{{ form.code }}</el-descriptions-item>
      <el-descriptions-item label="会员头像">
        <el-image
            style="width: 80px; height: 80px;border-radius: 50%"
            v-if="form.avatarUrl"
            :preview-src-list="[form.avatarUrl]"
            alt="会员头像"
            :src="form.avatarUrl"
            >
          </el-image>
        <!-- <img
          v-if="form.avatarUrl"
          width="80px"
          height="80px"
          :src="form.avatarUrl"
          alt="会员头像"
          style=" border-radius: 50%"
        > -->
      </el-descriptions-item>
      <el-descriptions-item label="会员昵称">{{ form.nickname }}</el-descriptions-item>
      <el-descriptions-item label="会员手机号">{{ form.phone }}</el-descriptions-item>
      <el-descriptions-item label="会员openid">{{ form.openid }}</el-descriptions-item>
      <el-descriptions-item label=" 注册时间">{{ form.registerTime }}</el-descriptions-item>
      <el-descriptions-item label="用户来源">{{ form.memberSource===1?'自行注册':form.memberSource===2?'销售合伙人裂变':'' }}</el-descriptions-item>
      <el-descriptions-item label="会员类型">{{ form.type===1?'普通用户':form.type===2?'销售合伙人':'' }}{{ form.isWorker===1?'/工作人员':'' }}</el-descriptions-item>
      <el-descriptions-item label=" 账户状态">{{ form.state===1?'启用':form.state===2?'禁用':'' }}</el-descriptions-item>
    </el-descriptions>
    <h4 class="margin20 title-18 flex align-items justify-content-space-between">
      <div>
        会员等级信息
      </div>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="handleEditIntimacy"
        >调整亲昵度</el-button>
      </div>
    </h4>
    <el-descriptions>
      <el-descriptions-item label="当前会员等级">{{ form.levelName }}</el-descriptions-item>
      <el-descriptions-item label="当前亲昵度">{{ form.intimacy	 }}</el-descriptions-item>
    </el-descriptions>
    <h4 class="margin20 title-18 flex align-items justify-content-space-between">
      <div>
        积分信息
      </div>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="handleEditAvailableIntegral"
        >调整积分</el-button>
      </div>
    </h4>
    <el-descriptions>
      <el-descriptions-item label="当前可用积分">{{ form.availableIntegral }}</el-descriptions-item>
      <el-descriptions-item label="累计积分">{{ form.totalIntegral	 }}</el-descriptions-item>
    </el-descriptions>
    <div v-if="form.isWorker===1">
      <h4 class="margin20 title-18">工作人员所属</h4>
      <el-descriptions>
        <el-descriptions-item label="所属区域">{{ form.regionName }}</el-descriptions-item>
      </el-descriptions>
    </div>

    <el-dialog
      v-if="dialogVisibleIntimacy"
      title="调整亲昵度"
      :visible.sync="dialogVisibleIntimacy"
      width="450px"
      :before-close="handleCloseIntimacy"
    >
      <avue-form
        ref="forms"
        v-model="forms"
        :option="formOptionIntimacy"
        @submit="submitIntimacy"
      >
        <template slot="intimacy">
          <div class="flex justify-content-space-between">
            <el-button
              plain
              @click="reduceHandle('intimacy',forms.intimacy,)"
            >-</el-button>
            <el-input
              class="marginLeft10 marginRight10"
              v-model="forms.intimacy"
              placeholder="请输入 亲昵度"
              onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
              @blur="salaryChange($event, 'intimacy', 99999999)"
              type="text"
              clearable
            />
            <el-button
              plain
              @click="addHandle('intimacy',forms.intimacy,)"
            >+</el-button>
          </div>

        </template>

        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleCloseIntimacy"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog
      v-if="dialogVisibleAvailableIntegral"
      title="修改积分"
      :visible.sync="dialogVisibleAvailableIntegral"
      width="40%"
      :before-close="handleCloseAvailableIntegral"
    >
      <avue-form
        ref="forms"
        v-model="forms"
        :option="formOptionAvailableIntegral"
        @submit="submitAvailableIntegral"
      >

        <template slot="availableIntegral">
          <div class="flex justify-content-space-between">
            <el-button
              plain
              @click="reduceHandle('availableIntegral',forms.availableIntegral,)"
            >-</el-button>
            <el-input
              class="marginLeft10 marginRight10"
              v-model="forms.availableIntegral"
              placeholder="请输入 积分"
              onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
              @blur="salaryChange($event, 'availableIntegral', 99999999)"
              type="text"
              clearable
            />
            <el-button
              plain
              @click="addHandle('availableIntegral',forms.availableIntegral,)"
            >+</el-button>
          </div>

        </template>

        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleCloseAvailableIntegral"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  view,
  updateIntimacy,
  updateAvailableIntegral,
} from "@/api/user/manage";
export default {
  props: {
    id: {
      typeof: String,
    },
  },
  data() {
    return {
      form: {
        availableIntegral: "",
        avatarUrl: "",
        code: "",
        id: "",
        intimacy: "",
        isWorker: "",
        levelName: "",
        memberSource: 1,
        nickname: "",
        openid: "",
        phone: "",
        regionName: "",
        registerTime: "",
        state: "",
        totalIntegral: "",
        type: "",
      },
      dialogVisibleIntimacy: false,
      dialogVisibleAvailableIntegral: false,
      formOptionIntimacy: {
        labelWidth: "100",
        submitText: "确定",
        emptyBtn: false,
        column: [
          {
            label: "亲昵度",
            prop: "intimacy",
            type: "number",
            span: 24,
            value: 1,
            maxRows: 999999,
            minRows: 0,
            controls: false,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入亲昵度",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      formOptionAvailableIntegral: {
        labelWidth: "100",
        submitText: "确定",
        emptyBtn: false,
        column: [
          {
            label: "积分",
            prop: "availableIntegral",
            type: "number",
            span: 24,
            value: 1,
            maxRows: 999999,
            minRows: 0,
            controls: false,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入亲昵度",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      forms: {
        availableIntegral: "",
        avatarUrl: "",
        code: "",
        id: "",
        intimacy: "",
        isWorker: "",
        levelName: "",
        memberSource: 1,
        nickname: "",
        openid: "",
        phone: "",
        regionName: "",
        registerTime: "",
        state: "",
        totalIntegral: "",
        type: "",
      },
    };
  },
  mounted() {
    if (this.id) {
      this.getView();
    }
  },
  methods: {
    async getView() {
      const { data } = await view({ id: this.id });
      this.form = data;
      this.forms = this.form;
    },
    // 打开亲呢度弹窗
    async handleEditIntimacy() {
      this.dialogVisibleIntimacy = true;
    },
    // 修改亲呢度
    submitIntimacy(form, done) {
      updateIntimacy({
        id: form.id,
        intimacy: form.intimacy,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.dialogVisibleIntimacy = false;
            this.getView();
          } else {
            this.$message.error(res.msg);
            done();
          }
        })
        .catch(() => done());
    },
    // 关闭亲呢度弹窗
    handleCloseIntimacy() {
      this.dialogVisibleIntimacy = false;
    },
    // 打开积分弹窗
    async handleEditAvailableIntegral() {
      this.dialogVisibleAvailableIntegral = true;
    },
    // 修改积分
    submitAvailableIntegral(form, done) {
      updateAvailableIntegral({
        id: form.id,
        availableIntegral: form.availableIntegral,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.dialogVisibleAvailableIntegral = false;
            this.getView();
          } else {
            this.$message.error(res.msg);
            done();
          }
        })
        .catch(() => done());
    },
    // 关闭积分弹窗
    handleCloseAvailableIntegral() {
      this.dialogVisibleAvailableIntegral = false;
    },
    reduceHandle(t, value) {
      if (Number(value) > 0) {
        this.forms[t] = Number(value) - 1;
        value = this.forms[t];
        console.log(this.forms);
      }
    },
    addHandle(t, value) {
      if (Number(value) < 99999999) {
        this.forms[t] = Number(value) + 1;
        value = this.forms[t];
      }
    },
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.forms[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.forms[t] = max;
            e.target.value = max;
          } else {
            this.forms[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.forms[t] = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-descriptions-item__container {
    display: flex;
    align-items: center;
}
</style>