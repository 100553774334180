import { get, post, put, Delete } from "@/api/axios";

// 订单设置-查看
export const settingView = (params) => get("/web/order/setting/view", params);

// 订单设置-修改
export const settingUpdate = (params) =>
  put("/web/order/setting/update", params);

// 订单列表-列表
export const manageList = (params) => post("/web/order/manage/list", params);

// 订单列表-结束订单
export const manageOver = (params) =>
  get("/web/order/manage/over/" + params.id, "");

// 订单列表-订单详情
export const manageView = (params) =>
  get("/web/order/manage/view/" + params.id, "");

// 订单列表-车辆行驶轨迹
export const manageTravel = (params) =>
  get("/web/order/manage/travel/" + params.id, "");

// 订单列表-车辆行驶轨迹-位置信息
export const travelAdress = (params) =>
  get("/web/order/manage/travel/address", params);
