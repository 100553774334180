import { get, put, } from "@/api/axios";

// 获取banner列表
export const list = params => get('/web/deposit/setting/list', params);

// 修改banner
export const update = params => put('/web/deposit/setting/update', params);

// 查询banner详情
// export const view = params => get('/web/deposit/setting/view/' + params.id, '');


