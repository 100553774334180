<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex justify-content-space-between align-items marginBottom20">
          <div class="flex align-items">
            <div class="flex align-items marginRight10">
              <span class="span marginRight10">弹窗标题 : </span>
              <div class="width200">
                <el-input
                  v-model="searchData.title"
                  placeholder="请输入标题"
                  maxlength="20"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10">
              <span class="span marginRight10">到期时间 : </span>
              <div class="width200">
                <el-date-picker
                  v-model="searchData.endTime"
                  type="date"
                  placeholder="选择日期"
                  @change="handleSearch"
                >
                </el-date-picker>
              </div>
            </div>
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>
          </div>

          <el-button
            size="medium"
            type="primary"
            icon="el-icon-plus"
            @click="handleCreate"
          >新增弹窗</el-button>
        </div>
      </template>

      <template
        slot="state"
        slot-scope="{ row }"
      >

        <el-switch
          v-if="row.stateShow !== 2"
          v-model="row.state"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event, row.id)"
        ></el-switch>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
        >删除</el-button>
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
        >编辑</el-button>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { deleteNew, list, updateNew } from "@/api/home/frame";
import dayjs from "dayjs";

export default {
  name: "index",
  data() {
    return {
      searchData: {
        title: "",
        endTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "弹窗管理",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "弹窗标题",
            prop: "title",
          },
          {
            label: "弹窗图片",
            prop: "image",
            type: "img",
          },
          {
            label: "优先级",
            prop: "sort",
          },
          {
            label: "到期时间",
            prop: "endTime",
          },
          // {
          //   label: "每日展示次数",
          //   prop: "dayShowNum",
          // },
          {
            label: "显示状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1 ? "开启" : row.state === 2 ? "关闭" : "/";
            },
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        type: "",
        name: "",
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: {
          ...this.searchData,
          endTime: dayjs(this.searchData.endTime).format("YYYY-MM-DD 23:59:59"),
        },
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      // const searchData = {
      //   title: this.searchData.title,
      // };
      // if (this.searchData.endTime) {
      //   searchData.endTime = this.searchData.endTime;
      // }
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchForm.searchData,
        endTime:this.searchData.endTime ? searchForm.searchData.endTime : ''
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 添加
    handleCreate() {
      this.$router.push({
        path: "/home/frame/create",
        query: {
          type: "create",
        },
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        title: "",
        endTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    handleEdit(row) {
      // // console.log(row)
      this.$router.push({
        path: "/home/frame/create",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    handleSwitch(e, id) {
      // console.log(e,id)
      updateNew({
        id: id,
        state: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.msg);
        }
        this.onLoad();
      });
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
        name: "",
      };
    },
  },
};
</script>

<style scoped></style>
