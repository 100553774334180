<template>
  <div class="main-content">
    <div style="display: flex;flex-wrap: wrap;">
      <!-- 售后消息 -->
      <el-badge v-for="(item,index) in grade" :key="index" :value="item.num" :max="99" class="item">
        <div
          class="circle"
          :style="{
            backgroundColor:
              item.level === 3
                ? '#d9001b'
                : item.level === 2
                ? '#ffd83a'
                : item.level === 1
                ? '#70b603'
                : '',          
          }"
          @click="getJump(item.type,item.typeName)"
        >
          <div :class="{'colorFFF':item.level == 1 || item.level == 2 || item.level == 3,'message':true }">{{item.typeName}}</div>
        </div>
      </el-badge>
      <!-- 故障消息 -->
    <!--  <el-badge :value="grade.twoNum" :max="99" class="item">
        <div
          class="circle"
          :style="{
            backgroundColor:
              grade.twoLevel === 3
                ? '#d9001b'
                : grade.twoLevel === 2
                ? '#ffd83a'
                : grade.twoLevel === 1
                ? '#70b603'
                : '',
          }"
          @click="getJump(2)"
        >
          <div :class="{'colorFFF':grade.twoLevel == 1 || grade.twoLevel == 2 || grade.twoLevel == 3,'message':true }">故障消息</div>
        </div>
      </el-badge> -->
      <!-- 销售合伙人消息 -->
     <!-- <el-badge :value="grade.threeNum" :max="99" class="item">
        <div
          class="circle"
          :style="{
            backgroundColor:
              grade.threeLevel === 3
                ? '#d9001b'
                : grade.threeLevel === 2
                ? '#ffd83a'
                : grade.threeLevel === 1
                ? '#70b603'
                : '',
          }"
          @click="getJump(3)"
        >
          <div :class="{'colorFFF':grade.threeLevel == 1 || grade.threeLevel == 2 || grade.threeLevel == 3,'message':true }">销售合伙人消息</div>
        </div>
      </el-badge> -->
      <!-- 退还押金消息 -->
   <!--   <el-badge :value="grade.fourNum" :max="99" class="item">
        <div
          class="circle"
          :style="{
            backgroundColor:
              grade.fourLevel === 3
                ? '#d9001b'
                : grade.fourLevel === 2
                ? '#ffd83a'
                : grade.fourLevel === 1
                ? '#70b603'
                : '',
          }"
          @click="getJump(4)"
        >
          <div :class="{'colorFFF':grade.fourLevel == 1 || grade.fourLevel == 2 || grade.fourLevel == 3,'message':true }">退还押金消息</div>
        </div>
      </el-badge> -->
      <!-- 其它异常消息 -->
   <!--   <el-badge :value="grade.fiveNum" :max="99" class="item">
        <div
          class="circle"
          :style="{
            backgroundColor:
              grade.fiveLevel === 3
                ? '#d9001b'
                : grade.fiveLevel === 2
                ? '#ffd83a'
                : grade.fiveLevel === 1
                ? '#70b603'
                : '',
          }"
          @click="getJump(5)"
        >
          <div :class="{'colorFFF':grade.fiveLevel == 1 || grade.fiveLevel == 2 || grade.fiveLevel == 3,'message':true }">其它异常消息</div>
        </div>
      </el-badge> -->
    </div>
  </div>
</template>

<script>
import { manageList } from "@/api/message/index";
export default {
  name: "index",
  data() {
    return {
      disabled: false,
      grade: [],
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      manageList({}).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.grade = res.data;
        }
      });
    },
    getJump(e,name) {
      if (e === 1) {
        this.$router.push({
          path: "/message/manage/afterSalesNews",
          query: {
            type: e,
          },
        });
      } else if (e === 2) {
        this.$router.push({ path: "/message/manage/faultMessages",
          query: {
            type: e,
          }, });
      } else if (e === 3) {
        this.$router.push({ path: "/message/manage/partnerInformation",
          query: {
            type: e,
          }, });
      } else if (e === 4) {
        this.$router.push({ path: "/message/manage/depositMessage",
          query: {
            type: e,
          }, });
      } else if (e === 5) {
        this.$router.push({ path: "/message/manage/otherMessages",
          query: {
            type: e,
          }, });
      }else{
		  // 新增的消息类型
		  this.$router.push({ path: "/message/manage/other",
		    query: {
		      type: e,
			  name
		    }, });
		  
	  }

      console.log(e, "跳转对应路径");
    },
  },
};
</script>

<style scoped>
/deep/.el-badge__content.is-fixed {
  position: absolute;
  top: 30px;
  right: 54px;
  transform: translateY(-50%) translateX(100%);
}
.circle {
  width: 110px;
  height: 110px;
  /* background-color: #a72525; */
  -webkit-border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 30px;
  cursor: pointer;
  /* box-shadow: ; */
  box-shadow: 0px 0px 14px 2px #ddd;
}
.message {
  text-align: center;
  padding: 0 16px;
  word-break: break-all;
 
}
.colorFFF{
  color: #fff;
}
.message333 {
  text-align: center;
  padding: 0 20px;
  color: #333;
}
</style>
