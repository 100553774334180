<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 ">
              <span class="span marginRight10">时间 : </span>
              <div class="width250">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="统计开始日期"
                  end-placeholder="统计结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">订单编号 : </span>
              <div class="width250">
                <el-input
                  v-model="searchData.orderCode"
                  placeholder="请输入订单编号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">分账状态 : </span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.state"
                  clearable
                  @change="handleSearch"
                >
                  <el-option :value="0" label="待分账"></el-option>
                  <el-option :value="2" label="可分账"></el-option>
                  <el-option :value="1" label="已分账"></el-option>
                  <el-option :value="3" label="已关闭"></el-option>

                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">分账对象 : </span>
              <div class="width200">
                <el-input
                  v-model="searchData.financeKeyword"
                  placeholder="公司名称/姓名/商户号/手机号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
                <!-- <el-select
                  class="width250"
                  v-model="searchData.financeKeyword"
                  clearable
                  @change="handleSearch"
                >
                  <el-option value="" label="请选择"></el-option>
                  <el-option :value="1" label="启用"></el-option>
                  <el-option :value="2" label="禁用"></el-option> -->
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                >重置</el-button
              >
            </div>

            <div
              class="marginLeft10 flex align-items marginRight10 marginBottom10"
            >
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-download"
                @click="handleExport"
                >导出</el-button
              >
            </div>
          </div>

          <div class="order-box">
            <div class="order-box-list">
              <h3 class="order-box-title">
                {{ summaryData.financeAmount || 0 }}
              </h3>
              <div class="order-box-info">分账总额（元）</div>
            </div>
            <div class="order-box-list">
              <h3 class="order-box-title">
                {{ summaryData.financedAmount || 0 }}
              </h3>
              <div class="order-box-info">已分账金额（元）</div>
            </div>
            <div class="order-box-list">
              <h3 class="order-box-title">
                {{ summaryData.unFinanceAmount || 0 }}
              </h3>
              <div class="order-box-info">未分账金额（元）</div>
            </div>
          </div>
        </div>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import {
  getSummary,
  getReportPage,
  reportDetailExport,
  getDetailSummary,
  getDetailReportPage
} from '@/api/statistics/index'

import dayjs from 'dayjs'
import qs from 'qs'
export default {
  name: 'index',
  data() {
    return {
      searchData: {},
      summaryData: {},
      page: {
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '分账对象明细-区域',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: '序号', // 索引文字
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: '分账对象',
            prop: 'relationName',
            type: 'string'
          },
          {
            label: '类型',
            prop: 'financeUseType',
            formatter: function(row, column) {
              const financeUseType = row.financeUseType
              if (financeUseType === 0) {
                return '员工'
              } else if (financeUseType === 1) {
                return '公司'
              }
            }
          },
          {
            label: '公司名称/姓名',
            prop: 'financeUseName'
          },
          {
            label: '商户号/手机号',
            prop: 'accountOrPhone'
          },

          {
            label: '分账订单',
            prop: 'orderCode'
          },
          {
            label: '区域名称',
            prop: 'regionName'
          },
          {
            label: '分账比例（%）',
            prop: 'proportion'
          },
          {
            label: '分账金额（元）',
            prop: 'financeAmount'
          },

          {
            label: '分账状态',
            prop: 'financeStatus',
            formatter: function(row, column) {
              const financeStatus = row.financeStatus
              if (financeStatus === -1) {
                return '全部'
              } else if (financeStatus === 0) {
                return '待分账'
              } else if (financeStatus === 1) {
                return '已分账'
              } else if (financeStatus === 2) {
                return '可分账'
              } else if (financeStatus === 3) {
                return '已关闭'
              }
            }
          }
        ]
      }
    }
  },
  created() {
    this.getcarTypeListHandle()
  },
  methods: {
    getcarTypeListHandle() {},
    async onLoad() {
      this.showLoading = true
      const paramsSearchData = {
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }
      if (this.searchData.createTime) {
        paramsSearchData.startDate = dayjs(
          this.searchData.createTime[0]
        ).format('YYYY-MM-DD 00:00:00')
        paramsSearchData.endDate = dayjs(this.searchData.createTime[1]).format(
          'YYYY-MM-DD 23:59:59'
        )
      }
      const { data  ={}} = await getDetailSummary({ ...paramsSearchData })
      this.summaryData = data
      getDetailReportPage({
        ...paramsSearchData
      }).then((res) => {
        this.showLoading = false
        if (res.code === 200) {
          this.tableData = res.data.records
          this.page.total = res.data.total
        }
      })
    },
    // 添加
    handleView() {
      // console.log('添加')
      this.dialogVisible = true
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1
      this.onLoad()
    },
    // 重置
    handleReset() {
      // console.log('重置')

      this.searchData = {
        type: ''
      }
      this.page.currentPage = 1
      this.onLoad()
    },
    /**查看关闭modal框 */
    handleModalClose() {
      this.dialogVisible = false
    },
    handleExport () {
      const {  orderCode, state, financeKeyword } = this.searchData
      
      const searchData = {
        page: this.page.currentPage,
        size: this.page.pageSize,
        orderCode, state, financeKeyword
      }
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(this.searchData.createTime[0]).format(
          'YYYY-MM-DD 00:00:00'
        )
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          'YYYY-MM-DD 23:59:59'
        )
      }
      // reportDetailExport({ ...searchData })

      window.open(
        `/api/web/finance/report/order/export?${qs.stringify(searchData)}`,
        '_self'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 0px;
}
.order-box {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  background: #f6f6f6;
  padding: 20px;
  margin-bottom: 20px;
}
.order-box-list {
  width: 16.5%;
  text-align: center;
}
.order-box-title {
  font-size: 45px;
  font-weight: 400;
  height: 70px;
}
.order-box-info {
  color: #999;
}
</style>
