import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

import pages from "./pages";
import indexHome from "./view";
import house from "./view/house";
import system from "./view/system";
import home from "./view/home";
import level from "./view/level";
import balance from "./view/balance";
import deposit from "./view/deposit";
import integral from "./view/integral";
import coupon from "./view/coupon";
import car from "./view/car";
import region from "./view/region";
import log from "./view/log";
import setting from "./view/setting";
import scan from "./view/scan";
import partner from "./view/partner";
import order from "./view/order";
import user from './view/user'
import worker from './view/worker'
import fault from './view/fault'
import sale from './view/sale'
import data from './view/data'
import finance from './view/finance'
import message from './view/message'
import statistics from './view/statistics'
// message
// finance

// 首页
import index from "@/pages/index/index";

// view 内容

 const router = new Router({
  // mode: 'history',//去掉#号、
  mode: "hash", //保留#号、
  routes: [
    ...pages,
    {
      path: "/",
      component: index,
      children: [

        ...indexHome,
        ...house,
        ...home,
        ...level,
        ...user,
        ...balance,
        ...fault,
        ...sale,
        ...deposit,
        ...integral,
        ...coupon,
        ...car,
        ...region,
        ...statistics,//区域对账
        ...worker,
        ...partner,
        ...order,
        ...scan, // 扫码开车白名单管理
        ...finance,//财务报表白名单管理
        ...message, //消息中心
        ...setting, // 其他设置管理
        ...log, // 系统日志
        // ------------------------------------------------------ 系统管理
        ...system,
      ],
    },
    ...data,
  ],
 });
// 设置全局前置守卫
router.beforeEach((to, from, next) => {
  const tableTabsData = sessionStorage.getItem('store')
  console.log('to',to)
  console.log('from', from)
  // localStorage.setItem('toPath',to.path)
  if (from.path !== '/') {
    const data = tableTabsData ? JSON.parse(tableTabsData) : {};
  const tableTabs = data.index.tableTabs || []
  const curTabs = tableTabs.find((item) => item.url === to.path);
  if (curTabs) {
    localStorage.setItem('toPath',curTabs.path)
  } else {
    localStorage.setItem('toPath',from.path)
  }

  // 可以在这里进行路由守卫的逻辑处理
  // 例如：身份验证、权限校验、加载数据等
 
  // 确保要调用next()来resolve这个钩子
}

  next();
});

 
export default router