const house = [
    {
        path: '/balance/rule',
        name: 'balanceRule',
        component: () => import('@/view/balance/rule'),
    },
    {
        path: '/balance/record',
        name: 'balanceRecord',
        component: () => import('@/view/balance/record'),
    }
]
export default house