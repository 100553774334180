<template>
    <el-container class="html">
        <el-aside class="el-aside" :width="width" :class="isCollapse?'el-aside-old':'el-aside-new'" >
            <div class="logo">{{title}}</div>
            <Menu></Menu>
        </el-aside>
        <el-container class="container">
            <el-header height="100px" class="header">
                <Header :isCollapse="isCollapse"></Header>
                <IndexNav></IndexNav>
            </el-header>
            <el-main id="id">
                <router-view/>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import Menu from '@/pages/menu/menu.vue';
    import Header from '@/pages/header/header.vue';
    import IndexNav from '@/pages/indexNav/indexNav.vue';
    export default {
        name: "index",
        components: {
            Menu,
            Header,
            IndexNav
        },
        data(){
            return{
                width: '240px',
                isCollapse: false,  //导航菜单展开，收缩
                routerViewName: "",
                title: this.$title
            }
        },
        watch:{
            isCollapse(valueNew){
                // console.log(valueNew)
                let that = this;
                if(valueNew){
                    that.width = '64px';
                    that.title = '';
                }else {
                    that.width = '240px';
                    setTimeout(()=>{
                        that.title = this.$title;

                    },200)
                }
            }
        },
        mounted () {
          this.$root.eventHub.$on('changeDate', this.changeDate)
        },
        methods:{
            changeDate(){
                this.isCollapse = !this.isCollapse
            }
        }
    }
</script>

<style scoped lang="scss">
    .html{
        height: 100vh;
        overflow-y: hidden;
    }
    .logo{
        height: 60px;
        font-size: 20px;
        color: hsla(0,0%,100%,.8);
        line-height: 60px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
        text-align: center;
        background-color: #20222a;
    }
    .el-aside{
        overflow: hidden;
    }
    .container{
        background-color: #f0f2f5;
    }
    .header{
        background-color: #ffffff;
    }
    .el-main{
        padding: 10px !important;
    }
    .el-main::-webkit-scrollbar {display: none;}
</style>
