<template>
  <div>
    <v-chart
      class="chart"
      :option={...yuanbing,series:OrderChart.orderData}
    />
  </div>
</template>

<script>
import { yuanbing } from "./orderEchart";

export default {
  props:{
    OrderChart:Object
  },
  data() {
    return {
      yuanbing,
    };
  },
};
</script>

<style>
.chart {
  width: 100%;
  height: 250px;
}
</style>
