<template>
  <div class="main-content">
    <h3 class="marginBottom20">积分设置</h3>
    <div style="text-align:right">
      <el-button
        type="primary"
        @click="handleSave"
        style="margin-bottom:20px"
        :loading="loading"
      >{{disabled?'编辑':'保存'}}</el-button>
    </div>

    <el-card style="margin-top:20px">
      <template #header>
        <div class="flex align-items justify-content-space-between">
          <h4>消费积分设置</h4>
        </div>
      </template>

      <el-descriptions
        title=""
        :column="1"
        :colon="false"
      >
        <el-descriptions-item label="用户每消费 ">
          <div class="flex align-items">
            <el-input
              v-model="form.consumeAddPrice"
              placeholder="请输入"
              onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
              @blur="salaryDianChange($event,'consumeAddPrice',999999.99)"
              type="text"
              clearable
              :disabled="disabled"
            />
            <span style="margin: 0 10px;width: 140px;">元，增加</span>
            <el-input
              v-model="form.consumeAddPriceIntegral"
              placeholder="请输入"
              type="text"
              onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
              @blur=" salaryChange($event,'consumeAddPriceIntegral',999999)"
              :disabled="disabled"
            />
            <span style="margin: 0 10px;width: 120px;">积分</span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="">
          <span style="font-size: 14px;color:#f59a23">*消费指微信支付下单订单完成后，和充值余额成功时</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>
import { view, update } from "@/api/integral/setting";
export default {
  name: "index",
  data() {
    return {
      form: {
        id: "",
        consumeAddPrice: "",
        consumeAddPriceIntegral: "",
      },
      loading: false,
      disabled: true,
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  mounted() {
    this.getView();
  },
  methods: {
    async getView() {
      try {
        const { data } = await view();
        // console.log(data)
        this.form = data;
      } catch (e) {
        // console.log('错误',e)
      }
    },
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    handleSave() {
      if (this.disabled) {
        this.disabled = false;
        return;
      }

      if (!this.form.consumeAddPrice) {
        this.$message.warning("请输入用户每消费多少元");
        return;
      }

      if (this.form.consumeAddPriceIntegral === '') {
        this.$message.warning("请输入增加多少积分");
        return;
      }

      if (this.loading) return;
      this.loading = true;
      update(this.form)
        .then((res) => {
          this.loading = false;
          this.$message.success("保存成功");
          this.disabled = true;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
/deep/.el-descriptions-item .el-descriptions-item__container {
  align-items: center;
}
</style>