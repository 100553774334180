<template>
  <el-dialog
    title="选择消息模板"
    :visible.sync="show"
    width="60%"
    top="5vh"
    :before-close="handleClose"
  >
    <RegionList
      @change="handleChange"
      :defaultData="defaultData"
      :show="show"
    />
  </el-dialog>
</template>

<script>
import RegionList from "./regionList";
export default {
  name: "index",
  components: {
    RegionList,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    defaultData: {
      type: Array,
      // default: () => {},
    },
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleChange(e) {
      this.$emit("change", e);
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
