<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >

    </avue-crud>

  </div>
</template>

<script>
import { listUser } from "@/api/region/manage";
export default {
  name: "index",
  data() {
    return {
      searchData: {},
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "区域白名单",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "用户昵称",
            prop: "username",
          },
          {
            label: "联系电话",
            prop: "phone",
          },
          {
            label: "排序",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state == 1 ? "启用" : row.state == 2 ? "禁用" : "";
            },
          },
        ],
      },
    };
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      listUser({
        id: this.$route.query.id,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>