const statistics = [
  {
    /**分账员工管理 */
    path: '/statistics/staff',
    name: 'statisticsStaff',
    component: () => import('@/view/statistics/staff')
    // icon: 'el-icon-user'
  },
  {
    /*分账公司管理 */
    path: '/statistics/companyManagement',
    name: 'statisticsCompanyManagement',
    component: () => import('@/view/statistics/companyManagement')
    // icon: 'el-icon-s-platform'
  },
  {
    /*手机号白名单 */
    path: '/statistics/companyManagement/mobileWhite',
    name: 'statisticsCompanyManagementMobileWhite',
    component: () => import('@/view/statistics/companyManagement/mobileWhite')
  },
  {
    /*分账管理-平台 */
    path: '/statistics/accountsPlatform',
    name: 'statisticsAccountsPlatform',
    component: () => import('@/view/statistics/accountsPlatform')
    // icon:'el-icon-picture-outline-round'
  },
  {
    /*分账对象明细-平台 */
    path: '/statistics/accountsDetailPlatform',
    name: 'statisticsAccountsDetailPlatform',
    component: () => import('@/view/statistics/accountsDetailPlatform')
    // icon: 'el-icon-help'
  },
  {
    /*分账明细-区域 */
    path: '/statistics/accountsRegion',
    name: 'statisticsAccountsRegion',
    component: () => import('@/view/statistics/accountsRegion')
    // icon:'el-icon-picture-outline-round'
  },
  {
    /*分账对象明细-区域 */
    path: '/statistics/accountsDetailRegion',
    name: 'statisticsAccountsDetailRegion',
    component: () => import('@/view/statistics/accountsDetailRegion')
    // icon: 'el-icon-help'
  }
]
export default statistics
