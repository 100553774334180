<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div
            class="flex  justify-content-space-between align-items marginBottom20"
          >
            <div class="flex align-items">
              <div class="flex align-items marginRight10 marginBottom10">
                <span class="span marginRight10"></span>
                <div class="width250">
                  <el-input
                    v-model="searchData.keyword"
                    placeholder="输入姓名/手机号"
                    @keyup.enter.native="handleSearch"
                    clearable
                    maxlength="20"
                  ></el-input>
                </div>
              </div>

              <div class="flex align-items marginRight10 marginBottom10">
                <el-button
                  size="medium"
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
                  >查询</el-button
                >
              </div>

              <div class="flex align-items marginRight10 marginBottom10">
                <el-button
                  size="medium"
                  icon="el-icon-refresh-right"
                  @click="handleReset"
                  >重置</el-button
                >
              </div>
            </div>
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-plus"
              @click="handleAdd"
              >新增员工</el-button
            >
          </div>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <div>
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button
            type="text"
            @click="handleStatus(row)"
            v-show="row.status === 1"
            >禁用</el-button
          >
          <el-button
            type="text"
            @click="handleStatus(row)"
            v-show="row.status !== 1"
            >启用</el-button
          >
        </div>
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? ' 编辑员工' : '新增员工'}`"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleModalClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >
        <template slot="name">
          <el-input
            maxlength="8"
            v-model="form.name"
            placeholder="请输入"
            type="text"
            clearable
          />
        </template>

        <template slot="userId">
          <el-select v-model="form.userId" filterable placeholder="请选择">
            <el-option
              v-for="item in mobileList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>

        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCompanyUserPage,
  getInfoUsers,
  addCompanyUser,
  editCompanyUser,
  changeCompanyUserStatus
} from '@/api/statistics/index'

export default {
  name: 'index',
  data() {
    return {
      searchData: {},
      mobileList: [],
      page: {
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '手机号白名单',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: '序号', // 索引文字
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '姓名',
            prop: 'name'
          },
          {
            label: '手机号',
            prop: 'phone'
          },

          {
            label: '创建时间',
            prop: 'createTime'
          },
          {
            label: '状态',
            prop: 'status',
            formatter: function(row, column, cellValue) {
              return row.status === 1 ? '正常' : '禁用'
            }
          }
        ]
      },
      /**查看modal框 */
      dialogVisible: false,

      form: {
        id: '',
        name: '',
        userId: ''
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: '140',
        column: [
          {
            label: '姓名',
            prop: 'name',
            type: 'text',
            span: 24,
            rules: [
              {
                required: true,
                message: '请输入姓名',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '手机号',
            prop: 'userId',
            span: 24,
            rules: [
              {
                required: true,
                message: '请选择',
                trigger: 'blur'
              }
            ]
          }
        ]
      }
    }
  },
  created() {
    this.getInfoUsersData()

  },
  methods: {
    /**禁用启用 */
    handleStatus(row) {
      const state = row.status == 1 ? '禁用' : '启用'
      this.$confirm(`确定${state}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeCompanyUserStatus({
          id: row.id,
          status: row.status == 1 ? 0 : 1
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success(`${state}成功!`)
            this.onLoad()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    /**新增员工 */
    handleAdd() {
      this.dialogVisible = true
      this.resetForm()
    },
    onLoad() {
      this.showLoading = true
      getCompanyUserPage({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then((res) => {
        this.showLoading = false
        if (res.code === 200) {
          this.tableData = res.data.records
          this.page.total = res.data.total
        }
      })
    },
    // 添加
    handleView() {
      // console.log('添加')
      this.dialogVisible = true
    },
    // 编辑
    async handleEdit(row) {
      const { id, name, userId } = row
      this.form = {
        id,
        userId,
        name
      }
      this.dialogVisible = true
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1
      this.onLoad()
    },
    // 重置
    handleReset() {
      this.searchData = {
        type: ''
      }
      this.page.currentPage = 1
      this.onLoad()
    },
    /**查看关闭modal框 */
    handleModalClose() {
      this.dialogVisible = false
    },

    handleClose() {
      this.dialogVisible = false
    },
    async submit(form, done) {
      try {
        let result
        const  companyId =  this.$route.query.companyId
        if (form.id) {
          result = await editCompanyUser({ ...this.form,companyId })
        } else {
          result = await addCompanyUser({ ...this.form,companyId })
        }
        if (result.code === 200) {
          this.$message.success('操作成功')
          this.dialogVisible = false
          this.onLoad()
        } else {
          this.$message.error(result.msg)
        }
        done()
      } catch (error) {
        done()
      }
    },
    /**获取用户手机号 */
    async getInfoUsersData() {
      const data = await getInfoUsers({ page: 1, pageSize: 10 })
      this.mobileList = data.data.map((item) => {
        return {
          value: item.id,
          label: item.nickname + ' | ' + item.phone,
          userPhone: item.phone
        }
      })
    },
    resetForm() {
      this.form = {
        id: '',
        userId: '',
        name: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
