<template>
  <div>
    <v-chart
      class="chart"
      :option={...zhuzhuang,series:eZhuZhuang}
    />
  </div>
</template>

<script>
import { zhuzhuang } from "./echart";

export default {
  props:{
    eZhuZhuang:Array
  },
  data() {
    return {
      zhuzhuang,
    };
  },
};
</script>

<style>
.chart {
  width: 100%;
  height: 300px;
}
</style>
