import { get, post, put, Delete } from "@/api/axios";

// /web/house/label/list get
export const list = params => get('/web/fault/manage/list', params);

// /web/house/label/update put
export const updateSuggest = params => put('/web/fault/manage/suggest', params);

// /web/house/label/view/{id} get
export const view = params => get('/web/fault/manage/view/' + params.id, '');

// /web/house/label/update put
export const suggestExamine = params => put('/web/fault/manage/suggest/examine', params);

// /web/house/label/update put
export const examine = params => put('/web/fault/manage/examine', params);