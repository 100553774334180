var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("套餐设置")]),_c('avue-form',{ref:"form",attrs:{"option":_vm.formOption},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('template',{slot:"carTypes"},[_c('div',{staticClass:"main-content"},[_c('avue-crud',{attrs:{"data":_vm.dataFour,"option":_vm.option,"table-loading":_vm.showLoading},scopedSlots:_vm._u([{key:"illegalStop",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.illegalStop)+" ")]}},{key:"packageNameOne",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageNameOne)+" ")]}},{key:"packageDurationOne",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageDurationOne)+" ")]}},{key:"packagePriceOne",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packagePriceOne)+" ")]}},{key:"packageMinAvailablePowerOne",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageMinAvailablePowerOne)+" ")]}},{key:"packageUseTimeOne",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex justify-content-center "},[_c('div',{staticClass:"width100"},[_vm._v(" "+_vm._s(row.packageUseTimeOneStart)+" - "+_vm._s(row.packageUseTimeOneEnd)+" ")])])]}},{key:"packageNameTwo",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageNameTwo)+" ")]}},{key:"packageDurationTwo",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageDurationTwo)+" ")]}},{key:"packagePriceTwo",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packagePriceTwo)+" ")]}},{key:"packageMinAvailablePowerTwo",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageMinAvailablePowerTwo)+" ")]}},{key:"packageUseTimeTwo",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex justify-content-center"},[_c('div',{staticClass:"width100"},[_vm._v(" "+_vm._s(row.packageUseTimeTwoStart)+" - "+_vm._s(row.packageUseTimeTwoEnd)+" ")])])]}},{key:"packageNameThree",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageNameThree)+" ")]}},{key:"packageDurationThree",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageDurationThree)+" ")]}},{key:"packagePriceThree",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packagePriceThree)+" ")]}},{key:"packageMinAvailablePowerThree",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageMinAvailablePowerThree)+" ")]}},{key:"packageUseTimeThree",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex justify-content-center"},[_c('div',{staticClass:"width100"},[_vm._v(" "+_vm._s(row.packageUseTimeThreeStart)+" - "+_vm._s(row.packageUseTimeThreeEnd)+" ")])])]}},{key:"packageNameFour",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageNameFour)+" ")]}},{key:"packageDurationFour",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageDurationFour)+" ")]}},{key:"packagePriceFour",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packagePriceFour)+" ")]}},{key:"packageMinAvailablePowerFour",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageMinAvailablePowerFour)+" ")]}},{key:"packageUseTimeFour",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex justify-content-center"},[_c('div',{staticClass:"width100"},[_vm._v(" "+_vm._s(row.packageUseTimeFourStart)+" - "+_vm._s(row.packageUseTimeFourEnd)+" ")])])]}},{key:"packageNameFive",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageNameFive)+" ")]}},{key:"packageDurationFive",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageDurationFive)+" ")]}},{key:"packagePriceFive",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packagePriceFive)+" ")]}},{key:"packageMinAvailablePowerFive",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.packageMinAvailablePowerFive)+" ")]}},{key:"packageUseTimeFive",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex justify-content-center"},[_c('div',{staticClass:"width100"},[_vm._v(" "+_vm._s(row.packageUseTimeFiveStart)+" - "+_vm._s(row.packageUseTimeFiveEnd)+" ")])])]}}])})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }