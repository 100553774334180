<template>
  <div>
    <h3>车辆管理</h3>

    <avue-crud
      :data="dataFive"
      :option="option"
      :table-loading="showLoading"
    >

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          @click="handleDetails(row)"
        >查看详情</el-button>
      </template>
    </avue-crud>

  </div>

</template>

<script>
export default {
  name: "index",
  props: {
    dataFour: {
      type: Object,
      default: () => {},
    },
    dataFive: {
      type: Array,
    },
  },
  data() {
    return {
      showLoading: false,
      option: {
        width: 3000,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: false, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "车型名称",
            prop: "typeName",
          },
          {
            label: "车型编号",
            prop: "code",
          },
          {
            label: "车辆二维码",
            prop: "image",
            type: "img",
          },
        ],
      },
    };
  },
  methods: {
    onDataFourHandle() {
      const data = {
        dataFour: this.dataFour,
        dataFive: this.dataFive,
      };

      this.$emit("onDataFourHandle", data);
    },
    // 查看详情
    handleDetails(row) {
      this.$router.push({
        path: "/car/manage/details",
        query: {
          type: "details",
          id: row.id,
        },
      });
    },
  },
};
</script>

<style scoped>
</style>