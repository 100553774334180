const house = [
    {
        path: '/sale/manage',
        name: 'saleManage',
        component: () => import('@/view/sale/manage'),
    },
    {
        path: '/sale/manage/details',
        name: 'saleManageDetails',
        component: () => import('@/view/sale/manage/details'),
    },
    {
        path: '/sale/manage/ideaHandle',
        name: 'saleManageIdeaHandle',
        component: () => import('@/view/sale/manage/ideaHandle'),
    },
]
export default house