import { get, post, put, Delete } from "@/api/axios";

// /web/house/label/list get
export const list = params => get('/web/coupon/relate/list', params);

// /web/house/label/add post
export const add = params => post('/web/coupon/relate/add', params);

// /web/house/label/update put
export const update = params => put('/web/coupon/relate/update', params);

// /web/house/label/delete/{id} delete
export const deleteNew = params => Delete('/web/coupon/relate/delete/' + params.id, '');

// /web/house/label/view/{id} get
export const view = params => get('/web/coupon/relate/view/' + params.id, '');

// /web/house/label/state/update put
export const updateNew = params => put('/web/coupon/relate/state/update', params);

//选择优惠劵列表
export const getCouponList = params => get('/web/coupon/relate/coupon/list', params)