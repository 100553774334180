<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
      @selection-change="selectionChange"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 ">
              <span class="span marginRight10">时间 : </span>
              <div class="width250">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="统计开始日期"
                  end-placeholder="统计结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>
            </div>
            <div class="flex align-items marginRight10 ">
              <span class="span marginRight10">区域 : </span>
              <div class="width150">
                <el-select
                  v-model="searchData.regionId"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in regionList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">订单编号 : </span>
              <div class="width250">
                <el-input
                  v-model="searchData.orderCode"
                  placeholder="请输入订单编号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">分账状态 : </span>
              <div class="width150">
                <el-select
                  v-model="searchData.state"
                  clearable
                  @change="handleSearch"
                >
                  <!-- <el-option value="" label="请选择"></el-option> -->
                  <el-option :value="0" label="待分账"></el-option>
                  <el-option :value="2" label="可分账"></el-option>
                  <el-option :value="1" label="已分账"></el-option>
                  <el-option :value="3" label="已关闭"></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                >重置</el-button
              >
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-download"
                @click="handleBatch"
                >批量分账</el-button
              >
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-download"
                @click="handleExport"
                >导出</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10"></div>
          </div>
          <!-- <div class="marginBottom20">
            <avue-data-display :option="dataOption"></avue-data-display>
          </div> -->
          <div class="order-box">
            <div class="order-box-list">
              <h3 class="order-box-title">
                {{ summaryData.orderAmount || 0 }}
              </h3>
              <div class="order-box-info">订单金额（元）</div>
            </div>
            <div class="order-box-list">
              <h3 class="order-box-title">
                {{ summaryData.couponAmount || 0 }}
              </h3>
              <div class="order-box-info">优惠券扣减（元）</div>
            </div>
            <div class="order-box-list">
              <h3 class="order-box-title">
                {{ summaryData.memberAmount || 0 }}
              </h3>
              <div class="order-box-info">会员等级扣减（元）</div>
            </div>
            <div class="order-box-list">
              <h3 class="order-box-title">
                {{ summaryData.refundAmount || 0 }}
              </h3>
              <div class="order-box-info">退款金额（元）</div>
            </div>
            <div class="order-box-list">
              <h3 class="order-box-title">{{ summaryData.payAmount || 0 }}</h3>
              <div class="order-box-info">实付金额（元）</div>
            </div>
            <div class="order-box-list">
              <h3 class="order-box-title">
                {{ summaryData.financeAmount || 0 }}
              </h3>
              <div class="order-box-info">分账总额（元）</div>
            </div>
          </div>
        </div>
      </template>
      <template slot="detail" slot-scope="{ row }">
        <el-button type="text" @click="handleView(row)">查看</el-button>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button
          v-if="row.financeStatus === 2"
          type="text"
          @click="handleDialog(row)"
          >确认分账</el-button
        >
        <div v-else>-</div>
      </template>
    </avue-crud>

    <el-dialog
      v-show="dialogVisible"
      title="分账"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleModalClose"
    >
      <avue-crud
        :data="dialogTableData"
        :option="dialogOption"
        :table-loading="dialogShowLoading"
      >
        <template #header>
          <div v-if="!view">
            是否确认分账？
          </div>
          <div>订单编号：{{ curRow.orderCode }}</div>
          <div>区域：{{ curRow.regionName }}</div>
          <div class="marginBottom20">分账金额：{{ curRow.financeAmount }}</div>
        </template>
      </avue-crud>
      <div
        class="flex align-items justify-content-center"
        slot="footer"
        v-if="!view"
      >
        <el-button icon="el-icon-close" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" size="medium" @click="handleConfirm"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSummary,
  getReportPage,
  reportExport,
  viewDetail,
  separateAccounts
} from '@/api/statistics/index'
import { list } from '@/api/region/manage'
import dayjs from 'dayjs'
import qs from 'qs'
export default {
  name: 'index',
  data() {
    return {
      selectionData: [],
      searchData: {},
      regionList: [],
      summaryData: {},
      page: {
        total: 0,
        currentPage: 1
      },
      view: false,
      tableData: [],
      curRow: {},
      showLoading: false,
      option: {
        title: '分账明细-平台',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: '序号', // 索引文字
        size: 'small',
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        menuWidth: 150,
        selection: true,
        selectable: (row, index) => {
          return row.financeStatus === 2
        },
        column: [
          {
            label: '订单编号',
            prop: 'orderCode',
            type: 'string'
          },
          {
            label: '区域名称',
            prop: 'regionName'
          },
          {
            label: '车辆类型',
            prop: 'carTypeName'
          },
          {
            label: '车辆编号',
            prop: 'carCode'
          },

          {
            label: '订单金额（元）',
            prop: 'totalPrice'
          },
          {
            label: '优惠券扣减（元）',
            prop: 'couponPrice'
          },
          {
            label: '会员等级扣减（元）',
            prop: 'memberPrice'
          },
          {
            label: '退款金额（元）',
            prop: 'refundPrice'
          },
          {
            label: '实付金额（元）',
            prop: 'payPrice'
          },
          {
            label: '分账金额（元）',
            prop: 'financeAmount'
          },
          {
            label: '分账明细',
            prop: 'detail'
          },
          {
            label: '分账状态',
            prop: 'state',
            formatter: function(row, column) {
              const financeStatus = row.financeStatus
              if (financeStatus === -1) {
                return '全部'
              } else if (financeStatus === 0) {
                return '待分账'
              } else if (financeStatus === 1) {
                return '已分账'
              } else if (financeStatus === 2) {
                return '可分账'
              } else if (financeStatus === 3) {
                return '已关闭'
              }
            }
          }
        ]
      },

      /**查看modal框 */
      dialogVisible: false,
      dialogTableData: [],
      dialogOption: {
        title: '',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        // indexLabel: '分账对象', // 索引文字
        size: 'small',
        selection: false, // 显示多选框
        page: false, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: '分账对象',
            prop: 'name'
          },
          {
            label: '名称',
            prop: 'financeRegionUserName'
          },
          {
            label: '商户号/手机号',
            prop: 'accountOrPhone'
          },
          {
            label: '分账比例额（%）',
            prop: 'proportion'
          },
          {
            label: '分账金额',
            prop: 'proportionAmount'
          }
        ]
      },
      dialogShowLoading: false
    }
  },
  created() {
    this.getRegionList()
  },
  methods: {
    async getRegionList() {
      const data = await list({ page: -1, size: -1 })
      this.regionList = data.data.records.map((item) => {
        return { value: item.id, label: item.name }
      })
    },
    async onLoad() {
      this.showLoading = true
      const paramsSearchData = {
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }
      if (this.searchData.createTime) {
        paramsSearchData.startDate = dayjs(
          this.searchData.createTime[0]
        ).format('YYYY-MM-DD 00:00:00')
        paramsSearchData.endDate = dayjs(this.searchData.createTime[1]).format(
          'YYYY-MM-DD 23:59:59'
        )
      }
      const { data = {} } = await getSummary({ ...paramsSearchData })
      this.summaryData = data
      getReportPage({
        ...paramsSearchData
      }).then((res) => {
        this.showLoading = false
        if (res.code === 200) {
          this.tableData = res.data.records
          this.page.total = res.data.total
        }
      })
    },
    // 确认分账
    handleDialog(row) {
      viewDetail(row.orderCode).then((res) => {
        this.dialogTableData = res.data
        this.curRow = row
        this.dialogVisible = true
      })
    },
    // 查看
    handleView(row) {
      // console.log('添加')
      this.curRow = row
      viewDetail(row.orderCode).then((res) => {
        this.dialogTableData = res.data
        this.view = true
        this.dialogVisible = true
      })
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1
      this.onLoad()
    },
    // 重置
    handleReset() {
      // console.log('重置')

      this.searchData = {
        type: ''
      }
      this.page.currentPage = 1
      this.onLoad()
    },
    /**查看关闭modal框 */
    handleModalClose() {
      this.dialogVisible = false
      this.view = false
    },
    handleExport() {
      const { regionId, orderCode, state } = this.searchData
      const searchData = {
        page: this.page.currentPage,
        size: this.page.pageSize,
        regionId,
        orderCode,
        state
        // carCode: this.searchData.carCode,
        // regionName: this.searchData.regionName
      }
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format('YYYY-MM-DD 00:00:00')
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          'YYYY-MM-DD 23:59:59'
        )
      }
      // reportExport({ ...searchData })

      window.open(
        `/api/web/finance/report/export?${qs.stringify(searchData)}`,
        '_self'
      )
    },
    selectionChange(selection) {
      this.selectionData = selection
    },
    handleBatch() {
      if (this.selectionData.length) {
        // this.dialogVisible = true
        this.$confirm('确定批量分账吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleConfirm()
        })
      } else {
        this.$message({
          message: '请选择批量操作的数据',
          type: 'warning'
        })
      }
    },
    async handleConfirm() {
      let orderNos = []
      if (this.dialogVisible) {
        orderNos = [this.curRow.orderCode]
      } else {
        orderNos = this.selectionData.map((item) => {
          return item.orderCode
        })
        console.log('1qqewqeqwr', this.selectionData)
      }
      // const paramsList = this.curRow?[t]:[]
      await separateAccounts({ orderNos })
      if (this.dialogVisible) {
        this.dialogVisible = false
        this.view = false
      } else {
        this.selectionData = []
      }
      this.$message({
        message: '操作成功',
        type: 'success'
      })
      this.onLoad()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 0px;
}
.order-box {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  background: #f6f6f6;
  padding: 20px;
  margin-bottom: 20px;
}
.order-box-list {
  width: 16.5%;
  text-align: center;
}
.order-box-title {
  font-size: 45px;
  font-weight: 400;
  height: 70px;
}
.order-box-info {
  color: #999;
}
</style>
