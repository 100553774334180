<template>
	<div>
		<div class="width_100 flex justify-content-space-between ">
			<Logo class="logo-left " />
			<!-- <el-button class="btn" size="small" @click="toggleFullScreen">{{ isFullScreen }}</el-button> -->
			<MapTitle class="map-title" />
			<img src="../../../../../style/image/open.png" alt="logo" @click="toggleFullScreen" class="open">
			<!-- <el-button class="btn" size="small" @click="toggleFullScreen">{{ isFullScreen }}</el-button> -->
		</div>
		<dv-decoration-4 class="margin20" :reverse="true" :color="['#70FFF9', '#39A396']"
			style="width:100%;height:5px;" />

	</div>
</template>

<script>
	import Logo from "./logo.vue";
	import MapTitle from "./map-title.vue";
	export default {
		components: {
			Logo,
			MapTitle,
		},
		data() {
			return {
				isFullScreen: '开启全屏',
			}
		},
		methods: {
			toggleFullScreen() {
				// const elem = document.documentElement; // 获取整个文档的元素
				if (document.fullscreenElement) {
					// 全屏状态，退出全屏
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.mozCancelFullScreen) { // Firefox
						document.mozCancelFullScreen();
					} else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
						document.webkitExitFullscreen();
					} else if (document.msExitFullscreen) { // IE/Edge
						document.msExitFullscreen();
					}
					this.isFullScreen = '开启全屏'
				} else {
					// 非全屏状态，进入全屏
					const element = document.documentElement; //获取整个文档的元素
					if (element.requestFullscreen) {
						element.requestFullscreen();
					} else if (element.mozRequestFullScreen) { // Firefox
						element.mozRequestFullScreen();
					} else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
						element.webkitRequestFullscreen();
					} else if (element.msRequestFullscreen) { // IE/Edge
						element.msRequestFullscreen();
					}
					this.isFullScreen = '关闭全屏'
				}
				// if (elem.requestFullscreen) {
				//   elem.requestFullscreen(); // 兼容性处理：进入全屏模式
				// } else if (elem.mozRequestFullScreen) {
				//   elem.mozRequestFullScreen();
				// } else if (elem.webkitRequestFullscreen) {
				//   elem.webkitRequestFullscreen();
				// } else if (elem.msRequestFullscreen) {
				//   elem.msRequestFullscreen();
				// }
			},
		},
	};
</script>

<style scoped>
	.logo-left {
		width: 200px;
	}

	.map-title {
		width: calc(100% - 220px);
		text-align: center;
		font-size: 48px;
		color: #fff;
	}

	.btn {
		margin-left: 15px;
		height: 60px !important;
		line-height: 30px !important;
		padding: 4px 10px !important;
		background: rgba(255, 255, 255, 1) !important;
	}
	.open{
		width: 40px !important;
		height: 40px !important;
	}
</style>