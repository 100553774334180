import { get, post, put, Delete } from '@/api/axios'

/**分账员工管理-分页 */
export const userPage = (params) => get('/web/finance/user/page', params)

/**分账关系-下拉类别 */
export const getInfoRoles = (params) => get('/web/finance/info/roles', params)

/** 下拉手机号*/
export const getInfoUsers = (params) => get('/web/finance/info/users', params)

/**分账员工管理-创建 */
export const addUser = (params) => post('/web/finance/user', params)

/**分账员工管理-状态 */
export const changeUserStatus = (params) =>
  put('/web/finance/user/status', params)

/**分账员工管理-修改 */
export const editUser = (params) => put('/web/finance/user', params)

/**分账员工管理-分页 */
export const getCompany = (params) => get('/web/finance/company/page', params)

/**分账公司管理-创建  */
export const addCompany = (params) => post('/web/finance/company', params)

/** 分账公司管理-修改 */
export const editCompany = (params) => put('/web/finance/company', params)

/**分账公司管理-状态 */
export const changeCompanyStatus = (params) =>
  put('/web/finance/company/status', params)

/** 手机号白名单-分页 */
export const getCompanyUserPage = (params) =>
  get('/web/finance/company/user/page', params)

/** 手机号白名单-创建  */
export const addCompanyUser = (params) =>
  post('/web/finance/company/user', params)

/**手机号白名单-修改 */
export const editCompanyUser = (params) =>
  put('/web/finance/company/user', params)

/**手机号白名单-状态 */
export const changeCompanyUserStatus = (params) =>
  put('/web/finance/company/user/status', params)

/**后台-分账报表汇总 区域 */
export const getSummary = (params) =>
  post('/web/finance/report/order/summary', params)

/**后台-分账报表分页*/
export const getReportPage = (params) =>
  post('/web/finance/report/order/page', params)

/**后台-分账报表导出 */
export const reportExport = (params) =>
  post('/web/finance/report/export', params)

/**后台-分账报表导出 */
export const reportDetailExport = (params) =>
  post('/web/finance/report/order/export', params)

/**分账对象明细-区域-分账对象明细-平台-汇总 */
export const getDetailSummary = (params) =>
  post('/web/finance/report/summary', params)

/**后台-分账报表分页 明细*/
export const getDetailReportPage = (params) =>
  post('/web/finance/report/page', params)

/**分账明细-明细 */
export const viewDetail = (params) => 
  get('/web/finance/report/order/detail/' + params,)

/**分账 */
export const separateAccounts =  (params) => 
  put('/web/finance/report/order/finance' ,params)