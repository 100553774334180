import { get, put, } from "@/api/axios";

// 获取banner列表
export const list = params => get('/web/deposit/apply/list', params);

// 扣除押金
export const deductionMoneyHandle = params => put('/web/deposit/setting/deduct', params);

// 是否同意退还押金申请
export const isAgreestHandle = params => get('/web/deposit/setting/deduct/' + params.id, '');


