<template>
  <div class="main-content">
    <h3 class="marginBottom20">会员详情</h3>
    <div class="flex justify-content-space-between">
      <el-button @click="goBack()">返回</el-button>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane
        label="基础信息"
        name="createOne"
      >
        <createOne
          :id="id"
          v-if="activeName==='createOne'"
        />
      </el-tab-pane>
      <el-tab-pane
        label="订单明细"
        name="createTwo"
      >
        <createTwo
          :id="id"
          v-if="activeName==='createTwo'"
        />

      </el-tab-pane>
      <el-tab-pane
        label="优惠劵明细"
        name="createThree"
      >
        <createThree
          :id="id"
          v-if="activeName==='createThree'"
        />
      </el-tab-pane>
      <el-tab-pane
        label="余额充值记录"
        name="createFour"
      >
        <createFour
          :id="id"
          v-if="activeName==='createFour'"
        />

      </el-tab-pane>
      <el-tab-pane
        label="积分明细"
        name="createFive"
      >
        <createFive
          :id="id"
          v-if="activeName==='createFive'"
        />

      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import createOne from "./components/createOne/index.vue";
import createTwo from "./components/createTwo/index.vue";
import createThree from "./components/createThree/index.vue";
import createFour from "./components/createFour/index.vue";
import createFive from "./components/createFive/index.vue";

export default {
  name: "info",
  components: {
    createOne,
    createTwo,
    createThree,
    createFour,
    createFive,
  },
  data() {
    return {
      activeName: "createOne",
      id: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
</style>