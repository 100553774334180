import { get, post, put, Delete } from "@/api/axios";

// 系统日志-列表
export const list = (params) => get("/web/log/manage/list", params);

// 系统日志-删除
export const deleteNew = (params) => post("/web/log/manage/delete", params);

// 系统日志-查看
export const view = (params) => get("/web/log/manage/view/" + params.id, "");
