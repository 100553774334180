<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <div class="width250">
            <el-input
              v-model="searchData.name"
              placeholder="输入优惠劵名称"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="20"
            ></el-input>
          </div>
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            class="marginLeft10"
            >查询</el-button
          >
          <el-button
            size="medium"
            type="info"
            icon="el-icon-refresh-right"
            @click="handleReset"
            class="marginLeft10"
            >重置</el-button
          >

          <el-button
            size="medium"
            type="primary"
            icon="el-icon-plus"
            @click="handleCreate"
            class="marginLeft10"
            >选择优惠券并发放</el-button
          >
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-view"
          @click="handleDetails(row)"
          >发放详情</el-button
        >
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible0"
      :title="`${form.id ? '编辑' : '新增'}`"
      :visible.sync="dialogVisible0"
      width="40%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >
        <template slot="couponId">
          <el-select v-model="form.couponId" placeholder="请选择优惠劵">
            <el-option
              v-for="item in couponList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>

        <template slot="giveNum">
          <el-input
            v-model="form.giveNum"
            placeholder="请输入发放数量"
            onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
            @blur="salaryChange($event, 'giveNum', 999)"
            type="text"
            clearable
          />
        </template>

        <template slot="sendCrowd">
          <div class="flex align-items marginRight10 marginBottom10">
            <div class="marginRight10 marginBottom10">
              <el-button
                size="medium"
                style="
                  background: #f59a23;
                  color: #ffffff;
                  border-color: #f59a23;
                "
                icon="el-icon-upload2"
                @click="handleImport"
                >导入用户手机号</el-button
              >
            </div>
            <div class="marginRight10 marginBottom10">
              <el-button
                size="medium"
                style="
                  background: #c280ff;
                  color: #ffffff;
                  border-color: #c280ff;
                "
                icon="el-icon-download"
                @click="handleDownload"
                >下载导入模板</el-button
              >
            </div>
            <div class="marginRight10 marginBottom10">
              *通过用户手机号进行发放
            </div>
          </div>
        </template>

        <template slot="phone">
          <div>
            <div v-if="exportPhoneList.effectPhone.length + exportPhoneList.unEffectPhone.length > 0">
              已添加{{
                exportPhoneList.effectPhone.length +
                exportPhoneList.unEffectPhone.length
              }}项目 有效{{ exportPhoneList.effectPhone.length }}条 无效{{
                exportPhoneList.unEffectPhone.length
              }}条
            </div>
            <div v-else>暂无数据</div>
            <div
              :style="
                exportPhoneList.effectPhone.length > 0
                  ? 'border:1px solid #999;padding:10px;'
                  : ''
              "
            >
              <div
                class="flex justify-content-space-between export-list"
                v-for="(item, index) in exportPhoneList.effectPhone"
                :key="index"
              >
                <div>{{ item }}</div>
                <el-button
                  class="export-del"
                  type="text"
                  @click="delExportPhoneList(index)"
                  >删除</el-button
                >
              </div>
              <!-- 导入的错误信息 -->
              <p style="color:red
              " v-if="exportPhoneList.unEffectPhone.length > 0">无效↓</p>
              <div
                class="flex justify-content-space-between export-list"
                v-for="(item1, index1) in exportPhoneList.unEffectPhone"
                :key="index1"
              >
                <div>{{ item1 }}</div>
                <el-button
                  class="export-del"
                  type="text"
                  @click="delExportPhoneList1(index1)"
                  >删除</el-button
                >
              </div>
            </div>
          </div>
        </template>

        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleCloseFile"
    >
      <div class="flex align-items justify-content-center marginBottom20">
        <!--        <el-button type="primary" size="medium" >选择文件</el-button>-->

        <el-upload
          class="upload-demo"
          :drag="true"
          action="/api/web/coupon/send/user/add/template/import"
          :show-file-list="false"
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <!-- <div class="flex align-items justify-content-center">
        <el-button type="text" size="medium" @click="handleDownload"
          >下载文件模板</el-button
        >
      </div> -->
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleOne"
      width="30%"
      :before-close="handleCloseOne"
    >
      <div class="flex align-items justify-content-center marginBottom30">
        <span>导入成功</span>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="primary" size="medium" @click="handleCloseOne"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, list, getCouponList } from "@/api/coupon/send";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        type: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "优惠券发送",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "优惠劵名称",
            prop: "name",
          },
          {
            label: "优惠劵类型",
            prop: "type",
            formatter: function (row, column, cellValue) {
              return row.type === 1
                ? "满减"
                : row.type === 2
                ? "代金"
                : row.type;
            },
          },
          {
            label: "满足条件",
            prop: "useConditionFullPrice",
            formatter: function (row, column, cellValue) {
              return row.type == 1
                ? row.useConditionFullPrice == 0
                  ? "无条件"
                  : `满￥ ${cellValue}可用`
                : row.takePrice;
            },
          },
          {
            label: "使用有效期",
            prop: "validityStartTime",
            formatter: function (row, column, cellValue) {
              console.log("rowffff", row);
              return row.overdueType == 1
                ? `${row.validityStartTime}~${row.validityStartTime}`
                : `领取后${row.comeNum}天生效，生效后${row.validityNum}天失效`;
            },
          },
          {
            label: "发放数量（人均）",
            prop: "giveNum",
          },
          {
            label: "发放时间",
            prop: "createTime",
          },
        ],
      },
      couponList: [],
      dialogVisible: false,
      dialogVisible0: false,
      dialogVisibleOne: false,
      form: {
        id: "",
        type: "",
        name: "",
        phone: [],
        phone1:[]
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "150",
        column: [
          {
            label: "优惠券名称",
            prop: "couponId",
            type: "select",
            dicData: [],
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择优惠券名称",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择优惠券名称"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "发放数量(人均)",
            prop: "giveNum",
            span: 24,
            maxlength: 4,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入发放数量",
                trigger: "blur",
              },
            ],
          },
          {
            label: "发放人群",
            prop: "sendCrowd",
            span: 24,
            maxlength: 4,
            showWordLimit: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入标签名称",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "已添加列表",
            prop: "phone",
            span: 24,
            maxlength: 4,
            showWordLimit: true,
            rules: [
              {
                required: true,
                // message: "请导入用户手机号",
                trigger: "blur",
                validator: (rule, value, callback) => {
                  if (this.exportPhoneList.unEffectPhone.length > 0) {
                    callback(new Error("用户手机号格式有误"));
                  } else if (
                    this.exportPhoneList.effectPhone.length == 0 &&
                    this.exportPhoneList.unEffectPhone.length == 0
                  ) {
                    callback(new Error("请导入用户手机号"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
        ],
      },
      exportPhoneList: {
        effectPhone: [],
        unEffectPhone: [],
      },
    };
  },
  created() {
    this.getCouponList();
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    //删除导入成功的手机号
    delExportPhoneList(index) {
      this.exportPhoneList.effectPhone.splice(index, 1);
      this.exportPhoneList.effectPhone = this.form.phone;
    },
        //删除导入失败的手机号
        delExportPhoneList1(index) {
      this.exportPhoneList.unEffectPhone.splice(index, 1);
      this.exportPhoneList.unEffectPhone = this.form.phone1;
    },
    // 批量导入
    handleImport() {
      console.log("批量导入");
      this.dialogVisible = true;
    },
    beforeUpload(file) {
      // 文件类型
      const isType = file.type === "application/vnd.ms-excel";
      const isTypeComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error("上传文件只能是xls/xlsx格式！");
      } // 文件大小限制为2M
      const fileLimit = file.size / 1024 / 1024 < 20;
      if (!fileLimit) {
        this.$message.error("上传文件大小不超过20M！");
      }
      return fileType && fileLimit;
    },
    handleSuccess(res, file) {
      // console.log(1111111,res, file);
      this.dialogVisible = false;
      if (res.code === 200) {
        console.log("res", res);

        this.exportPhoneList = {
          effectPhone: this.exportPhoneList.effectPhone.concat(
            res.data.effectPhone
          ),
          unEffectPhone: this.exportPhoneList.unEffectPhone.concat(
            res.data.unEffectPhone
          ),
        };

        this.form.phone = this.exportPhoneList.effectPhone;
        this.form.phone1 = this.exportPhoneList.unEffectPhone;

        setTimeout(() => {
          if (res.data.state === 1) {
            this.dialogVisibleOne = true;
          } else if (res.data.state === 2) {
            this.problemId = res.data.id;
            this.dialogVisibleTwo = true;
          } else if (res.data.state === 3) {
            this.problemId = res.data.id;
            this.dialogVisibleThree = true;
          } else {
            this.problemId = res.data.id;
            this.dialogVisibleFour = true;
          }
        }, 500);
      }
    },
    // 下载文件模板
    handleDownload() {
      // console.log('下载文件模板')
      window.open("/api/web/coupon/send/user/add/template/export", "_self");
    },
    handleCloseOne() {
      this.dialogVisibleOne = false;
      this.onLoad();
    },
    handleCloseFile() {
      this.dialogVisible = false;
    },
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
        delete this.form[t]
      }
    },
    // 获取优惠劵列表
    getCouponList() {
      getCouponList().then((res) => {
        if (res.code === 200) {
          this.couponList = res.data;
          console.log(" this.couponList ", this.couponList);
        }
      });
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: {
          ...this.searchData,
        },
      };

      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 添加
    handleCreate() {
      // console.log('添加')
      this.dialogVisible0 = true;
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        type: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 发放详情
    async handleDetails(row) {
      // console.log('编辑',row)
      this.$router.push({
        path: "/coupon/send/details",
        query: {
          id: row.id,
        },
      });
    },

    // 提交
    submit(form, done) {
      // console.log(form)
      add(form)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.dialogVisible0 = false;
            setTimeout(() => {
              this.resetForm();
            }, 500);
            this.onLoad();
          } else {
            this.$message.error(res.msg);
            done();
          }
        })
        .catch(() => done());
    },
    // 关闭
    handleClose() {
      this.dialogVisible0 = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
        name: "",
        phone: [],
      };
      this.exportPhoneList.effectPhone = [];
      this.exportPhoneList.unEffectPhone = [];
    },
  },
};
</script>

<style scoped>
.export-del {
  display: none;
}

.export-list:hover .export-del {
  display: inline-block;
}
</style>