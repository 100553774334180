import { get, post, put, Delete } from "@/api/axios";

// /web/house/label/list get
export const list = params => get('/web/region/manage/list', params);

// /web/house/label/add post
export const add = params => post('/web/region/manage/add', params);

// /web/house/label/update put
export const update = params => put('/web/region/manage/update', params);

// /web/house/label/delete/{id} delete
export const deleteNew = params => Delete('/web/car/manage/delete/' + params.id, '');

// /web/house/label/view/{id} get
export const view = params => get('/web/region/manage/view/' + params.id, '');

//选择车型列表
export const getCarTypeList = params => get('/web/region/manage/car/type/list', params)

//选择车辆列表
export const getCarList = params => get('/web/region/manage/car/list', params)

//添加车辆
export const addCarList = params => post('/web/region/manage/car/add', params);

//冻结车辆
export const stateCarList = params => put('/web/region/manage/view/state/update', params);

//统一启动-统一上锁
export const unifyUpdateCarList = params => put('/web/region/manage/view/unify/update', params);

//删除
export const deleteCarList = params => Delete('/web/region/manage/view/delete/' + params.id, '');

// 区域车辆
export const listCar = params => get('/web/region/manage/list/car', params);

// 区域车辆
export const listUser = params => get('/web/region/manage/list/user', params);

// 冻结区域列表 
export const stateUpdateRegion = params => put('/web/region/manage/state/update', params);

// 区域列表-新增-地址模糊搜索
export const searchAddress = params => get('/web/region/manage/search/address', params);

// 区域管理-地图看板-查看
export const mapView = params => get('/web/region/map/view', params);

// 区域管理-地图看板-修改
export const mapUpdate = params => put('/web/region/map/update', params);

/** 分账区域用户管理-列表 */
export const userSelect = params => get('/web/finance/region/user/select', params);

/** 分账区域用户管理-列表 */
export const getFinanceUserList  =  params => get('/web/finance/region/user/list', params);
