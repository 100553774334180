<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width250">
                <el-input
                  v-model="searchData.name"
                  placeholder="区域编号/区域名称"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">区域状态 : </span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.state"
                  clearable
                  @change="handleSearch"
                >
                  <el-option value="" label="请选择"></el-option>
                  <el-option :value="1" label="正常"></el-option>
                  <el-option :value="2" label="冻结"></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10 marginBottom10"
                >查询</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10 marginBottom10"
                >重置</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-plus"
                @click="handleMap"
                class="marginLeft10 marginBottom10"
                >地图看板全区域图片</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-plus"
                @click="handleCreate"
                class="marginLeft10 marginBottom10"
                >新增区域</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                style="background: #f59a23;color: #ffffff;border-color: #f59a23"
                icon="el-icon-upload2"
                @click="handleImport"
                class="marginLeft10 marginBottom10"
                >导入车辆</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                icon="el-icon-download"
                style="background: #c280ff;color: #ffffff;border-color: #c280ff"
                @click="handleExport"
                class="marginLeft10 marginBottom10"
                >导出</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <!-- 
      <template
        slot="state"
        slot-scope="{ row }"
      >
        <el-switch
          v-if="row.moveState !== 3"
          v-model="row.state"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event,row.id)"
        ></el-switch>
        <span v-else>{{ row.state === 1?'开':row.state === 2?'关':'' }}</span>
      </template> -->

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleDetails(row)"
          >区域详情</el-button
        >
        <el-button type="text" size="mini" @click="handleEdit(row)"
          >区域编辑</el-button
        >
        <el-button type="text" size="mini" @click="handleCarList(row)"
          >区域车辆</el-button
        >
        <el-button type="text" size="mini" @click="handleWhite(row)"
          >区域白名单</el-button
        >
        <el-button
          v-if="row.state == 1"
          style="color: #D9001B"
          type="text"
          size="mini"
          @click="handleState(row)"
          >冻结</el-button
        >
        <el-button
          v-if="row.state == 2"
          type="text"
          size="mini"
          @click="handleState(row)"
          >解除冻结</el-button
        >
      </template>
    </avue-crud>

    <Import
      v-if="importShow"
      :show="importShow"
      :action="importAction"
      :downloadLink="importDownloadLink"
      :problemLink="importProblem"
      @close="importClose"
    />
    <el-dialog
      v-if="dialogVisiblePassword"
      title="地图看板-全区域图片设置"
      :visible.sync="dialogVisiblePassword"
      width="40%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >
        <template slot="mapImage">
          <transition-group class="flex flex-wrap">
            <div
              class="ElImage"
              v-for="(url, index) in form.mapImage"
              :key="`ElImage${index}`"
            >
              <el-image
                class="ElImageItem"
                :src="url"
                :key="`ElImage${index}`"
                fit="contain"
              >
                <div>{{ url }}</div>
              </el-image>
              <div class="ElImageHover">
                <div class="flex">
                  <!-- <i
                      class="el-icon-zoom-in ElIcon"
                      @click.stop="handleView(index)"
                    ></i> -->
                  <i
                    class="el-icon-delete ElIcon"
                    style="margin-left: 15px;"
                    @click.stop="handleDelete(index)"
                  ></i>
                </div>
              </div>
            </div>
            <div
              class="forbid"
              :key="`ElImage${10}`"
              v-if="form.mapImage && form.mapImage.length < 1"
            >
              <UploadImage @upload="handleUpload" />
            </div>
          </transition-group>
        </template>

        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
    <el-image-viewer
      v-if="imgViewerVisible"
      :on-close="closeImgViewer"
      :initialIndex="imgViewerIndex"
      :url-list="form.mapImage"
    />
  </div>
</template>

<script>
import {
  list,
  stateUpdateRegion,
  mapView,
  mapUpdate
} from '@/api/region/manage'
import Import from '@/components/import'
import qs from 'qs'
import UploadImage from './UploadImage'

export default {
  name: 'index',
  components: {
    Import,
    UploadImage,
    'el-image-viewer': () =>
      import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {
      searchData: {
        regionName: '',
        name: '',
        code: '',
        minElectricity: '',
        maxElectricity: '',
        moveState: ''
      },
      page: {
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '区域列表',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: '序号', // 索引文字
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '区域编号',
            prop: 'code'
          },
          {
            label: '区域名称',
            prop: 'name'
          },
          {
            label: '区域地址',
            prop: 'address'
          },
          {
            label: '城市合伙人',
            prop: 'cityPartner'
          },
          {
            label: '合伙人联系方式',
            prop: 'partnerPhone'
          },
          {
            label: '合作方式',
            prop: 'cooperWay'
          },
          // {
          //   label: "平台比例",
          //   prop: "platformScale",
          // },

          // {
          //   label: "居间费比例",
          //   prop: "betweenScale",
          // },
          // {
          //   label: "运营比例",
          //   prop: "runScale",
          // },

          // {
          //   label: "景区比例",
          //   prop: "scenicScale",
          // },
          {
            label: '创建时间',
            prop: 'createTime'
          },
          {
            label: '更新时间',
            prop: 'updateTime'
          },
          {
            label: '更新人',
            prop: 'updateUsername'
          },
          {
            label: '区域状态',
            prop: 'state',
            formatter: function(row, column, cellValue) {
              return row.state === 1
                ? '正常'
                : row.state === 2
                ? '冻结'
                : row.state
            }
          }
        ]
      },
      dialogVisiblePassword: false,
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: '160',
        column: [
          {
            label: '地图看板图片',
            prop: 'mapImage',
            span: 24,
            rules: [
              {
                required: true,
                message: '',
                trigger: 'blur'
              }
            ]
          }
        ]
      },
      importShow: false,
      imgViewerVisible: false,
      form: {
        id: '',
        mapImage: []
      },
      imgViewerIndex: 0, // 图片预览索引
      drag: false, // 拖拽
      importAction: '/api/web/region/manage/car/template/import',
      importProblem: '/api/web/region/manage/car/template/error/export',
      importDownloadLink: '/api/web/region/manage/car/template/export'
    }
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem('searchForm'))
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData
    } else {
      localStorage.removeItem('searchForm')
    }
  },
  methods: {
    salaryChange(e, t, max) {
      if (
        Number(this.searchData.minElectricity) >
          Number(this.searchData.maxElectricity) &&
        this.searchData.maxElectricity
      ) {
        this.searchData.minElectricity = ''
      }
      const value = e.target.value
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = ''
          e.target.value = ''
        } else {
          if (value > max) {
            this.form[t] = max
            e.target.value = max
          } else {
            this.form[t] = value * 1
            e.target.value = value * 1
          }
        }
      } else {
        this.form[t] = ''
      }
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData
      }
      localStorage.setItem('searchForm', JSON.stringify(searchForm))
      this.showLoading = true
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then((res) => {
        this.showLoading = false
        if (res.code === 200) {
          this.tableData = res.data.records
          this.page.total = res.data.total
        }
      })
    },

    // 查询
    handleSearch() {
      // console.log('查询')

      this.page.currentPage = 1
      this.onLoad()
    },
    // 重置
    handleReset() {
      // console.log('重置')
      localStorage.removeItem('searchForm')
      this.searchData = {
        regionName: '',
        name: '',
        code: '',
        minElectricity: '',
        maxElectricity: '',
        moveState: ''
      }
      this.page.currentPage = 1
      this.onLoad()
    },
    // 批量导入
    handleImport() {
      this.importShow = true
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false
      console.log('e111', e)
      this.onLoad()
    },
    // 导出
    handleExport() {
      // console.log('导出')
      window.open(
        `/api/web/region/manage/export?${qs.stringify(this.searchData)}`,
        '_self'
      )
    },
    // 添加车辆
    handleCreate() {
      this.$router.push({
        path: '/car/region/create',
        query: {
          type: 'create'
        }
      })
    },
    // 编辑车辆
    handleEdit(row) {
      this.$router.push({
        path: '/car/region/create',
        query: {
          type: 'edit',
          id: row.id
        }
      })
    },
    // 区域详情
    handleDetails(row) {
      this.$router.push({
        path: '/car/region/details',
        query: {
          type: 'edit',
          id: row.id
        }
      })
    },
    handleCarList(row) {
      this.$router.push({
        path: '/car/region/carList',
        query: {
          id: row.id
        }
      })
    },
    handleWhite(row) {
      this.$router.push({
        path: '/car/region/white',
        query: {
          id: row.id
        }
      })
    },
    // 冻结
    handleState(row) {
      const state = row.state == 1 ? '冻结' : row.state == 2 ? '解冻' : ''
      this.$confirm(`确定${state}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        stateUpdateRegion({
          id: row.id,
          state: row.state == 1 ? 2 : 1
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success(`${state}成功!`)
            this.onLoad()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    // 重置form
    resetForm() {
      this.form = {
        id: '',
        parkFeeDeduct: '',
        otherDeduct: '',
        depositPrice: ''
      }
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = ''
          e.target.value = ''
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2)
            e.target.value = max.toFixed(2)
          } else {
            this.form[t] = parseFloat(value).toFixed(2)
            e.target.value = parseFloat(value).toFixed(2)
          }
        }
      } else {
        this.form[t] = ''
      }
    },
    //打开地图
    handleMap() {
      mapView().then((res) => {
        if (res.code === 200) {
          if (!res.data.mapImage) {
            console.log('111')
            this.form.mapImage = []
          } else {
            console.log('2222')
            this.form.mapImage = [res.data.mapImage]
          }
          console.log('成功')
        } else {
          console.log('失败')
          // this.$message.error(res.msg);
        }
      })
      this.dialogVisiblePassword = true
    },
    // 提交
    submit(form, done) {
      mapUpdate({ mapImage: form.mapImage[0] })
        .then((res) => {
          if (res.code === 200) {
            this.dialogVisiblePassword = false
            setTimeout(() => {
              this.resetForm()
            }, 500)
            this.onLoad()
          } else {
            this.$message.error(res.msg)
            done()
          }
        })
        .catch(() => done())
    },
    // 关闭
    handleClose() {
      this.dialogVisiblePassword = false
    },
    handleUpload(e) {
      this.form.mapImage = [e]
    },
    handleDelete(index) {
      this.$confirm('是否确定移除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.form.mapImage.splice(index, 1)
          }
          done()
        }
      })
    },

    handleView(index) {
      this.imgViewerIndex = index
      this.imgViewerVisible = true
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, false) // 禁止页面滑动
    },
    closeImgViewer() {
      this.imgViewerVisible = false
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
    }
  }
}
</script>

<style scoped>
/deep/ .avue-form__group .avue-upload__icon {
  width: 148px !important;
  height: 148px !important;
  line-height: 148px !important;
}
/deep/ .avue-form__group .avue-upload__avatar {
  width: 148px !important;
  height: 148px !important;
  object-fit: contain;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: contain;
}
/deep/ .ElImage {
  width: 148px !important;
  height: 148px !important;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
/deep/ .ElImage .ElImageItem {
  width: 148px !important;
  height: 148px !important;
}
.ElImageHover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
}
/deep/.ElImageHover:hover {
  opacity: 1;
}
/deep/ .ElIcon {
  color: #fff;
  font-size: 20px;
}

.travel .travel-list {
  width: 33%;
  text-align: center;
  border-left: 1px solid #999;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}

.travel .travel-list:last-child {
  border-right: 1px solid #999;
}

.travel .travel-list .travel-list-title {
  border-bottom: 1px solid #999;
}

.avue-crud .el-input--small input,
::v-deep .avue-form .travel .travel-list .el-input--small input {
  border: none;
}
/deep/ .el-image-viewer__wrapper {
  z-index: 2010;
}
</style>
