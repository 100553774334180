<template>
  <el-dialog
    title="选择链接"
    :visible.sync="show"
    width="50%"
    top="5vh"
    :before-close="handleClose"
  >
    <el-tabs v-model="activeName">
      <el-tab-pane
        label="小程序页面"
        name="first"
      >
        <WxLinkList
          :default-data="defaultData"
          @change="handleChange"
        />
      </el-tab-pane>
      <el-tab-pane
        label="景区详情"
        name="second"
      >
        <RegionList
          :default-data="defaultData"
          @change="handleChange"
        />
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import WxLinkList from "./wxLinkList";
import RegionList from "./regionList";
export default {
  name: "index",
  components: {
    WxLinkList,
    RegionList,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    defaultData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleChange(e) {
      // // console.log(e)
      this.$emit("change", {
        linkType: this.activeName === "first" ? 1 : 2,
        ...e,
      });
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
</style>