<template>
  <div class="large-screen">
    <div class="padding20">
      <div class="width_100 flex justify-content-space-between">
        <div class="map-left">
          <Header />
          <div class="flex justify-content-space-between">
            <MapLeft class="left-box" :alldatas="alldata" :reginName="reginName" />
            <MapCenter
              class="center-box"
              :alldatas="alldata"
              :subassembly="subassembly"
              :ulListData="ulListData"
              :selectList="selectList"
            />
          </div>
        </div>

        <MapRight class="map-right" :alldatas="alldata" :listCar="listCar" :reginName="reginName" :getSeachCar="getSeachCar" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/header/index.vue";
import MapLeft from "./components/map-left/index.vue";
import MapCenter from "./components/map-center/index.vue";
import MapRight from "./components/map-right/index.vue";
import {
  list,
  addressList,
  upCount,
  carState,
  orderPrice,
  mapNotice,
  carList,
  typeProportion,
  orderProportion,
  regionNum,
} from "@/api/data/map";

export default {
  components: {
    Header,
    MapLeft,
    MapCenter,
    MapRight,
  },
  data() {
    return {
     reginID:'', //区域id
     reginName:'全区域',
      selectList:[],
      ulListData: [],
      listCar: [],
      alldata: {
        allImg:'',
        mapList: [],
        mapListNum:[],
        upCount: {},
        ulList: [],
        allImg: "",
        zhexianData: [],
        zhuzhuangData: [],
        listCar: [],
        PieCatTypeData: {},
        orderData:{}
      }, //所有数据请求存放
      loading: false,
    };
  },
  mounted() {
    this.getView("");
    this.getSelect()
  },
  methods: {
    getSeachCar(e) {
       //车辆信息
       carList({
        id: this.reginID,
        name:e,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.listCar = res.data.map((item, index) => {
            return {
              ...item,
              name: item.carTypeName,
              state:
                item.moveState === 1
                  ? "可用"
                  : item.moveState === 2
                  ? "被预约"
                  : item.moveState === 3
                  ? "使用中"
                  : item.moveState === 4
                  ? "用户上报故障"
                  : item.moveState === 5
                  ? "系统自测故障"
                  : "",
            };
          });
        } else {
          // this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
      
    },
    subassembly(e,label) {
      console.log(e,label,'12');
      this.reginID = e
      this.reginName = label
      this.getView(e)
    },
    getSelect() {
      list({
        // id: id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.selectList = res.data.map((item, index) => {
            return {
              label:item.name,
              value: item.id,
            };
          });
        } else {
          // this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    getView(id) {
      // this.loading = true;
      //地图区域
      console.log('这里执行了吗');
      addressList({
        id: id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.alldata.mapList = res.data.map((item, index) => {
            return {
              ...item,
              value: [item.longitude, item.latitude],
            };
          });
          if(this.reginID != '' ) {
            this.alldata.allImg = res.data[0].headImage
            console.log(res,'this.alldata.allImg');
          } else {
            this.alldata.allImg = ''
          }
        } else {
          // this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
         //地图区域数量
         regionNum({
        id: id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.alldata.mapListNum = res.data.map((item, index) => {
            return {
              name: item.province.slice(0,item.province.length-1),
              value: item.num,
            };
          });
        } else {
          // this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
      //中间统计
      upCount({
        id: id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.alldata.upCount = res.data;
        } else {
          // this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
      //公告
      mapNotice({
        id: id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.ulListData = res.data.map((item, index) => {
            return {
              msg: `${item.regionName}-${item.carCode}-${item.phone}-成交金额${item.totalPrice}-${item.createTime}`,
            };
          });
        } else {
          // this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
      //车辆状态
      carState({
        id: id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.alldata.zhuzhuangData = [
            {
              name: "可用",
              stack: "设备",
              type: "bar",
              barWidth: 20,
              data: [res.data.carNum[0]],
              itemStyle: {
                borderRadius: 4,
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#02F4C3",
                    },
                    {
                      offset: 1,
                      color: "#312AAD",
                    },
                  ],
                  global: false,
                },
              },
            },
            {
              name: "被预约",
              type: "bar",
              barWidth: 20,
              stack: "设备",
              data: [0, res.data.carNum[1]],
              itemStyle: {
                borderRadius: 4,
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#FACC8F",
                    },
                    {
                      offset: 1,
                      color: "#F59A23",
                    },
                  ],
                  global: false,
                },
              },
            },
            {
              name: "使用中",
              type: "bar",
              barWidth: 20,
              stack: "设备",
              data: [0, 0, res.data.carNum[2]],
              itemStyle: {
                borderRadius: 4,
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#CDF92F",
                    },
                    {
                      offset: 1,
                      color: "#52903D",
                    },
                  ],
                  global: false,
                },
              },
            },
            {
              name: "故障",
              type: "bar",
              barWidth: 20,
              stack: "设备",
              data: [0, 0, 0, res.data.carNum[3]],
              itemStyle: {
                borderRadius: 4,
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#F6B583",
                    },
                    {
                      offset: 1,
                      color: "#D9001B",
                    },
                  ],
                  global: false,
                },
              },
            },
          ];
        } else {
          // this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
      //订单金额
      orderPrice({
        id: id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.alldata.zhexianData = [
            {
              name: "正常完成",
              type: "line",
              smooth: true,
              data: res.data.normalPrice,
            },
            {
              name: "售后退款",
              type: "line",
              smooth: true,
              data: res.data.afterRefund,
            },
          ];
        } else {
          // this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
      //车辆信息
      carList({
        id: id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.listCar = res.data.map((item, index) => {
            return {
              ...item,
              name: item.carTypeName,
              state:
                item.moveState === 1
                  ? "可用"
                  : item.moveState === 2
                  ? "被预约"
                  : item.moveState === 3
                  ? "使用中"
                  : item.moveState === 4
                  ? "用户上报故障"
                  : item.moveState === 5
                  ? "系统自测故障"
                  : "",
            };
          });
        } else {
          // this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
      //车型占比
      typeProportion({
        id: id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          let PieCatData = res.data.map((item, index) => {
            return {
              ...item,
              value: item.carNum,
            };
          });
          this.alldata.PieCatTypeData = {
            name: "区域车型",
            type: "pie",
            radius: ["24%", "40%"],
            // avoidLabelOverlap: true,//对，就是这里avoidLabelOverlap
            data: PieCatData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            labelLine: {
              normal: {
                length: 2, //第一条线
                length: 2,
                lineStyle: {
                  width: 1, // 线条的宽度
                  type: "solid",
                },
              },
            },
          };
        } else {
          // this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
      //订单占比
      orderProportion({
        id: id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          let orderDatas = res.data.map((item, index) => {
            return {
              ...item,
              value: item.carNum,
            };
          });
          this.alldata.orderData = {
            name: "区域车型",
            type: "pie",
            radius: ["24%", "40%"],
            // avoidLabelOverlap: true,//对，就是这里avoidLabelOverlap
            data: orderDatas,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            labelLine: {
              normal: {
                length: 2, //第一条线
                length: 2,
                lineStyle: {
                  width: 1, // 线条的宽度
                  type: "solid",
                },
              },
            },
          };
        } else {
          // this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });

      // this.alldata = {
      //   allImg:
      //     "https://img1.baidu.com/it/u=2463514011,1142503686&fm=253&fmt=auto&app=138&f=JPEG?w=888&h=500",
      // orderData: {
      //   name: "订单率",
      //   type: "pie",
      //   radius: ["24%", "40%"],
      //   data: [
      //     {
      //       value: 48,
      //       name: "车型1",
      //       itemStyle: {
      //         color: "#70FFF9",
      //       },
      //     },
      //     {
      //       value: 22,
      //       name: "车型2",
      //       itemStyle: {
      //         color: "#7ED321",
      //       },
      //     },
      //     {
      //       value: 14,
      //       name: "车型3",
      //       itemStyle: {
      //         color: "#F58723",
      //       },
      //     },
      //     {
      //       value: 16,
      //       name: "车型4",
      //       itemStyle: {
      //         color: "#FA493F",
      //       },
      //     },
      //   ],
      //   emphasis: {
      //     itemStyle: {
      //       shadowBlur: 10,
      //       shadowOffsetX: 0,
      //       shadowColor: "rgba(0, 0, 0, 0.5)",
      //     },
      //   },
      // },
      // };
    },
  },
};
</script>

<style  scoped>
:-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.large-screen {
  background: #061734;
  height: 100vh;
  overflow-y: auto;
}
.map-left {
  width: calc(100% - 270px);
}
.map-right {
  width: 260px;
}

.left-box {
  width: 440px;
}
.center-box {
  width: calc(100% - 450px);
}
</style>