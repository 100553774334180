<template>
  <div class="main-content">

    <div style="display: flex;align-items: center;justify-content: space-between;">
      <el-button
        @click="goBack()"
        style="margin-bottom:20px"
      >返回</el-button>

      <div>
        <el-button
          type="primary"
          size="small"
          @click="handleEditAvailableIntegral(1)"
        >通过</el-button>
        <el-button
          size="small"
          @click="handleEditAvailableIntegral(2)"
        >驳回</el-button>
      </div>
    </div>

    <h3 class="marginBottom20">故障详情</h3>

    <div class="details-title-style">
      <div class="flex marginBottom10">
        <div class="width100">
          故障编号：
        </div>
        <div>
          {{ form.code }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width100">
          故障时间：
        </div>
        <div>
          {{ form.createTime }}
        </div>
      </div>
      <div class="flex marginBottom30">
        <div class="width100">
          故障状态：
        </div>
        <div>
          {{ form.state===1?'未填写意见':form.state===2?'意见待审核':form.state===3?'待处理':form.state===4?'待审核':form.state===5?'已处理':'' }}
        </div>
      </div>
  
      <div v-if="form.type === 1">
        <div class="flex marginBottom10">
        <div class="width100">
          故障信息
        </div>
        <div>
        </div>
        </div>
        <div class="flex marginBottom10">
          <div class="width100">
            订单编号：
          </div>
          <div>
            {{ form.orderCode }}
          </div>
        </div>
        <div class="flex marginBottom10">
          <div class="width100">
            会员编号：
          </div>
          <div>
            {{ form.userCode }}
          </div>
        </div>
        <div class="flex marginBottom10">
          <div class="width100">
            会员昵称：
          </div>
          <div>
            {{ form.nickname }}
          </div>
        </div>
        <div class="flex marginBottom10">
          <div class="width100">
            会员手机号：
          </div>
          <div>
            {{ form.phone }}
          </div>
        </div>
        <div class="flex marginBottom10">
          <div class="width100">
            申请原因：
          </div>
          <div>
            {{ form.applyType===1?'车辆故障': form.applyType===2?'其他原因': ''}}
          </div>
        </div>
        <div class="flex marginBottom30">
          <div class="width100">
            申请凭证：
          </div>
          <div>
            {{ form.saleDesc }}
          </div>
        </div>
        <div class="flex marginBottom30">
          <div class="width100">
            <!-- 申请凭证： -->
          </div>
          <div v-if="form.saleImage != ''" class="flex flex-wrap">
            <el-image
            style="width: 100px; height: 100px"
            class="marginRight10"
            v-for="(item,index) in form.saleImage"
            :preview-src-list="[item]"
            :key="index"
            :src="item"
            >
          </el-image>
            <!-- <img
              width="100px"
              height="100px"
              class="marginRight10"
              v-for="(item,index) in form.saleImage"
              :key="index"
              :src="item"
            > -->
          </div>
        </div>
        <div class="flex marginBottom30">
          <div class="width100">
            处理意见：
          </div>
          <div>
            {{form.handleSuggest}}
          </div>
        </div>
      </div>
      <div v-if="form.type === 2">
        {{ form.detectionContent }}
      </div>

      <div v-if="form.state!==2">

        <div class="flex marginBottom10">
          <div class="width100">
            处理信息
          </div>
          <div>

          </div>
        </div>

        <div class="flex marginBottom10">
          <div class="width100">
            处理人：
          </div>
          <div>
            {{ form.handleUsername }}
          </div>
        </div>

        <div class="flex marginBottom10">
          <div class="width100">
            处理时间：
          </div>
          <div>
            {{ form.handleTime	 }}
          </div>
        </div>

        <div class="flex marginBottom10">
          <div class="width100">
            处理人手机号：
          </div>
          <div>
            {{ form.handlePhone }}
          </div>
        </div>

        <div class="flex marginBottom10">
          <div class="width100">
            处理方式：
          </div>
          <div>
            {{ form.handleDesc }}
          </div>
        </div>

        <div class="flex marginBottom10">
          <div class="width100">
            处理图片：
          </div>
          <!-- v-if="form.state === 5 || form.state === 4" -->
          <div
            class="flex flex-wrap"
            v-if="form.handleImage.length"
          >
          <el-image
            style="width: 100px; height: 100px"
            class="marginRight10"
            v-for="(item,index) in form.handleImage"
            :preview-src-list="[item]"
            :key="index"
            :src="item"
            >
          </el-image>
            <!-- <img
              width="100px"
              height="100px"
              class="marginRight10"
              v-for="(item,index) in form.handleImage"
              :key="index"
              :src="item"
            > -->
          </div>
        </div>

      </div>
    </div>

    <el-dialog
      v-if="dialogVisibleAvailableIntegral"
      title="审核"
      :visible.sync="dialogVisibleAvailableIntegral"
      width="40%"
      :before-close="handleCloseAvailableIntegral"
    >
      <avue-form
        ref="forms"
        v-model="forms"
        :option="formOptionAvailableIntegral"
        @submit="submitAvailableIntegral"
      >
        <template slot="state">
          <el-radio-group v-model="forms.state">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
        </template>

        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleCloseAvailableIntegral"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { view, suggestExamine, examine } from "@/api/fault/manage";
import { stat } from "fs";
export default {
  data() {
    return {
      id: this.$route.query.id,
      form: {
        applyType: "",
        code: "",
        createTime: "",
        handleDesc: "",
        handleImage: "",
        handlePhone: "",
        handleSuggest: "",
        handleTime: "",
        handleUsername: "",
        id: "",
        nickname: "",
        orderCode: "",
        phone: "",
        saleDesc: "",
        saleImage: "",
        state: 0,
        type: 0,
        userCode: "",
      },
      dialogVisibleIntimacy: false,
      dialogVisibleAvailableIntegral: false,
      formOptionAvailableIntegral: {
        labelWidth: "100",
        submitText: "确定",
        emptyBtn: false,
        column: [
          {
            label: "审核结果",
            prop: "state",
            type: "radio",
            // value: 1,
            dicData: [
              { label: "通过", value: 1 },
              { label: "驳回", value: 2 },
            ],
            span: 24,
            maxRows: 999999,
            minRows: 0,
            controls: false,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请选择审核结果",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      forms: {
        state: "",
        id: "",
      },
    };
  },
  mounted() {
    if (this.id) {
      this.getView();
    }
  },
  methods: {
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    async getView() {
      const { data } = await view({ id: this.id });
      this.form = {
        ...data,
        saleImage: data?.saleImage?.split(","),
        handleImage: data?.handleImage? data?.handleImage?.split(",") : [],
      };
      this.forms.id = this.form.id;
    },
    // 打开亲呢度弹窗
    async handleEditIntimacy() {
      this.dialogVisibleIntimacy = true;
    },
    // 审核
    submitAvailableIntegral(form, done) {
      if (this.form.state === 2) {
        suggestExamine({
          id: form.id,
          state: form.state,
        })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.dialogVisibleAvailableIntegral = false;
              // this.getView();
              this.goBack();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      }

      if (this.form.state === 4) {
        examine({
          id: form.id,
          state: form.state,
        })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.dialogVisibleAvailableIntegral = false;
              // this.getView();
              this.goBack();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      }
    },
    // 关闭亲呢度弹窗
    handleCloseAvailableIntegral() {
      this.dialogVisibleAvailableIntegral = false;
    },
    // 打开积分弹窗
    async handleEditAvailableIntegral(state) {
      this.dialogVisibleAvailableIntegral = true;
      this.forms.state = state;
    },
  },
};
</script>

<style>
</style>