<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items flex-wrap justify-content-space-between marginBottom10">
          <div class="flex">
            <el-input
              size="medium"
              class="width300 marginBottom10 marginRight10"
              v-model="searchData.username"
              auto-complete="off"
              :maxlength="20"
              placeholder="请输入用户名称"
              @change="handleSearch"
              clearable
            />
            <div class="marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
              >查询</el-button>
            </div>
            <div class="marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
              >重置</el-button>
            </div>
          </div>
          <div class="flex align-items marginBottom10">
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-plus"
              @click="handleCreate"
            >新增</el-button>
            <el-button
              size="medium"
              type="warning"
              icon="el-icon-download"
              @click="handleExport"
            >导出</el-button>
          </div>

        </div>
      </template>

      <template
        slot="menu"
        slot-scope="{row}"
      >
        <el-button
          size="small"
          type="text"
          icon="el-icon-refresh"
          @click="handleRefresh(row)"
        >密码重置</el-button>
        <el-button
          size="small"
          type="text"
          icon="el-icon-edit"
          @click="handleEdit(row)"
        >编辑</el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-delete"
          @click="handleDelete(row)"
        >删除</el-button>
        <el-button
          v-if="row.state === 1"
          size="small"
          type="text"
          icon="el-icon-warning"
          @click="handleForbiddenD(row)"
        >禁用</el-button>
        <el-button
        v-else
          size="small"
          type="text"
          icon="el-icon-warning"
          @click="handleForbidden(row)"
        >解禁</el-button>
      </template>

    </avue-crud>

    <el-dialog
      :title="form.id ? '编辑' : '新增'"
      v-if="show"
      :visible.sync="show"
      width="50%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="handleSubmit"
      >

        <template slot="phone">
          <el-input
            v-model="form.phone"
            type="text"
            maxlength="11"
            placeholder="请输入 联系电话"
            clearable
            show-word-limit
            onkeyup="this.value=this.value.replace(/^(0+)|\D+/g,'')"
            @blur="changeBlur($event,'phone')"
          >
          </el-input>
        </template>

        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleClose"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="dialogPassWordVisible"
      width="35%"
      :before-close="handlePassWordClose"
    >
      <el-card>
        <div class="flex justify-content-flex-start">
          <i class="icon el-icon-warning"></i>
          <div class="passWord">
            <p class="tips"> {{ this.editId?'密码修改成功，请妥善保存账户密码':'您的新密码已经生成，请妥善保存账户密码' }}</p>
            <div class="flex align-items">
              <p>账户：{{ passwordInfo.phone }}</p>
              <p>密码：{{ passwordInfo.password }}</p>
              <p
                style="cursor: pointer"
                @click="handleCopy(passwordInfo)"
              >点此复制</p>
            </div>
          </div>
        </div>
      </el-card>
      <div class="flex justify-content-flex-end marginTop20">
        <el-button
          size="small"
          @click="handlePassWordClose"
        >取消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="handlePassWordClose"
        >确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  userlist,
  userDelete,
  passwordReset,
  userAdd,
  userUpdate,
  userRole,
  userView,
  userStateUpdate,
} from "@/api/system";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      editId: false,
      page: {
        total: 0,
        currentPage: 1,
      },
      searchData: {
        username: "",
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "账号管理",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "用户名称",
            prop: "username",
          },
          {
            label: "联系电话",
            prop: "phone",
          },
          {
            label: "用户邮箱",
            prop: "email",
          },
          {
            label: "角色",
            prop: "roleName",
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
        ],
      },

      show: false,
      form: {},
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "登录账号(手机号)",
            prop: "phone",
            maxlength: 11,
            showWordLimit: true,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入登录账号",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (!/^1[3456789]\d{9}$/.test(value)) {
                    callback(new Error("请输入正确的手机号"));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          },
          {
            label: "用户名称",
            prop: "username",
            maxlength: 20,
            showWordLimit: true,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入登录名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "用户邮箱",
            prop: "email",
            maxlength: 100,
            showWordLimit: true,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入用户邮箱",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (
                    !/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/g.test(
                      value
                    )
                  ) {
                    callback(new Error("请输入正确的邮箱"));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          },
          {
            label: "角色",
            prop: "roleId",
            type: "select",
            dicData: [],
            span: 24,
            props: {
              label: "name",
              value: "id",
            },
            rules: [
              {
                required: true,
                message: "请选择角色",
                trigger: "blur",
              },
            ],
          },
        ],
      },

      dialogPassWordVisible: false,
      passwordInfo: {
        phone: "",
        password: "",
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  async mounted() {
    await this.getUserRole();
  },
  methods: {
        // 禁用二次弹窗
        handleForbiddenD(row) {
      // console.log('删除',row)
      this.$confirm("是否禁用此账户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        userStateUpdate({
        id: row.id,
        state: row.state === 1 ? 2 : 1,
      }).then((res) => {
        console.log(res,'resres');
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.onLoad();
        } else {
          this.$message.error(res.msg)
        }
      });
      });
    },
    // 禁用/解禁
    handleForbidden(row) {
      userStateUpdate({
        id: row.id,
        state: row.state === 1 ? 2 : 1,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.onLoad();
        }
      });
    },
    // 角色
    async getUserRole() {
      const { data } = await userRole();
      // console.log(data)
      const formOption = this.findObject(this.formOption.column, "roleId");
      formOption.dicData = data || [];
    },
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      this.searchData = this.$options.data().searchData;
      this.page.currentPage = 1;
      this.onLoad();
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      userlist({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 新增账号
    handleCreate() {
      this.show = true;
    },
    // 修改
    async handleEdit(row) {
      // console.log('修改',row)
      if (await this.getUserView(row.id)) {
        this.show = true;
      }
    },
    async getUserView(id) {
      const res = await userView(id);
      // console.log(res)
      if (res.code === 200) {
        this.form = {
          ...res.data,
        };
        return true;
      } else {
        this.$message.error(res.msg);
        return false;
      }
    },
    // 密码重置
    handleRefresh(row) {
      // console.log('密码重置',row)
      this.editId = true;
      this.$confirm(`确认重置用户${row.username}的密码?`, "重置密码", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        passwordReset(row.id).then((res) => {
          if (res.code === 200) {
            this.passwordInfo = res.data;
            setTimeout(() => {
              this.dialogPassWordVisible = true;
            }, 500);
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    handlePassWordClose() {
      this.editId = false;
      this.dialogPassWordVisible = false;
      setTimeout(() => {
        this.passwordInfo = this.$options.data().passwordInfo;
      }, 500);
    },
    handleCopy(info) {
      // js复制info到剪切板
      const text = `账户：${info.phone} 密码：${info.password}`;
      const input = document.createElement("input");
      input.setAttribute("readonly", "readonly");
      input.setAttribute("value", text);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$message.success("账户密码已复制");
      }
      document.body.removeChild(input);
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("是否删除此账号数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        userDelete(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 提交
    handleSubmit(form, done) {
      // console.log(form)
      if (form.id) {
        userUpdate({
          ...form,
        })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功");
              this.show = false;
              this.onLoad();
              setTimeout(() => {
                this.resetForm();
              }, 200);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      } else {
        userAdd({
          ...form,
        })
          .then((res) => {
            if (res.code === 200) {
              // this.$message.success("添加成功");
              this.show = false;
              this.dialogPassWordVisible = true;
              this.passwordInfo = res.data;
              this.onLoad();
              setTimeout(() => {
                this.resetForm();
              }, 200);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      }
    },
    // 关闭
    handleClose() {
      this.show = false;
      setTimeout(() => {
        this.resetForm();
      }, 200);
    },
    resetForm() {
      this.form = {
        id: "",
      };
    },
    // 导出
    handleExport() {
      const searchData = {
        username: this.searchData.username,
      };

      window.open(
        `/api/web/system/user/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },

    // 限制输入框
    changeBlur(e, text) {
      // console.log(e.target.value,text)
      // console.log(isNaN(e.target.value))
      const value = e.target.value;
      if (value) {
        if (isNaN(e.target.value)) {
          e.target.value = "";
          this.form[text] = "";
        } else {
          // console.log(Number(value))
          this.form[text] = value;
        }
      } else {
        e.target.value = "";
        this.form[text] = "";
      }
    },
  },
};
</script>

<style scoped>
.icon {
  font-size: 28px;
  color: #faad14;
}
.passWord {
  padding-left: 5%;
}
.tips {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 30px;
}
.passWord p {
  padding-right: 20px;
}
</style>