<template>
	<img src="../../../../../style/image/logomap.png" alt="logo" @click="onLinkHandle" class="logos">
</template>

<script>
	export default {
		methods: {
			onLinkHandle() {
				this.$store.commit("setSubMenuIndex", '');
				this.$router.push("/");
			},
		},
	};
</script>

<style scoped>
	.logos {
		margin-left: 30px;
		width: 80px !important;
		height: 80px !important;
	}

	.logos:hover {
		cursor: pointer;
	}
</style>