var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("车型管理")]),_c('avue-form',{ref:"form",attrs:{"option":_vm.formOption},model:{value:(_vm.dataFour),callback:function ($$v) {_vm.dataFour=$$v},expression:"dataFour"}},[_c('template',{slot:"carTypes"},[_c('div',{staticClass:"main-content"},[_c('avue-crud',{attrs:{"data":_vm.dataFour.carTypes,"option":_vm.option,"table-loading":_vm.showLoading},scopedSlots:_vm._u([{key:"bufferNum",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.bufferNum)+" ")]}},{key:"orderFirstRemind",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.orderFirstRemind)+" ")]}},{key:"orderTwoRemind",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.orderTwoRemind)+" ")]}},{key:"lowBatteryRemind",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.lowBatteryRemind)+" ")]}},{key:"lowBatteryNoUse",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.lowBatteryNoUse)+" ")]}},{key:"lockTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.lockTime)+" ")]}},{key:"workerMoveCar",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.workerMoveCar)+" ")]}},{key:"dayUseTimeSlotEnd",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex"},[_c('div',{staticClass:"width200"},[_vm._v(" "+_vm._s(row.dayUseTimeSlotStart)+" ")]),_c('div',{staticStyle:{"margin":"0 10px"}},[_vm._v(" - ")]),_c('div',{staticClass:"width200"},[_vm._v(" "+_vm._s(row.dayUseTimeSlotEnd)+" ")])])]}},{key:"menu",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.handleFreeze(row)}}},[_vm._v(_vm._s(row.state==1?'冻结':row.state==2?'解冻':''))]),_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.handleUnify(row,1)}}},[_vm._v("统一启动")]),_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.handleUnify(row,2)}}},[_vm._v("统一上锁")])]}}])})],1)])],2),_c('CarType',{attrs:{"show":_vm.dialogVisibleCarType,"defaultData":_vm.form.carTypes},on:{"change":_vm.handleSelectData,"close":_vm.handleSelectClose}}),_c('CreateCar',{attrs:{"show":_vm.dialogVisibleCarList,"defaultData":_vm.carList,"carId":_vm.carId},on:{"change":_vm.handleSelectDataCarList,"close":_vm.handleSelectCloseCarList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }