const house = [
    {
        path: '/region/manage',
        name: 'regionManage',
        component: () => import('@/view/region/manage'),
    },
    {
        path: '/car/region/create',
        name: 'regionManageCreate',
        component: () => import('@/view/region/manage/create'),
    },
    {
        path: '/car/region/details',
        name: 'regionManageDetails',
        component: () => import('@/view/region/manage/details'),
    },
    {
        path: '/car/region/carList',
        name: 'regionManageCarList',
        component: () => import('@/view/region/manage/carList'),
    },
    {
        path: '/car/region/white',
        name: 'regionManageWhite',
        component: () => import('@/view/region/manage/white'),
    },
    {
        path: '/region/white',
        name: 'regionWhite',
        component: () => import('@/view/region/white'),
    },
    {
        path: '/region/commission',
        name: 'regionCommission',
        component: () => import('@/view/region/commission')
    },
    {
        path: '/region/commission/create',
        name: 'regionCommissionCreate',
        component: () => import('@/view/region/commission/create')
    },
    {
        path: '/region/commission/revenue',
        name: 'regionCommissionRevenue',
        component: () => import('@/view/region/commission/revenue')
    },
    {
        path: '/region/commission/bindingRelationship',
        name: 'regionCommissionBindingRelationship',
        component: () => import('@/view/region/commission/bindingRelationship')
    },
    {
        path: '/region/commission/atomicFission',
        name: 'regionCommissionAtomicFission',
        component: () => import('@/view/region/commission/atomicFission')
    },
    {
        path: '/region/finance',
        name: 'regionFinance',
        component: () => import('@/view/region/finance'),
    },
    {
        path: '/region/business',
        name: 'regionBusiness',
        component: () => import('@/view/region/business'),
    },

]
export default house