<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">

            <div class="flex align-items marginRight10 marginBottom10 ">
              <div class="width300">
                <el-input
                  v-model="searchData.name"
                  placeholder="输入会员编号/会员昵称/会员手机号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>

            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width200">
                <el-select
                  class="width200"
                  v-model="searchData.payChannel"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="支付渠道"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="微信"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="余额"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width200">
                <el-select
                  class="width200"
                  v-model="searchData.state"
                  clearable
                  @change="handleSearch"
                >
                  <!-- <el-option
                    value=""
                    label=""
                  ></el-option> -->
                  <el-option
                    :value="1"
                    label="收取押金"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="退还押金"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="marginLeft10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10 marginBottom10"
              >查询</el-button>
            </div>

            <div class="marginLeft10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10 marginBottom10"
              >重置</el-button>
            </div>

            <div class="marginLeft10 marginBottom10">
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-download"
                @click="handleExport"
                class="marginLeft10 marginBottom10"
              >导出</el-button>
            </div>
          </div>

        </div>
      </template>

    </avue-crud>

  </div>
</template>

<script>
import { list } from "@/api/deposit/record";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        name: "",
        state: "",
        createTime: "",
        payChannel: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "押金记录",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "会员编号",
            prop: "code",
          },
          {
            label: "会员昵称",
            prop: "nickname",
          },
          {
            label: "会员手机号",
            prop: "phone",
          },
          {
            label: "支付渠道",
            prop: "payChannel",
            formatter: function (row, column, cellValue) {
              return row.payChannel === 1
                ? "微信"
                : row.payChannel === 2
                ? "余额"
                : row.payChannel;
            },
          },
          {
            label: "状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "收取押金"
                : row.state === 2
                ? "退还押金"
                : row.state;
            },
          },
          {
            label: "时间",
            prop: "createTime",
          },
          {
            label: "押金金额(元)",
            prop: "depositPrice",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        state: "",
        name: "",
        createTime: "",
        payChannel: "",
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
      if (
        searchForm.searchData.createTimeStart &&
        searchForm.searchData.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData.createTimeStart,
          searchForm.searchData.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        state: this.searchData.state,
        name: this.searchData.name,
        payChannel: this.searchData.payChannel,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));

      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        createTime: "",
        payChannel: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },

    // 导出
    handleExport() {
      const searchData = {
        state: this.searchData.state,
        name: this.searchData.name,
        payChannel: this.searchData.payChannel,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/deposit/record/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>

<style scoped>
</style>