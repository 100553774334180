<template>
  <div class="main-content">
    <h3 class="marginBottom20">车辆预约功能设置</h3>
    <div style="text-align:right">
      <el-button
        type="primary"
        @click="handleSave"
        style="margin-bottom:20px"
        :loading="loading"
        >{{ disabled ? "编辑" : "保存" }}</el-button
      >
    </div>
    <el-card>
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="state">
          <div class="flex align-items">
            <div style="margin-right: 20px;">车辆预约功能</div>
            <el-switch v-model="form.state" :disabled="disabled"> </el-switch>
          </div>
        </template>
      </avue-form>
    </el-card>
  </div>
</template>

<script>
import { subscribeView, subscribeUpdate } from "@/api/setting/index";
export default {
  name: "index",
  data() {
    return {
      form: {},
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "",
            prop: "state",
            span: 24,
            row: true,
          },
        ],
      },
      options: {
        action: this.actionUrl,
        customConfig: {}, //wangEditor编辑的配置
        headers: {
          Authorization: this.$store.state.user.Token,
        },
        props: {
          url: "path",
          res: "data",
          name: "path",
        },
      },
      loading: false,
      disabled: true,
    };
  },
  created() {},
  mounted() {
    this.getView();
  },
  methods: {
    async getView() {
      try {
        const { data } = await subscribeView();
        // console.log(data)
        if (data.state === 1) {
          data.state = true;
        } else {
          data.state = false;
        }
        this.form = data;
      } catch (e) {
        // console.log('错误',e)
      }
    },
    handleSave() {
      if (this.disabled) {
        this.disabled = false;
        return;
      }
      if (this.form.state) {
        this.form.state = 1;
      } else {
        this.form.state = 2;
      }
      if (this.loading) return;
      this.loading = true;
      subscribeUpdate(this.form)
        .then((res) => {
          this.loading = false;
          this.$message.success("保存成功");
          this.disabled = true;
          this.getView();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
/deep/.el-descriptions-item .el-descriptions-item__container {
  align-items: center;
}
/deep/.el-input-number.is-without-controls .el-input__inner {
  text-align: left;
}
</style>
