var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("车型管理")]),_c('avue-form',{ref:"form",attrs:{"option":_vm.formOption},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('template',{slot:"carTypes"},[_c('div',{staticClass:"main-content"},[_c('avue-crud',{attrs:{"data":_vm.dataFour.carTypes,"option":_vm.option,"table-loading":_vm.showLoading},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex align-items marginBottom20"},[_c('el-button',{attrs:{"size":"medium","type":"primary","icon":"el-icon-plus"},on:{"click":_vm.handleCreate}},[_vm._v("添加车型")])],1)]},proxy:true},{key:"bufferNum",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"value":row.bufferNum,"placeholder":"请输入","onkeyup":"this.value=this.value.replace(/[^\\d]+/g,'')","type":"text","clearable":""},on:{"blur":function($event){return _vm.salaryChange($event,'bufferNum',999999,row.$index)}},model:{value:(row.bufferNum),callback:function ($$v) {_vm.$set(row, "bufferNum", $$v)},expression:"row.bufferNum"}})]}},{key:"orderFirstRemind",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"value":row.orderFirstRemind,"placeholder":"请输入","onkeyup":"this.value=this.value.replace(/[^\\d]+/g,'')","type":"text","clearable":""},on:{"blur":function($event){return _vm.salaryChange($event,'orderFirstRemind',999999,row.$index)}},model:{value:(row.orderFirstRemind),callback:function ($$v) {_vm.$set(row, "orderFirstRemind", $$v)},expression:"row.orderFirstRemind"}})]}},{key:"orderTwoRemind",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"value":row.orderTwoRemind,"placeholder":"请输入","onkeyup":"this.value=this.value.replace(/[^\\d]+/g,'')","type":"text","clearable":""},on:{"blur":function($event){return _vm.salaryChange($event,'orderTwoRemind',row.orderFirstRemind,row.$index)}},model:{value:(row.orderTwoRemind),callback:function ($$v) {_vm.$set(row, "orderTwoRemind", $$v)},expression:"row.orderTwoRemind"}})]}},{key:"lowBatteryRemind",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"value":row.lowBatteryRemind,"placeholder":"请输入","onkeyup":"this.value=this.value.replace(/[^\\d]+/g,'')","type":"text","clearable":""},on:{"blur":function($event){return _vm.salaryChange($event,'lowBatteryRemind',100,row.$index)}},model:{value:(row.lowBatteryRemind),callback:function ($$v) {_vm.$set(row, "lowBatteryRemind", $$v)},expression:"row.lowBatteryRemind"}})]}},{key:"lowBatteryNoUse",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"value":row.lowBatteryNoUse,"placeholder":"请输入","onkeyup":"this.value=this.value.replace(/[^\\d]+/g,'')","type":"text","clearable":""},on:{"blur":function($event){return _vm.salaryChange($event,'lowBatteryNoUse',999999,row.$index)}},model:{value:(row.lowBatteryNoUse),callback:function ($$v) {_vm.$set(row, "lowBatteryNoUse", $$v)},expression:"row.lowBatteryNoUse"}})]}},{key:"lockTime",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"value":row.lockTime,"placeholder":"请输入","onkeyup":"this.value=this.value.replace(/[^\\d]+/g,'')","type":"text","clearable":""},on:{"blur":function($event){return _vm.salaryChange($event,'lockTime',999999,row.$index)}},model:{value:(row.lockTime),callback:function ($$v) {_vm.$set(row, "lockTime", $$v)},expression:"row.lockTime"}})]}},{key:"workerMoveCar",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"value":row.workerMoveCar,"placeholder":"请输入","onkeyup":"this.value=this.value.replace(/[^\\d]+/g,'')","type":"text","clearable":""},on:{"blur":function($event){return _vm.salaryChange($event,'workerMoveCar',100,row.$index)}},model:{value:(row.workerMoveCar),callback:function ($$v) {_vm.$set(row, "workerMoveCar", $$v)},expression:"row.workerMoveCar"}})]}},{key:"dayUseTimeSlotEnd",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex"},[_c('div',{staticClass:"width200"},[_c('el-time-select',{staticClass:"width200",attrs:{"placeholder":"起始时间","value":row.dayUseTimeSlotStart,"picker-options":{
     start: '00:00',
     step:'00:01',
     end: '23:58',
     maxTime: row.dayUseTimeSlotEnd
    }},model:{value:(row.dayUseTimeSlotStart),callback:function ($$v) {_vm.$set(row, "dayUseTimeSlotStart", $$v)},expression:"row.dayUseTimeSlotStart"}})],1),_c('div',{staticStyle:{"margin":"0 10px"}},[_vm._v(" - ")]),_c('div',{staticClass:"width200"},[_c('el-time-select',{staticClass:"width200",attrs:{"placeholder":"结束时间","value":row.dayUseTimeSlotEnd,"picker-options":{
      start: '00:00',
      step:'00:01',
      end: '23:59',
      minTime: row.dayUseTimeSlotStart
    }},model:{value:(row.dayUseTimeSlotEnd),callback:function ($$v) {_vm.$set(row, "dayUseTimeSlotEnd", $$v)},expression:"row.dayUseTimeSlotEnd"}})],1)])]}},{key:"menu",fn:function(ref){
    var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.handleCreateCarList(row)}}},[_vm._v("添加车辆")]),_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.handleFreeze(row)}}},[_vm._v(_vm._s(row.state==1?'冻结':row.state==2?'解冻':''))]),_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除")])]}}])})],1)])],2),_c('CarType',{attrs:{"show":_vm.dialogVisibleCarType,"defaultData":_vm.carTypes},on:{"change":_vm.handleSelectData,"newchange":_vm.handleSelectNewData,"close":_vm.handleSelectClose}}),_c('CreateCar',{attrs:{"show":_vm.dialogVisibleCarList,"defaultData":_vm.carLists,"carId":_vm.carId},on:{"change":_vm.handleSelectDataCarList,"newchange":_vm.handleSelectNewDataCarList,"close":_vm.handleSelectCloseCarList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }