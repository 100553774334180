<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex justify-content-space-between align-items marginBottom20">
          <div class="flex align-items">
            <div class="flex align-items marginRight10">
              <div class="width200">
                <el-input
                  v-model="searchData.name"
                  placeholder="输入类型名称"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="10"
                ></el-input>
              </div>
            </div>
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>
          </div>

          <el-button
            size="medium"
            type="primary"
            icon="el-icon-plus"
            @click="handleCreate"
          >新增</el-button>
        </div>
      </template>
      <template
        slot="retailScale"
        slot-scope="{ row }"
      >
        <div>{{ row.retailScale + "%" }}</div>
      </template>
      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          @click="handleEdit(row)"
        >编辑</el-button>
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
        >删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      :title="form.id ? '编辑' : '新建'"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >
        <template slot="couponId">
          <el-select
            v-model="form.couponId"
            placeholder="请选择优惠券"
          >
            <el-option
              v-for="item in couponList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
        <template slot="retailScale">
          <el-input-number
            v-model="form.retailScale"
            :disabled="disabled"
            class="width250"
            placeholder="请输入分销默认比例"
            :precision="1"
            :max="100"
            :min="0"
            :controls="false"
          ></el-input-number>
        </template>
        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleClose"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  typeList,
  typeDelete,
  couponList,
  typeAdd,
  typeUpdate,
  typeView,
} from "@/api/partner/withdraw";
export default {
  name: "index",
  data() {
    return {
      disabled: false,
      searchData: {
        name: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "销售合伙人类型设置",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        menu: true,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "类型名称",
            prop: "name",
          },
          {
            label: "分销默认比例",
            prop: "retailScale",
          },
          {
            label: "关联优惠券",
            prop: "couponName",
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
        ],
      },

      dialogVisible: false,
      couponList: [],
      form: {
        id: "",
        couponId: "",
        name: "",
        retailScale: 10,
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "130",
        column: [
          {
            label: "类型名称",
            prop: "name",
            span: 24,
            maxlength: 10,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入类型名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "分销默认比例",
            prop: "retailScale",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入分销默认比例",
                trigger: "blur",
              },
            ],
          },
          {
            label: "关联优惠券",
            prop: "couponId",
            type: "select",
            span: 24,
            row: true,
            rules: [
              {
                required: true,
                message: "请选择优惠券",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择优惠券"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    // 新增跳转
    handleCreate() {
      this.dialogVisible = true;
      this.couponLoad();
    },
    // 销售合伙人类型详情
    typeView(id) {
      typeView({ id }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.form = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 优惠券列表
    couponLoad() {
      this.couponList = [];
      couponList({}).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.couponList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 编辑
    handleEdit(row) {
      console.log(row);
      this.dialogVisible = true;
      this.typeView(row.id);
      this.couponLoad();
    },
    // 删除
    handleDelete(row) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        typeDelete({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        name: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));
      this.showLoading = true;
      typeList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 提交
    submit(form, done) {
      console.log(form);
      if (form.id) {
        typeUpdate(form)
          .then((res) => {
            if (res.code === 200) {
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.$message.success("新增成功");
              this.onLoad();
              done();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      } else {
        typeAdd(form)
          .then((res) => {
            if (res.code === 200) {
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.$message.success("新增成功");
              this.onLoad();
              done();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      }
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        couponId: "",
        name: "",
        retailScale: 10,
      };
    },
  },
};
</script>
