<template>
  <div>
   <div style="margin-left: 20px;">
    <el-row :gutter="26">
      <el-col :span="3">
        <div>
          <div class="title-info">总车辆数</div>
          <div class="number-info">
            {{ alldatas.upCount.carNum }}
          </div>
        </div>
      </el-col>
      <el-col :span="3">
        <div>
          <div class="title-info">正常车辆数</div>
          <div class="number-info">
            {{ alldatas.upCount.carNormalNum }}
          </div>
        </div>
      </el-col>
      <el-col :span="3">
        <div>
          <div class="title-info">故障车辆数</div>
          <div class="number-info">
            {{ alldatas.upCount.carUnNormalNum }}
          </div>
        </div>
      </el-col>
      <el-col :span="3">
        <div>
          <div class="title-info">总订单数</div>
          <div class="number-info">
            {{ alldatas.upCount.orderNum }}
          </div>
        </div>
      </el-col>
      <el-col :span="4">
        <div>
          <div class="title-info">订单金额(元)</div>
          <div class="number-info">
            {{ alldatas.upCount.orderPrice }}
          </div>
        </div>
      </el-col>
      <el-col :span="3">
        <div>
          <div class="title-info">总售后单数</div>
          <div class="number-info">
            {{ alldatas.upCount.afterNum }}
          </div>
        </div>
      </el-col>
      <el-col :span="4">
        <div>
          <div class="title-info">退款金额(元)</div>
          <div class="number-info">
            {{ alldatas.upCount.returnPrice }}
          </div>
        </div>
      </el-col>
    </el-row>
   </div>

    <div class="notice flex align-items margin20">
      <div class="notice-title">公告</div>
      <div class="notice-content">
        <ul class="list">
          <li
            v-for="(item, index) in ulList"
            :key="item.id"
            :class="!index && play ? 'toUp' : ''"
          >
            {{ item.msg }}
          </li>
        </ul>
      </div>
    </div>

    <ChinaMap :mapList="alldatas.mapList" :mapListNum="alldatas.mapListNum" :subassembly="subassembly" :selectList="selectList" />
  </div>
</template>

<script>
import ChinaMap from "./china-map.vue";
export default {
  components: {
    ChinaMap,
  },
  props: {
    alldatas: Object,
    ulListData:Array,
    subassembly: Function,
    selectList:Array,
  },
  data() {
    return {
      carNumber: 100,
      ulList: [],
      play: false,
      clearFlag:null,
    };
  },
  watch: {
    ulListData: {
      handler(newVal, oldVal) {
        this.ulList = JSON.parse(JSON.stringify(newVal));
        this.getTimer();
      },
      deep: true,
    },
  },
  mounted() {
    // setInterval(this.startPlay, 2000);
  },
  methods: {
    getTimer() {
      clearInterval(this.clearFlag);
      this.clearFlag = setInterval(this.startPlay, 2000);
    },
    startPlay() {
      let that = this;
      that.play = true; //开始播放
      setTimeout(() => {
        that.ulList.push(that.ulList[0]); //将第一条数据塞到最后一个
        that.ulList.shift(); //删除第一条数据
        that.play = false; //暂停播放,此处修改，保证每一次都会有动画显示
      }, 500);
    },
  },
};
</script>

<style scoped>
.title-info {
  color: #fff;
}
.number-info {
  font-size: 24px;
  color: #6bf5f0;
  font-weight: 600;
}

.notice .notice-title {
  font: 0.6rem;
  color: #333;
  padding: 5px 24px;
  background: #81d3f8;
  border-radius: 20px;
  margin-right: 20px;
}

.notice .notice-content {
  color: #fff;
  /* height: 500px; */
}

.toUp {
  margin-top: -40px;
  transition: all 0.5s;
}

.list {
  list-style: none;
  width: 100%;
  text-align: center;
  overflow: hidden;
  height: 40px;
  padding: 0;
  margin: 0;
}
li {
  text-align: left;
  height: 40px;
  line-height: 40px;
}
</style>