var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dv-border-box-8',{staticClass:"marginBottom10 dv-border-box-8",attrs:{"reverse":true,"backgroundColor":"#0A1C3C"}},[_c('div',{staticClass:"padding20"},[_c('div',{staticClass:"flex justify-content-space-between"},[_c('div',[_vm._v("车辆信息-"+_vm._s(_vm.reginName))]),_c('div',[_c('i',{staticClass:"el-icon-refresh",staticStyle:{"font-size":"24px"},on:{"click":_vm.handleRefresh}})])]),_c('div',{staticClass:"margin10"},[_c('el-input',{staticClass:"search-car-region",attrs:{"placeholder":"请输入车型名称/车辆编号","maxlength":"20"},model:{value:(_vm.input3),callback:function ($$v) {_vm.input3=$$v},expression:"input3"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.handleSearch},slot:"append"})],1)],1),_c('div',{staticClass:"list-car-info"},[_c('div',{staticClass:"list-title"},[_c('div',[_vm._v("车型名称")]),_c('div',[_vm._v("车型编号")]),_c('div',[_vm._v("当前状态")]),_c('div',[_vm._v("行动轨迹")])]),_c('div',{staticClass:"list-car-box"},_vm._l((_vm.listCars),function(item,index){return _c('div',{key:index,staticClass:"list-car",class:[
            item.state === '可用'
              ? 'list-car-state1'
              : item.state === '使用中'
              ? 'list-car-state2'
              : item.state === '被预约'
              ? 'list-car-state3'
              : item.state === '故障'
              ? 'list-car-state4'
              : '',
            !index && _vm.play ? 'toUp' : '' ]},[_c('el-tooltip',{staticClass:"list-car-item",attrs:{"effect":"dark","content":item.name + ' ' + item.code + ' ' + item.state,"placement":"top"}},[_c('div',{staticClass:"flex width_100 justify-content-space-between"},[_c('div',{staticClass:"list-car-list list-car-name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"list-car-list list-car-code"},[_vm._v(" "+_vm._s(item.code)+" ")]),_c('div',{staticClass:"list-car-list list-car-state"},[_vm._v(" "+_vm._s(item.state)+" ")]),_c('div',{staticClass:"list-car-list list-car-details",on:{"click":function($event){return _vm.getDetails(item.id)}}},[_vm._v("查看")])])])],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }