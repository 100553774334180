import { get, post, put, Delete } from "@/api/axios";

// /web/house/label/list get
export const list = params => get('/web/balance/rule/list', params);

// /web/house/label/add post
export const add = params => post('/web/balance/rule/add', params);

// /web/house/label/update put
export const update = params => put('/web/balance/rule/update', params);

// /web/house/label/delete/{id} delete
export const deleteNew = params => Delete('/web/balance/rule/delete/' + params.id, '');

// /web/house/label/view/{id} get
export const view = params => get('/web/balance/rule/view/' + params.id, '');

