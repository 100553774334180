<template>
  <div class="main-content">
    <h3 class="marginBottom20">{{ type === 'create' ? '新增' : '编辑' }}</h3>
    <div>
      <el-button @click="goBack()">返回</el-button>
    </div>
<div style="margin-right: 25%;">
  <avue-form
      ref="form"
      v-model="form"
      v-loading="loading"
      :option="formOption"
      @submit="submit"
    >
      <template slot="h3">
        <h3>优惠券设置</h3>
      </template>
      <template slot="code">
        {{ form.id?form.code:'系统自动生成' }}
      </template>

      <template slot="useConditionSubtractPrice">
        <div class="flex align-items">
          <div class="width_300 flex">
            <el-button
              type="text"
              style="color: #909399;padding: 0 10px"
            >满</el-button>
            <el-input
              v-model="form.useConditionFullPrice"
              placeholder="输入大于0金额"
              onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
              @blur="salaryDianChange($event,'useConditionFullPrice',1000)"
              type="text"
              clearable
              :disabled="disabled"
            />
          </div>
          <div class="width_300 flex">
            <el-button
              type="text"
              style="color: #909399;padding: 0 10px"
            >元，减</el-button>
            <el-input
              v-model="form.useConditionSubtractPrice"
              placeholder="请输入"
              onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
              @blur="salaryDianChange($event,'useConditionSubtractPrice',1000)"
              type="text"
              clearable
              @change="onUseConditionHandle"
              :disabled="disabled"
            />
            <el-button
              type="text"
              style="color: #909399;padding: 0 10px"
            >元</el-button>
          </div>
        </div>

      </template>

      <template slot="takePrice">
        <div class="flex align-items">
          <div class="width_300 flex">
            <el-button
              type="text"
              style="color: #909399;padding: 0 10px"
            >代</el-button>
            <el-input
              v-model="form.takePrice"
              placeholder="请输入"
              onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
              @blur="salaryDianChange($event,'takePrice',999999.99)"
              type="text"
              clearable
              :disabled="disabled"
            />
            <el-button
              type="text"
              style="color: #909399;padding: 0 10px"
            >元</el-button>
          </div>
        </div>
      </template>

      <template slot="validityNum">
        <div class="flex align-items">
          <div class="width_300 flex">
            <el-button
              type="text"
              style="color: #909399;padding: 0 10px"
            >领取</el-button>
            <el-input
              v-model="form.comeNum"
              placeholder="请输入"
              onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
              @blur="salaryChange($event,'sort',999999)"
              type="text"
              clearable
              :disabled="disabled"
            />
          </div>
          <div class="width_300 flex">
            <el-button
              type="text"
              style="color: #909399;padding: 0 10px"
            >天生效，有效</el-button>
            <el-input
              v-model="form.validityNum"
              placeholder="请输入"
              onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
              @blur="salaryChange($event,'sort',999999)"
              type="text"
              clearable
              @change="onValidityNumHandle"
              :disabled="disabled"
            />
            <el-button
              type="text"
              style="color: #909399;padding: 0 10px"
            >天</el-button>
          </div>
        </div>
        <div style="color:#ddd">*领取0天表示领取立即生效</div>
      </template>

      <template slot="useEndTime">

        <div class="flex align-items">
          <div class="width_200 flex">
            <el-time-select
              placeholder="起始时间"
              v-model="form.useStartTime"
              :picker-options="{
      start: '00:00',
     step:'00:01',
     end: '23:58',
    }"
            >
            </el-time-select>
          </div>
          <div style="margin:0 10px"> - </div>
          <div class="width_200 flex">
            <el-time-select
              placeholder="结束时间"
              v-model="form.useEndTime"
              :picker-options="{
       start: '00:00',
     step:'00:01',
     end: '23:59',
      minTime: form.useStartTime
    }"
              @change="onUseEndTimeHandle"
            >
            </el-time-select>
          </div>

        </div>

      </template>

      <template slot="personLimitNum">
       <div style="display: flex;">
        <el-input
          style="width: 350px;"
          v-model="form.personLimitNum"
          placeholder="请输入每人限领数量"
          onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
          @blur="salaryChange($event,'personLimitNum',10)"
          type="text"
          suffix="最多设置10张"
          clearable
        />
          <span style="color: #ddd;">*最多设置10张</span>
       </div>
      </template>

      <template slot="stockNum">
        <el-input
          v-model="form.stockNum"
          placeholder="请输入库存数量"
          onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
          @blur="salaryChange($event,'stockNum',999999)"
          type="text"
          clearable
        />
      </template>

      <template slot="regionIds">
        <div style="text-align:right">
          <el-button
            type="primary"
            @click="handleSelect"
          >选择区域</el-button>
        </div>

        <el-table
          :data="form.regionIds"
          style="width: 100%"
          height="250"
        >
          <el-table-column
            fixed
            prop="code"
            label="区域编号"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="区域名称"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="state"
            label="区域状态"
            width="120"
          >
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.state == 1?'正常':scope.row.state == 2?'冻结':'' }}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>

        </el-table>
      </template>

      <template slot="menuForm">
        <el-button
          icon="el-icon-close"
          @click="handleClose"
        >取消</el-button>
      </template>

      <template slot="useRuleTitle">
        <h3>使用规则说明</h3>
      </template>
      <template slot="useRule">
        <avue-ueditor
          v-model="form.useRule"
          :options="options"
        ></avue-ueditor>
      </template>
    </avue-form>
</div>
    <SelectLink
      :show="show"
      :defaultData="regionIds"
      @change="handleSelectData"
      @newchange="handleSelectNewData"
      @close="handleSelectClose"
    />

  </div>
</template>

<script>
import { add, update, view } from "@/api/coupon/manage";
import SelectLink from "./components/selectLink";
import dayjs from "dayjs";

export default {
  name: "create",
  components: {
    SelectLink,
  },
  data() {
    return {
      type: this.$route.query.type,
      loading: false,
      form: {
        state: 1,
        regionIds: [],
        useRule:'',
      },
      options: {
        action: this.actionUrl,
        customConfig: {}, //wangEditor编辑的配置
        headers: {
          Authorization: this.$store.state.user.Token,
        },
        props: {
          url: "path",
          res: "data",
          name: "path",
        },
      },
      regionIds: [],
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "",
            prop: "h3",
            span: 20,
            labelWidth: 0,
          },
          {
            label: "优惠劵编号",
            prop: "code",
            maxlength: 20,
            showWordLimit: true,
            span: 16,
            row: true,
            placeholder: "系统自动生成",
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入标题",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "优惠劵名称",
            prop: "name",
            maxlength: 20,
            showWordLimit: true,
            span: 16,
            row: true,
            placeholder: "请输入优惠劵名称",
            // disabled: this.$route.query.id ? true : false,
            rules: [
              {
                required: true,
                message: "请输入优惠劵名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "优惠劵类型",
            prop: "type",
            type: "select",
            dicData: [
              { label: "满减", value: 1 },
              { label: "代金", value: 2 },
            ],
            value: 1,
            span: 16,
            row: true,
            disabled: this.$route.query.id ? true : false,
            rules: [
              {
                required: true,
                message: "请选择优惠劵类型",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择优惠劵类型"));
                  } else {
                    callback();
                  }
                },
              },
            ],
            change: (e) => {
              const useConditionSubtractPrice = this.findObject(
                this.formOption.column,
                "useConditionSubtractPrice"
              );
              // const appId = this.findObject(this.formOption.column, "appId");
              const takePrice = this.findObject(
                this.formOption.column,
                "takePrice"
              );
              if (e.value === 1) {
                useConditionSubtractPrice.display = true;
                // appId.display = false;
                takePrice.display = false;
              } else {
                useConditionSubtractPrice.display = false;
                // appId.display = true;
                takePrice.display = true;
              }
            },
          },
          {
            label: "使用条件",
            prop: "useConditionSubtractPrice",
            type: "number",
            maxRows: 1000,
            minRows: 0.01,
            controls: false,
            span: 16,
            row: true,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入使用条件",
                trigger: "blur",
              },
            ],
            display: true,
          },
          {
            label: "使用条件",
            prop: "takePrice",
            type: "number",
            maxRows: 999999,
            minRows: 0.01,
            controls: false,
            span: 16,
            row: true,
            showWordLimit: true,
            placeholder: "请输入使用条件",
            rules: [
              {
                required: true,
                message: "请输入使用条件",
                trigger: "blur",
              },
            ],
            display: false,
          },
          {
            label: "过期类型",
            prop: "overdueType",
            type: "radio",
            value: 1,
            dicData: [
              { label: "使用有效期", value: 1 },
              { label: "领取失效", value: 2 },
            ],
            span: 12,
            row: true,
            disabled: this.$route.query.id ? true : false,
            rules: [
              {
                required: true,
                message: "请选择过期类型",
                trigger: "blur",
              },
            ],
            change: (e) => {
              const validityTime = this.findObject(
                this.formOption.column,
                "validityTime"
              );
              // const appId = this.findObject(this.formOption.column, "appId");
              const validityNum = this.findObject(
                this.formOption.column,
                "validityNum"
              );
              if (e.value === 1) {
                validityTime.display = true;
                // appId.display = false;
                validityNum.display = false;
              } else {
                validityTime.display = false;
                // appId.display = true;
                validityNum.display = true;
              }
            },
          },
          {
            label: "优惠券有效期",
            prop: "validityTime",
            type: "daterange",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            controls: false,
            span: 16,
            row: true,
            placeholder: "请选择优惠券有效期",
            pickerOptions: {
              disabledDate: (time) => {
                return time.getTime() < Date.now() - 8.64e7;
              },
            },
            rules: [
              {
                required: true,
                message: "请选择优惠券有效期",
                trigger: "blur",
              },
            ],
          },
          {
            label: "使用条件",
            prop: "validityNum",
            type: "number",
            maxRows: 999999,
            minRows: 0.01,
            controls: false,
            span: 16,
            row: true,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入使用条件",
                trigger: "blur",
              },
            ],
            display: true,
          },
          {
            label: "优惠券使用时间",
            prop: "useEndTime",
            controls: false,
            span: 16,
            row: true,
            placeholder: "请选择优惠券使用时间",
            rules: [
              {
                required: true,
                message: "请选择优惠券使用时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "获取方式",
            prop: "getWay",
            type: "radio",
            value: 1,
            dicData: [
              { label: "领用", value: 1 },
              { label: "购买", value: 2 },
              { label: "其他平台", value: 3 },
              { label: "销售合伙人赠送", value: 4 },
            ],
            span: 16,
            row: true,
            disabled: this.$route.query.id ? true : false,
            rules: [
              {
                required: true,
                message: "请选择获取方式",
                trigger: "blur",
              },
            ],
            change: (e) => {
              const receiveTime = this.findObject(
                this.formOption.column,
                "receiveTime"
              );
              const personLimitNum = this.findObject(
                this.formOption.column,
                "personLimitNum"
              );
              const stockNum = this.findObject(
                this.formOption.column,
                "stockNum"
              );
              if (e.value === 1) {
                receiveTime.display = true;
                personLimitNum.display = true;
                stockNum.display = true;
              } else if (e.value === 2 || e.value === 3) {
                receiveTime.display = false;
                personLimitNum.display = false;
                stockNum.display = true;
              } else {
                receiveTime.display = false;
                personLimitNum.display = false;
                stockNum.display = false;
              }
            },
          },
          {
            label: "优惠券领取时间",
            prop: "receiveTime",
            type: "daterange",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            controls: false,
            span: 16,
            row: true,
            placeholder: "请选择优惠券领取时间",
            pickerOptions: {
              disabledDate: (time) => {
                return time.getTime() < Date.now() - 8.64e7;
              },
            },
            rules: [
              {
                required: true,
                message: "请选择优惠券领取时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "每人限领数量",
            prop: "personLimitNum",
            type: "number",
            maxRows: 10,
            minRows: 1,
            controls: false,
            span: 16,
            row: true,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入每人限领数量",
                trigger: "blur",
              },
            ],
            display: true,
          },
          {
            label: "库存数量（张）",
            prop: "stockNum",
            type: "number",
            maxRows: 999,
            minRows: 1,
            controls: false,
            span: 16,
            row: true,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入库存数量",
                trigger: "blur",
              },
            ],
            display: true,
          },
          {
            label: "使用范围",
            prop: "useScope",
            type: "radio",
            value: 1,
            dicData: [
              { label: "全区域", value: 1 },
              { label: "指定区域", value: 2 },
            ],
            span: 16,
            row: true,
            rules: [
              {
                required: true,
                message: "请选择使用范围",
                trigger: "blur",
              },
            ],
            change: (e) => {
              const regionIds = this.findObject(
                this.formOption.column,
                "regionIds"
              );
              if (e.value === 1) {
                regionIds.display = false;
              } else {
                regionIds.display = true;
              }
            },
          },
          {
            label: "选择指定区域",
            prop: "regionIds",
            span: 16,
            row: true,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请选择指定区域",
                trigger: "blur",
              },
            ],
            display: true,
          },
          {
            label: "",
            prop: "useRuleTitle",
            span: 20,
            labelWidth: 0,
          },
          {
            label: "使用规则",
            prop: "useRule",
            type: "textarea",
            rows: 10,
            maxlength: 1000,
            showWordLimit: true,
            span: 16,
            row: true,
            placeholder: "请输入使用规则",
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入使用规则",
            //     trigger: "blur",
            //   },
            // ],
          },
        ],
      },
      show: false,
      disabled: false,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.getView();
      this.disabled = true;
    }
  },
  methods: {
    onValidityNumHandle(val) {
      if (!this.form.comeNum) {
        this.form.validityNum = "";
      }
    },
    onUseConditionHandle(val) {
      if (!this.form.useConditionFullPrice) {
        this.form.useConditionSubtractPrice = "";
      }
    },
    onUseEndTimeHandle(val) {
      if (!this.form.useStartTime) {
        this.form.useEndTime = "";
      }
    },
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
        delete this.form[t]
      }
    },
    handleSelect() {
      this.show = true;
      this.regionIds = this.form.regionIds;
    },
    handleSelectData(e) {
      this.form.regionIds = e;
      this.regionIds = e;
      this.handleSelectClose();
    },
    handleSelectNewData(e) {
      this.regionIds = e;
    },
    handleSelectClose() {
      this.show = false;
    },
    handleDelete(row) {
      this.form.regionIds = this.form.regionIds.filter(
        (item) => item.id !== row.id
      );
      this.regionIds = this.regionIds.filter((item) => item.id !== row.id);
    },
    getView() {
      this.loading = true;
      view({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.form = {
            ...res.data,
            regionIds: res.data.regions,
          };
          this.regionIds = res.data.regions;

          if (res.data.validityStartTime && res.data.validityEndTime) {
            this.form.validityTime = [
              res.data.validityStartTime,
              res.data.validityEndTime,
            ];
          }
          if (res.data.receiveStartTime && res.data.receiveEndTime) {
            this.form.receiveTime = [
              res.data.receiveStartTime,
              res.data.receiveEndTime,
            ];
          }

          // this.selectData = {
          //   id: res.data.linkType,
          //   name: res.data.typeName,
          // };
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    submit(form, done) {
      if (
        // Number(form.useConditionFullPrice) !== 0 &&
        Number(form.useConditionFullPrice) <
        Number(form.useConditionSubtractPrice)
      ) {
        this.$message.warning("减的金额不能大于满的金额");
        done();
        return;
      }
      const data = {
        ...form,
        regionIds: this.form.regionIds.map((item) => item.id),
      };
      if (form.validityTime) {
        data.validityStartTime = dayjs(form.validityTime[0]).format(
          "YYYY-MM-DD 00:00:00"
        );
        data.validityEndTime = dayjs(form.validityTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      if (form.receiveTime) {
        data.receiveStartTime = dayjs(form.receiveTime[0]).format(
          "YYYY-MM-DD 00:00:00"
        );
        data.receiveEndTime = dayjs(form.receiveTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      if (data.id) {
        update(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      } else {
        add(data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      }
    },
    handleClose() {
      this.$router.go(-1);
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value;
      console.log(value,'2222222');
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (Number(value) > Number(max)) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            console.log("t", t, value);
            if (
              (t === "useConditionSubtractPrice" || t === "takePrice") &&
              value < 0.01
            ) {
              this.form[t] = 0.01;
              e.target.value = 0.01;
              return;
            }
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
        delete this.form[t]
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
</style>