<template>
  <div class="main-content">

    <div style="display: flex;align-items: center;justify-content: space-between;">
      <el-button
        @click="goBack()"
        style="margin-bottom:20px"
      >返回</el-button>

      <!-- <div>
        <el-button
          type="primary"
          size="small"
          @click="handleEditAvailableIntegral(2)"
        >通过</el-button>
        <el-button
          size="small"
          @click="handleEditAvailableIntegral(3)"
        >驳回</el-button>
      </div> -->
    </div>

    <!-- <h3 class="marginBottom20">{{ form.handleWay==1?'换车审核':form.handleWay==2?'退款审核':'' }}</h3> -->

<h3 class="marginBottom20">售后详情</h3>
    <div class="details-title-style">
      <div class="flex marginBottom10">
        <div class="width120">
          售后编号：
        </div>
        <div>
          {{ form.code }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">
          售后类型：
        </div>
        <div>
          {{ form.handleWay==1?'换车':form.handleWay==2?'退款':'' }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">
          申请时间：
        </div>
        <div>
          {{ form.createTime }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">
          售后状态：
        </div>
        <div>
          {{ form.state===1?'待审核':form.state===2?'审核通过':form.state===3?'审核驳回':'' }}
        </div>
      </div>
    <div v-if="form.state===2 || form.state===3">
      <div class="flex marginBottom10">
        <div class="width120">
          审核人：
        </div>
        <div>
          {{ form.handleUsername }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">
          审核时间：
        </div>
        <div>
          {{ form.handleTime }}
        </div>
      </div>
    </div>
      <div v-if="form.handleWay!=1 && form.state===2">
        <div class="flex marginBottom10">
          <div class="width120">
            退款到微信 (元) ：
          </div>
          <div>
            {{ form.refundPrice }}
          </div>
        </div>
        <div class="flex marginBottom10">
          <div class="width120">
            退款到余额 (元) ：
          </div>
          <div>
            {{ form.balancePrice }}
          </div>
        </div>
      </div>
  <div class="flex marginBottom10" v-if="form.state===3">
          <div class="width120">
            驳回原因：
          </div>
          <div style="width: 60%;">
            {{ form.rejectReason }}
          </div>
        </div>
      <div class="flex marginBottom10 marginTop30">
        <div class="width120">
          订单信息
        </div>
        <div>
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">
          订单编号：
        </div>
        <div>
          {{ form.orderCode }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">
          会员编号：
        </div>
        <div>
          {{ form.userCode }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">
          会员昵称：
        </div>
        <div>
          {{ form.nickname }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">
          会员手机号：
        </div>
        <div>
          {{ form.phone }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">
          售后原因：
        </div>
        <div>
          {{ form.type===1?'车辆故障': form.type===2?'其他原因': ''}}
        </div>
      </div>
      <div class="flex marginBottom30">
        <div class="width120">
          申请凭证：
        </div>
        <div>
          {{ form.saleDesc }}
        </div>
      </div>
      <div class="flex marginBottom30">
        <div class="width120">
          <!-- 申请凭证： -->
        </div>
        <div class="flex flex-wrap" v-if="form.saleImage && form.saleImage != '' ">
          <el-image
            style="width: 100px; height: 100px"
            class="marginRight10"
            v-for="(item,index) in form.saleImage"
            :preview-src-list="[item]"
            :key="index"
            :src="item"
            >
          </el-image>
          <!-- <img
            width="100px"
            height="100px"
            class="marginRight10"
            v-for="(item,index) in form.saleImage"
            :key="index"
            :src="item"
          > -->
        </div>
      </div>
      <div
        style="width: 100%;"
        class="marginBottom30"
      >
        <div class="width120 marginBottom10">
          套餐情况：
        </div>
        <div>
          <el-table
            :data="form.packages"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="packageName"
              label="套餐名称"
            >
            </el-table-column>
            <el-table-column
              prop="packagePrice"
              label="套餐金额 (元)"
            >
            </el-table-column>
            <el-table-column
              prop="memberPrice"
              label="会员等级减免 (元)"
            >
            </el-table-column>
            <el-table-column
              prop="couponPrice"
              label="优惠券减免 (元)"
            >
            </el-table-column>
            <el-table-column
              prop="amount"
              label="实付金额 (元)"
            >
            </el-table-column>
            <el-table-column
              prop="payType"
              label="支付渠道"
            >
              <template slot-scope="scope">
                {{scope.row.payType==1?'微信':scope.row.payType==2?'余额':''}}
              </template>
            </el-table-column>
            <el-table-column
              prop="packageDuration"
              label="套餐时长  (分钟)"
            >
            </el-table-column>
            <el-table-column
              prop="userTime"
              label="使用时长  (分钟)"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>

    </div>

    <el-dialog
      v-if="dialogVisibleAvailableIntegral"
      title="审核"
      :visible.sync="dialogVisibleAvailableIntegral"
      width="40%"
      :before-close="handleCloseAvailableIntegral"
    >
      <avue-form
        ref="forms"
        v-model="forms"
        :option="formOption"
        @submit="submitAvailableIntegral"
      >
        <template slot="state">
          <el-radio-group
            v-model="forms.state"
            @change="onStateHandle"
          >
            <el-radio :label="2">通过</el-radio>
            <el-radio :label="3">驳回</el-radio>
          </el-radio-group>
        </template>

        <template slot="refundPrice">
          <el-input
            v-model="forms.refundPrice"
            placeholder="请输入"
            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            @blur="salaryDianChange($event,'refundPrice',999999.99)"
            type="text"
            clearable
          />
        </template>

        <template slot="refundPrices">
          <el-input
            v-model="forms.refundPrices"
            placeholder="暂不支持"
            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            @blur="salaryDianChange($event,'refundPrices',999999.99)"
            type="text"
            clearable
            :disabled="true"
          />
        </template>

        <template slot="rejectReason">
          <el-input
            v-model="forms.rejectReason"
            placeholder="请输入"
            type="textarea"
            clearable
            maxlength="200"
            rows="4"
          />
        </template>

        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleCloseAvailableIntegral"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
    <Look :form="form" />
  </div>
</template>

<script>
import { view, examine } from "@/api/sale/manage";
import Look from "./look";
export default {
  components: {
    Look,
  },
  data() {
    return {
      id: this.$route.query.id,
      form: {
        applyType: "",
        code: "",
        createTime: "",
        handleDesc: "",
        handleImage: "",
        handlePhone: "",
        handleSuggest: "",
        handleTime: "",
        handleUsername: "",
        id: "",
        nickname: "",
        orderCode: "",
        phone: "",
        saleDesc: "",
        saleImage: "",
        state: 0,
        type: 0,
        userCode: "",
      },
      dialogVisibleIntimacy: false,
      dialogVisibleAvailableIntegral: false,
      formOption: {
        labelWidth: "160",
        submitText: "确定",
        emptyBtn: false,
        column: [
          {
            label: "审核结果",
            prop: "state",
            type: "radio",
            // value: 1,
            dicData: [
              { label: "通过", value: 2 },
              { label: "驳回", value: 3 },
            ],
            span: 24,
            maxRows: 999999,
            minRows: 0,
            controls: false,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请选择审核结果",
                trigger: "blur",
              },
            ],
          },
          {
            label: "退款到微信",
            prop: "refundPrice",
            type: "number",
            maxRows: 999999.99,
            minRows: 0,
            span: 24,
            display: false,
            rules: [
              {
                required: true,
                message: "请输入金额",
                trigger: "blur",
              },
            ],
          },
          {
            label: "退款到余额",
            prop: "refundPrices",
            type: "number",
            maxRows: 999999.99,
            minRows: 0,
            span: 24,
            display: false,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入金额",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "处理说明",
            prop: "rejectReason",
            maxLength: 200,
            span: 24,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入处理说明",
            //     trigger: "blur",
            //   },
            // ],
          },
        ],
      },
      forms: {
        id: "",
        state: "",
        refundPrice: "",
        rejectReason: "",
      },
    };
  },
  mounted() {
    if (this.id) {
      this.getView();
    }
  },
  methods: {
    onStateHandle(e) {
      const refundPrice = this.findObject(
        this.formOption.column,
        "refundPrice"
      );
      const refundPrices = this.findObject(
        this.formOption.column,
        "refundPrices"
      );
      console.log(refundPrice, refundPrices);
      if (e === 2) {
        refundPrice.display = true;
        refundPrices.display = true;
      } else if (e === 3) {
        refundPrice.display = false;
        refundPrices.display = false;
      }
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    async getView() {
      const { data } = await view({ id: this.id });
      this.form = {
        ...data,
        saleImage: data?.saleImage?.split(","),
        handleImage: data?.handleImage?.split(","),
      };
      this.forms.id = this.form.id;
    },
    // 审核
    submitAvailableIntegral(form, done) {
      let data = {};
      if (form.state === 2) {
        data = {
          id: this.$route.query.id,
          state: form.state,
          refundPrice: form.refundPrice,
          rejectReason: form.rejectReason,
        };
      }
      if (form.state === 3) {
        data = {
          id: this.$route.query.id,
          state: form.state,
          rejectReason: form.rejectReason,
        };
      }
      // console.log("dataf", data);
      // return;
      examine(data)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.dialogVisibleAvailableIntegral = false;
            this.getView();
            this.forms = {};
            this.goBack();
          } else {
            this.$message.error(res.msg);
            done();
          }
        })
        .catch(() => done());
    },
    // 关闭亲呢度弹窗
    handleCloseAvailableIntegral() {
      this.forms = {};
      this.dialogVisibleAvailableIntegral = false;
    },
    // 打开积分弹窗
    async handleEditAvailableIntegral(state) {
      this.dialogVisibleAvailableIntegral = true;
      this.forms.state = state;
      this.onStateHandle(state);
    },
  },
};
</script>

<style>
</style>