import { get, post, put, Delete } from "@/api/axios";

// /web/house/label/list get
export const list = params => get('/web/coupon/up/list', params);

// /web/house/label/add post
export const add = params => post('/web/coupon/up/add', params);

// /web/house/label/update put
export const update = params => put('/web/coupon/up/update', params);

// /web/house/label/delete/{id} delete
export const deleteNew = params => Delete('/web/coupon/up/delete/' + params.id, '');

// /web/house/label/view/{id} get
export const view = params => get('/web/coupon/up/view/' + params.id, '');

// /web/house/label/state/update put
export const updateNew = params => put('/web/coupon/up/state/update', params);

//查看可用区域
export const getOnLookUseHandle = params => get('/web/coupon/up/region/list/' + params.id, '');

//选择优惠劵列表
export const getCouponList = params => get('/web/coupon/up/coupon/list', params)