<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">用户名称: </span>
              <div class="width250">
                <el-input
                  v-model="searchData.username"
                  placeholder="请输入"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">联系电话: </span>
              <div class="width250">
                <el-input
                  v-model="searchData.phone"
                  placeholder="请输入"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">用户邮箱: </span>
              <div class="width250">
                <el-input
                  v-model="searchData.email"
                  placeholder="请输入"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">角色: </span>
              <div class="width200">
                <el-select
                  class="width200"
                  v-model="searchData.roleId"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    v-for="item in levelList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">消息模块: </span>
              <div class="width200">
                <el-select
                  class="width200"
                  v-model="searchData.types"
                  clearable
                  @change="handleSearch"
                >
                  <el-option value="" label="请选择"></el-option>
                  <el-option :value="1" label="售后消息"></el-option>
                  <el-option :value="2" label="故障消息"></el-option>
                  <el-option :value="3" label="销售合伙人消息"></el-option>
                  <el-option :value="4" label="押金消息"></el-option>
                  <el-option :value="5" label="其他异常消息"></el-option>
				  
				  <el-option :value="6" label="电量为零消息"></el-option>
				  <el-option :value="7" label="超出电子围栏消息"></el-option>
				  <el-option :value="8" label="FautBat"></el-option>
				  <el-option :value="9" label="FautBat1"></el-option>
				  <el-option :value="10" label="FautDisP"></el-option>
				  
				  <el-option :value="11" label="FautCombinSwitch"></el-option>
				  <el-option :value="12" label="FautEC9"></el-option>
				  <el-option :value="13" label="FautECA"></el-option>
				  <el-option :value="14" label="FautECB"></el-option>
				  <el-option :value="15" label="FautECC"></el-option>
				  
				  <el-option :value="16" label="PWMState"></el-option>
				  <el-option :value="16" label="BatWake"></el-option>
				  <el-option :value="18" label="Bat1Wake"></el-option>
				  <el-option :value="19" label="FautBrake"></el-option>
				  <el-option :value="20" label="FautThro"></el-option>
				  
				  <el-option :value="21" label="FautSteel"></el-option>
				  <el-option :value="22" label="FautVCU"></el-option>
				  <el-option :value="23" label="断电超出时长"></el-option>
				  <el-option :value="24" label="超时未开锁运营"></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">接收区域: </span>
              <div class="width250">
                <el-input
                  v-model="searchData.regionNames"
                  placeholder="请输入"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <!-- 可接收模块 -->
      <template slot="types" slot-scope="{ row }">
        <div v-if="row.types">
          <span v-for="(item, index) in row.types.split(',')" :key="index">
            <span>{{
              item == 1
                ? "售后"
                : item == 2
                ? "故障"
                : item == 3
                ? "销售合伙人"
                : item == 4
                ? "押金"
                : item == 5
                ? "其他"
                : ""
            }}</span>
            <span v-if="index < row.types.split(',').length - 1">,</span>
          </span>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleSelect1(row)"
          >编辑接收模块</el-button
        >
        <el-button type="text" size="mini" @click="handleShow(row)"
          >编辑接收区域</el-button
        >
      </template>
    </avue-crud>
    <!-- 模块权限弹窗 -->
    <UserLink
      :show="show1"
      :defaultData="regionIds1"
      @change="handleSelectData1"
      @close="handleSelectClose1"
    />
    <!-- 区域权限弹窗 -->
    <SelectLink
      :show="show"
      :defaultData="regionIds"
      @change="handleSelectData"
      @newchange="handleSelectNewData"
      @close="handleSelectClose"
    />
  </div>
</template>

<script>
import {
  receiveList,
  roleList,
  typeUpdate,
  regionUpdate,
} from "@/api/receive/index";
import SelectLink from "./components/selectLink";
import UserLink from "./components/userLink";
export default {
  name: "index",
  components: {
    SelectLink,
    UserLink,
  },
  data() {
    return {
      //模块弹窗
      regionIds1: [],
      show1: false,
      //区域弹窗
      regionIds: [],
      show: false,

      ids: "",
      levelList: [], //角色数据
      disabled: false,
      searchData: {
        email: "",
        phone: "",
        position: "",
        roleId: "",
        types: "",
        regionNames: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "消息接收人设置",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "用户名称",
            prop: "username",
          },
          {
            label: "联系电话",
            prop: "phone",
          },
          {
            label: "用户邮箱",
            prop: "email",
          },
          {
            label: "角色",
            prop: "roleName",
          },
          {
            label: "可接收模块",
            prop: "types",
          },
          {
            label: "可接受区域",
            prop: "regionNames",
          },
          {
            label: "当前未读消息数",
            prop: "messageNum",
          },
        ],
      },
    };
  },
  created() {
    this.getLevelList();
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    //角色列表
    async getLevelList() {
      roleList().then((res) => {
        if (res.code === 200) {
          this.levelList = res.data;
        }
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        phone: "",
        position: "",
        email: "",
        roleId: "",
        types: "",
        regionNames: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },

    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      receiveList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    //模块消息提交
    getModuleSubmission(e) {
      typeUpdate({ types: e.join(","), id: this.ids }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.onLoad();
          this.$message.success("提交成功!");
          // this.tableData = res.data.records;
          // this.page.total = res.data.total;
        }
      });
    },
    //区域消息提交
    getRegionalSubmission(e) {
      let newRegionIds = [];
      if (e) {
        e.map((item) => {
          newRegionIds.push(item.id);
        });
      }
      regionUpdate({ regionIds: newRegionIds.join(","), id: this.ids }).then(
        (res) => {
          this.showLoading = false;
          if (res.code === 200) {
            this.onLoad();
            this.$message.success("提交成功!");
            // this.tableData = res.data.records;
            // this.page.total = res.data.total;
          }
        }
      );
    },

    //模块多选弹框
    handleSelect1(r) {
      this.show1 = true;
      this.ids = r.id;
      this.regionIds1 = r.types ? r.types.split(",") : [];
    },
    handleSelectData1(e) {
      this.regionIds1 = e;
      this.handleSelectClose1();
      this.getModuleSubmission(e);
    },

    handleSelectClose1() {
      this.show1 = false;
    },

    //区域表格弹出打开
    handleShow(r) {
      this.show = true;
      let newRegionIds = [];
      if (r.regionIds) {
        r.regionIds.split(",").map((item) => {
          newRegionIds.push({ id: item });
        });
      }
      this.regionIds = newRegionIds;
      this.ids = r.id;
    },
    handleSelectData(e) {
      this.regionIds = e;
      this.handleSelectClose();
      this.getRegionalSubmission(e);
    },
    handleSelectNewData(e) {
      this.regionIds = e;
    },
    handleSelectClose() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
 /deep/.el-table .cell {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 19px!important;
    padding-right: 10px;
}
</style>
