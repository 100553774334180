<template>
  <div class="main-content">
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <el-button @click="goBack()" style="margin-bottom: 20px">返回</el-button>

      <div>
        <el-button
          type="primary"
          size="small"
          @click="handleEditAvailableIntegral(2)"
          >通过</el-button
        >
        <el-button size="small" @click="handleEditAvailableIntegral(3)"
          >驳回</el-button
        >
      </div>
    </div>

    <h3 class="marginBottom20">
      {{
        form.handleWay == 1 ? "换车审核" : form.handleWay == 2 ? "退款审核" : ""
      }}
    </h3>

    <div class="details-title-style">
      <div class="flex marginBottom10">
        <div class="width120">售后编号：</div>
        <div>
          {{ form.code }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">售后类型：</div>
        <div>
          {{ form.handleWay == 1 ? "换车" : form.handleWay == 2 ? "退款" : "" }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">申请时间：</div>
        <div>
          {{ form.createTime }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">售后状态：</div>
        <div>
          {{
            form.state === 1
              ? "待审核"
              : form.state === 2
              ? "审核通过"
              : form.state === 3
              ? "审核驳回"
              : ""
          }}
        </div>
      </div>
      <!-- <div class="flex marginBottom10">
        <div class="width120">
          审核人：
        </div>
        <div>
          {{ form.handleUsername }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">
          审核时间：
        </div>
        <div>
          {{ form.handleTime }}
        </div>
      </div> -->
      <!-- <div v-if="form.handleWay!=1">
        <div class="flex marginBottom10">
          <div class="width120">
            退款到微信 (元) ：
          </div>
          <div>
            {{ form.refundPrice }}
          </div>
        </div>
        <div class="flex marginBottom10">
          <div class="width120">
            退款到余额 (元) ：
          </div>
          <div>
            {{ form.balancePrice }}
          </div>
        </div>
      </div> -->
      <div class="flex marginBottom10 marginTop30">
        <div class="width120">订单信息</div>
        <div></div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">订单编号：</div>
        <div>
          {{ form.orderCode }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">会员编号：</div>
        <div>
          {{ form.userCode }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">会员昵称：</div>
        <div>
          {{ form.nickname }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">会员手机号：</div>
        <div>
          {{ form.phone }}
        </div>
      </div>
      <div class="flex marginBottom10">
        <div class="width120">售后原因：</div>
        <div>
          {{ form.type === 1 ? "车辆故障" : form.type === 2 ? "其他原因" : "" }}
        </div>
      </div>
      <div class="flex marginBottom30">
        <div class="width120">申请凭证：</div>
        <div>
          {{ form.saleDesc }}
        </div>
      </div>
      <div class="flex marginBottom30">
        <div class="width120">
          <!-- 申请凭证： -->
        </div>
        <div v-if="form.saleImage != ''" class="flex flex-wrap">
          <el-image
            style="width: 100px; height: 100px"
            class="marginRight10"
            v-for="(item, index) in form.saleImage"
            :preview-src-list="[item]"
            :key="index"
            :src="item"
          >
          </el-image>
          <!-- <img
            width="100px"
            height="100px"
            class="marginRight10"
            v-for="(item,index) in form.saleImage"
            :key="index"
            :src="item"
          > -->
        </div>
      </div>
      <div style="width: 100%" class="marginBottom30">
        <div class="width120 marginBottom10">套餐情况：</div>
        <div>
          <el-table :data="form.packages" border style="width: 100%">
            <el-table-column prop="packageName" label="套餐名称">
            </el-table-column>
            <el-table-column prop="packagePrice" label="套餐金额 (元)">
            </el-table-column>
            <el-table-column prop="memberPrice" label="会员等级减免 (元)">
            </el-table-column>
            <el-table-column prop="couponPrice" label="优惠券减免 (元)">
            </el-table-column>
            <el-table-column prop="amount" label="实付金额 (元)">
            </el-table-column>
            <el-table-column prop="payType" label="支付渠道">
              <template slot-scope="scope">
                {{
                  scope.row.payType == 1
                    ? "微信"
                    : scope.row.payType == 2
                    ? "余额"
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column prop="packageDuration" label="套餐时长  (分钟)">
            </el-table-column>
            <el-table-column prop="userTime" label="使用时长  (分钟)">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog
      v-if="dialogVisibleAvailableIntegral"
      title="审核"
      :visible.sync="dialogVisibleAvailableIntegral"
      width="70%"
      :before-close="handleCloseAvailableIntegral"
    >
      <avue-form
        ref="forms"
        v-model="forms"
        :option="formOption"
        @submit="submitAvailableIntegral"
      >
        <template slot="state">
          <el-radio-group v-model="forms.state" @change="onStateHandle">
            <el-radio :label="2">通过</el-radio>
            <el-radio :label="3">驳回</el-radio>
          </el-radio-group>
        </template>
        <template slot="formTable">
          <el-table :data="form.packages" border style="width: 100%">
            <el-table-column prop="packageName" label="套餐名称" >
            </el-table-column>

            <template slot="orderTwoRemind" slot-scope="{ row }">
              <el-input style="width: 100px"
                v-model="row.orderTwoRemind"
                :value="row.orderTwoRemind"
                placeholder="请输入"
                onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                @blur="
                  salaryChange(
                    $event,
                    'orderTwoRemind',
                    row.orderFirstRemind,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <el-table-column prop="packageName" label="退款到微信">
              <template slot-scope="scope">
                <el-input
                  :controls="false" style="width: 260px"
                  v-model="forms.packages[scope.$index].refundPrice"
                  :placeholder='`总付款${scope.row.amount}，已退${scope.row.refundedPrice}，最多可以退款${scope.row.canRefundPrice}`'
                  :max='scope.row.canRefundPrice'
                  :min="0"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                  @blur="
                    salaryDianChange(
                      $event,
                      'refundPrice',
                      scope.row.canRefundPrice,
                      scope.$index
                    )
                  "
                  type="text"
                  clearable
                />
              </template>
            </el-table-column>
            <el-table-column prop="packageName" label="退款到余额">
              <template slot-scope="scope">
                <el-input style="width: 320px"
                  :placeholder='`总付款${scope.row.amount}，已退${scope.row.refundedPrice}，最多可以退款${scope.row.canRefundPrice}`'
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                  :max="scope.row.canRefundPrice"
                  @blur="
                    salaryDianChange(
                      $event,
                      'refundPrices',
                      scope.row.amount,
                      scope.$index
                    )
                  "
                  type="text"
                  clearable
                  :disabled="true"
                />
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template slot="rejectReason">
          <el-input
            v-model="forms.rejectReason"
            placeholder="请输入"
            type="textarea"
            clearable
            maxlength="200"
            rows="4"
          />
        </template>

        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleCloseAvailableIntegral"
            >取消</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
    <Look :form="form" />
  </div>
</template>

<script>
import { view, examine } from "@/api/sale/manage";
import Look from "./look";
export default {
  components: {
    Look,
  },
  data() {
    return {
      id: this.$route.query.id,
      form: {
        payPrice: "",
        applyType: "",
        code: "",
        createTime: "",
        handleDesc: "",
        handleImage: "",
        handlePhone: "",
        handleSuggest: "",
        handleTime: "",
        handleUsername: "",
        refundedPrice: 0,
        id: "",
        nickname: "",
        orderCode: "",
        phone: "",
        saleDesc: "",
        saleImage: "",
        state: 0,
        type: 0,
        userCode: "",
      },
      dialogVisibleIntimacy: false,
      dialogVisibleAvailableIntegral: false,
      formOption: {
        labelWidth: "160",
        submitText: "确定",
        emptyBtn: false,
        column: [],
      },
      forms: {
        id: "",
        state: "",
        // refundPrice: '',
        rejectReason: "",
        packages: [],
      },
      max:0
    };
  },
  mounted() {
    if (this.id) {
      this.getView();
    }
  },
  methods: {
    onStateHandle(e) {
      console.log(e, this.form.handleWay);
      const formTable = this.findObject(this.formOption.column, "formTable");
      // const refundPrice = this.findObject(
      //   this.formOption.column,
      //   "refundPrice"
      // );
      // const refundPrices = this.findObject(
      //   this.formOption.column,
      //   "refundPrices"
      // );
      const rejectReason = this.findObject(
        this.formOption.column,
        "rejectReason"
      );

      if (this.form.handleWay == 1) {
        if (e === 3) {
          rejectReason.display = true;
        } else {
          rejectReason.display = false;
        }
      } else {
        if (e === 2) {
          // refundPrice.display = true;
          // refundPrices.display = true;
          formTable.display = true;
          rejectReason.display = true;
        } else if (e === 3) {
          // refundPrice.display = false;
          // refundPrices.display = false;
          formTable.display = false;
          rejectReason.display = true;
        }
      }
    },
    salaryDianChange(e, t, max, index) {
 
      const value = e.target.value;
      console.log(t,value,max)
      if (value) {
        if (isNaN(value * 1)) {
          this.forms.packages[index][t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.forms.packages[index][t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.forms.packages[index][t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.forms.packages[index][t] = "";
      }
    },
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    async getView() {
      const { data } = await view({ id: this.id });
      this.form = {
        ...data,
        saleImage: data?.saleImage?.split(","),
        handleImage: data?.handleImage?.split(","),
      };
      this.forms = {
        ...this.forms,
        packages: data.packages,
      };
      if (this.form.refundedPrice > 0) {
        this.max=parseFloat(this.form.payPrice - this.form.refundedPrice).toFixed(2)
        // this.placeholder =
        //   "已退款金额" +
        //   this.form.refundedPrice +
        //   "，最多还可以退款" +
        //   this.max +
        //   "元";
      } else {
        this.max=this.form.payPrice
        // this.placeholder =
        //   "请输入退款金额，最多可以退款" + this.max + "元";
      }
      this.forms.id = this.form.id;

      if (this.form.handleWay == 2) {
        this.formOption.column = [
          {
            label: "审核结果",
            prop: "state",
            type: "radio",
            // value: 1,
            dicData: [
              { label: "通过", value: 2 },
              { label: "驳回", value: 3 },
            ],
            span: 24,
            maxRows: 999999,
            minRows: 0,
            controls: false,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请选择审核结果",
                trigger: "blur",
              },
            ],
          },
          {
            // label: "",
            prop: "formTable",
            // type: "number",
            maxRows: 999999.99,
            minRows: 0,
            span: 24,
            display: false,
          },
          // {
          //   label: "退款到微信",
          //   prop: "refundPrice",
          //   type: "number",
          //   maxRows: 999999.99,
          //   minRows: 0,
          //   span: 24,
          //   display: false,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请输入金额",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          // {
          //   label: "退款到余额",
          //   prop: "refundPrices",
          //   type: "number",
          //   maxRows: 999999.99,
          //   minRows: 0,
          //   span: 24,
          //   display: false,
          //   // rules: [
          //   //   {
          //   //     required: true,
          //   //     message: "请输入金额",
          //   //     trigger: "blur",
          //   //   },
          //   // ],
          // },
          {
            label: "处理说明",
            prop: "rejectReason",
            maxLength: 200,
            span: 24,
            display: false,
            rules: [
              {
                required: true,
                message: "请输入处理说明",
                trigger: "blur",
              },
            ],
          },
        ];
      } else {
        this.formOption.column = [
          {
            label: "审核结果",
            prop: "state",
            type: "radio",
            // value: 1,
            dicData: [
              { label: "同意", value: 2 },
              { label: "不同意", value: 3 },
            ],
            span: 24,
            maxRows: 999999,
            minRows: 0,
            controls: false,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请选择审核结果",
                trigger: "blur",
              },
            ],
          },
          {
            label: "处理说明",
            prop: "rejectReason",
            maxLength: 200,
            span: 24,
            display: false,
            rules: [
              {
                required: true,
                message: "请输入处理说明",
                trigger: "blur",
              },
            ],
          },
        ];
      }
    },
    // 审核
    submitAvailableIntegral(form, done) {
      let data = {};
      if (form.state === 2) {
        data = {
          id: this.$route.query.id,
          state: form.state,
          // refundPrice: form.refundPrice,
          rejectReason: form.rejectReason,
          packages: this.forms.packages,
        };
      }
      if (form.state === 3) {
        data = {
          id: this.$route.query.id,
          state: form.state,
          rejectReason: form.rejectReason,
        };
      }
      // console.log("dataf", data);
      // return;
      examine(data)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.dialogVisibleAvailableIntegral = false;
            this.getView();
            this.forms = {};
            this.goBack();
          } else {
            this.$message.error(res.msg);
            done();
          }
        })
        .catch(() => done());
    },
    // 关闭亲呢度弹窗
    handleCloseAvailableIntegral() {
      this.forms = {
        id: "",
        state: "",
        // refundPrice: '',
        rejectReason: "",
        packages: this.form.packages,
      };
      this.dialogVisibleAvailableIntegral = false;
    },
    // 打开积分弹窗
    async handleEditAvailableIntegral(state) {
      this.dialogVisibleAvailableIntegral = true;
      this.forms.state = state;
      this.onStateHandle(state);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input-number--small {
  width: 397px !important;
}
</style>