import { get, put } from "@/api/axios";

// 亲昵度规则设置-修改
export const update = params => put('/web/level/discount/update', params);

// 亲昵度规则设置-查看
export const view = params => get('/web/level/discount/view/' + params.id, '');

// 会员等级折扣设置-列表
export const list = params => get('/web/level/discount/list', params)



