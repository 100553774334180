<template>
  <div class="header">
    <div
      class="fold"
      :class="isCollapse ? 'rotate180' : 'rotate0'"
      @click="fold"
    >
      <i class="el-icon-s-fold"></i>
    </div>
    <div>
      <span style="margin-right: 40px; cursor: pointer" @click="getJump">
        <!-- :is-dot="$store.state.allNum.stateRed === 1 ? true : false" -->
        <!-- v-if="this.$store.state.user.userInfo.type == 1 " -->
        <el-badge v-if="this.$store.state.user.userInfo.type == 1 " :value="$store.state.allNum.allMessageNum" 
        :type="$store.state.allNum.allColor == 3 ? '' :
        $store.state.allNum.allColor == 2 ? 'warning  ' :
        $store.state.allNum.allColor == 1 ? 'success ': '' " 
        :hidden='$store.state.allNum.allMessageNum == 0 ? true : false'
        class="item">
            <i class="el-icon-bell"></i>
        </el-badge>
      </span>
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          <avue-avatar :src="imgHeand"></avue-avatar>
          <i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/"> 首页  {{ $store.state.stateRed }} </el-dropdown-item>
          <el-dropdown-item command="/password"> 修改密码 </el-dropdown-item>
          <el-dropdown-item :divided="true" command="/login">
            退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { userinfo, logout } from "../../api";
import { imageUrl } from "@/config/public";
import { allAlert } from "@/api/message/index";

import { manageList } from "@/api/message/index";

export default {
  name: "header.vue",
  props: {
    isCollapse: Boolean,
  },
  data() {
    return {
      headPortrait: "",
      interval:null,
      imgHeand:require('@/style/image/headSculpture.svg')
    };
  },
  mounted() {
    userinfo()
      .then((res) => {
        if (res.code === 200) {
          this.headPortrait = res.data.headPortrait;
          this.$store.commit("setUserInfo", res.data);
        }
      })
      .catch((err) => {
        // this.$message.error(err.msg)
      });
   
    if(this.$store.state.user.userInfo.type == 1) {
      this.interval = setInterval(this.open1, 15000);
      this.$store.dispatch("getStateRed",'');
    }

    console.log(this.$store.state.user.userInfo,'1111111111111111');
    this.$root.eventHub.$on("changeUserInfo", (n) => {
      this.headPortrait = this.$store.state.user.userInfo.headPortrait;
    });
  },
  methods: {
    getJump() {
      this.$router.push({
        path: "/message/manage",
      });
    },
    open1() {
      allAlert().then((res) => {
       if (res.code === 200) {
          res.data.map((item, index) => {
            const h = this.$createElement;
            this.$notify({
              //   title: "消息中心",
              message: h("p", [
                h(
                  "span",
                  null,
                  `收到一条${
                    item.level === 1
                      ? "【一级】"
                      : item.level === 2
                      ? "【二级】"
                      : item.level === 3
                      ? "【三级】"
                      : ""
                  }-${item.type === 1 ? '售后消息' : item.type === 2 ? '故障消息' : item.type === 3 ? '销售合伙人消息' : item.type === 4 ? '退还押金消' : item.type === 5 ? '其他异常消息' : ''}`
                ),
                h(
                  "a",
                  {
                    style: "color: #409EFF;cursor: pointer;",
                    on: {
                      click: () =>{this.$router.push({path: "/message/manage"})},
                    },
                  },
                  '查看'
                ),
              ]),
            });
          });
        }
      });
    },

    fold() {
      this.$root.eventHub.$emit("changeDate", null);
    },
    handleCommand(command) {
      if (command == "/login") {
        this.$confirm(`退出系统, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          callback: (action) => {
            if (action == "confirm") {
              logout()
                .then((res) => {
                  if (res.code === 200) {
                    this.$router.push("/login");
                    this.global.Set_store();
                    this.global.SET_localStorage();
                    clearInterval(this.interval)
                  }
                })
                .catch((err) => {
                  this.$message.error(err.msg);
                });
            } else {
              this.$message({
                type: "info",
                message: "已取消",
              });
            }
          },
        });
      } else {
        if (command != this.$store.state.index.router) {
          this.$router.push(command);
          let title = "";
          if (command == "/") {
            title = "首页";
          } else {
            title = "个人信息";
          }
          let data = {
            title: title,
            name: "0",
            url: command,
            index: "0",
          };
          let exist = false;
          let tableTabs = this.$store.state.index.tableTabs;
          tableTabs.forEach((item) => {
            if (item.title == title) {
              data.name = item.name;
              exist = true;
            }
          });
          if (!exist) {
            tableTabs.forEach((item, index) => {
              if (index + 1 == tableTabs.length) {
                data.name = (item.name * 1 + 1).toString();
              }
            });
          }
          this.$root.eventHub.$emit("changeSubMenuIndex", "0");
          this.$root.eventHub.$emit("changeTableTabs", { type: exist, data });
        }
      }
    },
  },
};
</script>

<style scoped>
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fold {
  font-size: 30px;
}
.rotate180 {
  transition: 0.5s;
  transform: rotate(180deg);
}
.rotate0 {
  transition: 0.5s;
  transform: rotate(0deg);
}
.el-icon--right {
  margin-left: 20px;
}
.message {
  position: absolute;
  top: 260%;
  right: 0;
}
</style>
